.precautions {
	img {
		width: 50px;
		height: 50px;
		margin-bottom: 1rem;
	}

	.precaution {
		.content {
			display: inline-flex;
			justify-content: center;
		}
	}
}

@media screen and (max-width: 768px) {
	.precautions {
		img {
			width: 60px;
			height: 60px;
		}

		.header {
			p.text {
				@include fluid-type-default(16px, 18px);
			}
		}


	}
}

@media screen and (max-width: 1024px) {
	.precautions {
		flex-wrap: wrap;

		.precaution {
			display: inline-flex;
			max-width: 50%;
			flex-direction: column;
			margin: 1em 0;

			.header {
				display: inline-block;
				text-align: center;

				p.text {
					display: inline-block;
					width: 100%;
				}
			}

			.content {
				// display: inline-block;
			}

			.options-list.covid {
				display: inline-block;

				.option-item {
					width: 100%;
					max-width: 100%;
					padding-left: 10px;
					white-space: nowrap;
				}
			}
		}

	}
}

// @media screen and (min-width: 768px) and (max-width: 1024px) {
// 	.precautions {
// 		.precaution {
// 			.options-list.covid {
// 				.option-item {
//
// 				}
// 			}
// 		}
// 	}
// }

@media screen and (min-width: 1024px) {
	.precautions {
		img {
			width: 75px;
			height: 75px;
		}
	}
}
