.accordion.bordered {
	.accordion__item .accordion__title {
		border-color: $input-border-grey;
		background-color: transparent;
		border-radius: 4px;

		& + .section-body {
			overflow: hidden;
		}

		&[aria-selected='true'] {
			border-radius: 4px 4px 0 0;

			& + .section-body {
				border-width: 0 1px 1px 1px;
				border-style: solid;
				// border: solid 1px #ccc;
				border-color: $input-border-grey;
				border-radius: 0 0 4px 4px;
			}
		}

		.section-title {
			font-weight: 400;
		}
	}
}
