.text:not(> .input-container) {
	margin-bottom: 20px;
	color: $black-text;
	// font: 30px/1.2 'Lato', sans-serif;
}

.bold {
	font-weight: 600 !important;
}

.normal {
	font-weight: 400;
}

.text:not(> .input-container).thin {
	font-weight: 300;
}

.text:not(> .input-container).normal {
	font-weight: 400;
}

.text:not(> .input-container).text-italic {
	font-style: italic;
}

.text:not(> .input-container).xs {
	@include fluid-type(map-get($grid-breakpoints, xs), map-get($grid-breakpoints, md), 10px, 12px);

	// for table and up
	@include media-breakpoint-up(md) {
		@include fluid-type(map-get($grid-breakpoints, md), map-get($grid-breakpoints, lg), 14px, 16px);
	}

	// for desktop
	@include media-breakpoint-up(lg) {
		@include fluid-type(map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xl), 12px, 14px);
	}
}

.text:not(> .input-container).sm {
	@include fluid-type(map-get($grid-breakpoints, xs), map-get($grid-breakpoints, md), 12px, 16px);

	// for table and up
	@include media-breakpoint-up(md) {
		@include fluid-type(map-get($grid-breakpoints, md), map-get($grid-breakpoints, lg), 18px, 20px);
	}

	// for desktop
	@include media-breakpoint-up(lg) {
		@include fluid-type(map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xl), 16px, 18px);
	}
}

.text:not(> .input-container).md {
	@include fluid-type(map-get($grid-breakpoints, xs), map-get($grid-breakpoints, md), 18px, 22px);

	// for table and up
	@include media-breakpoint-up(md) {
		@include fluid-type(map-get($grid-breakpoints, md), map-get($grid-breakpoints, lg), 24px, 28px);
	}

	// for desktop
	@include media-breakpoint-up(lg) {
		@include fluid-type(map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xl), 16px, 18px);
	}
}

.text:not(> .input-container).lg {
	@include fluid-type(map-get($grid-breakpoints, xs), map-get($grid-breakpoints, md), 20px, 24px);

	// for table and up
	@include media-breakpoint-up(md) {
		@include fluid-type(map-get($grid-breakpoints, md), map-get($grid-breakpoints, lg), 26px, 30px);
	}

	// for desktop
	@include media-breakpoint-up(lg) {
		@include fluid-type(map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xl), 18px, 20px);
	}
}

.text:not(> .input-container).xl {
	@include fluid-type(map-get($grid-breakpoints, xs), map-get($grid-breakpoints, md), 24px, 30px);

	// for table and up
	@include media-breakpoint-up(md) {
		@include fluid-type(map-get($grid-breakpoints, md), map-get($grid-breakpoints, lg), 30px, 36px);
	}

	// for desktop
	@include media-breakpoint-up(lg) {
		@include fluid-type(map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xl), 48px, 56px);
	}
}
