.map {
	position: relative;

	.map-header {
		position: absolute;
		z-index: 2;
		top: 10px;
		display: flex;
		width: 100%;
		justify-content: flex-end;
		padding: 0 10px;

		// ignore point when in map header wrpper div.
		pointer-events: none;

		> * {
			// allow pointer evens for all children in map-header wrapper div.
			pointer-events: all;
		}

		.map-controls {
			display: flex;
			flex-direction: column;
			pointer-events: none;

			.center-map-btn {
				margin-bottom: 10px;
			}

			.center-map-btn,
			.map-btn {
				pointer-events: all;
			}

			.map-btn {
				width: 35px;
				height: 35px;
				padding: 0;
				border: 1px solid #6c6f70;
				margin: 0;
				border-radius: 8px;
				cursor: pointer;

				&.zoom-out {
					background: url(#{$imageBaseUrl}/icons/icon-zoom-out.svg) center center no-repeat #209a20;
				}

				&.zoom-in {
					background: url(#{$imageBaseUrl}/icons/icon-zoom-in.svg) center center no-repeat #209a20;
				}

				&.zoom-in,
				&.zoom-out {
					margin-top: 3px;
					background-size: 18px 18px;
				}
			}
		}
	}
}
