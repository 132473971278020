.horizontal-nav {
	display: flex;
	max-width: 100%;
	flex-wrap: wrap;
	justify-content: center;

	.nav-item {
		padding: 0px 15px;
		cursor: pointer;
		text-decoration: none;

		.text {
			margin: 0;
			color: $dinova-green;
			white-space: nowrap;
		}
	}
}
