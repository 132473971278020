.payment-not-connected {
	text-align: center;

	.btn-wrapper {
		display: inline-block;

		.btn {
			white-space: nowrap;
		}
	}

	.title {
		font-weight: 400 !important;
	}

	// for table and up
	@include media-breakpoint-up(md) {
		.title {
			max-width: 60%;
			margin: 0 auto;
		}
	}
}
