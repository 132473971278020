p.text.input-error-text {
	@include fluid-type-default(13px, 16px);

	padding: 0 10px;
	margin: 10px 0 0;
	color: $danger-red;
}

.input-container.error svg {
	position: absolute;
	width: 24px;
	height: 24px;
	fill: $danger-red;
}

// Text and Password Input Error
.input-container.error,
.input-container.error.text,
.input-container.error.select,
.input-container.error.password,
.input-container.error.email {
	svg {
		top: 9px;
		left: 15px;
	}
}

.input-container.error.radio,
.input-container.error.checkbox-group {
	position: relative;
	padding-left: 32px;

	.text.input-error-text {
		padding-left: 0px;
		margin-top: 5px;
	}

	svg {
		top: 0px;
		left: 0px;
	}
}
