@font-face{
	font-family: text-security-disc;
	src: url("https://raw.githubusercontent.com/noppa/text-security/master/dist/text-security-disc.woff");
  }
  
.with-toggle {
	position: relative;

	a {
		color: $black;
	}

	&.disabled {
		opacity: .6;
	}

	.children {
		width: calc(100% - 50px); // 50px = help button with.
		min-height: 10px; // Help button height.
	}

	.title {
		margin-top: 1px;
		margin-right: 51px;
		margin-bottom: 0px;
		cursor: pointer;
	}

	& > .title {
		color: $dinova-green;
	}

	.toggle-btn,
	.trigger {
		position: absolute;
		top: 13px;
		right: 55px;
		height: 100%;
		cursor: pointer;
	}

	.hide
	 {
		-webkit-text-security: disc;
		font-family: text-security-disc;
	  }

	  /* Override styles for specific <p> element */
	p.input-error-text {
		-webkit-text-security: none; /* Reset the text security */
		font-family: inherit; /* Use the parent's font-family */
	}
	
	label {
		font-family: 'Lato' !important;
		-webkit-text-security: none;
	}

	@include media-breakpoint-down(sm) {
		.toggle-btn,
		.trigger {
			top: 6px;
		}
	}
}

.with-toggle.floating {
	position: relative;

	.toggle-btn {
		position: static;
	}
}
