.keywords-section {
	font-size: 16px;

	.label {
		font-weight: bold;

		@include fluid-type-default(13px, 16px);
	}

	.value {
		color: rgb(112, 112, 117);
		font-weight: 400;

		@include fluid-type-default(13px, 16px);
	}
}
