.togo-options-list {
	margin: 0;


	.title {
		@include fluid-type-default(14px, 16px);
	}

	.options-list.togo-options {
		margin-left: 0 !important;

		.option-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 0;
			padding: 1rem .5rem .5rem;
			background-color: $accordion-title-grey;
			border-radius: 10px;

			&:before {
				content: '';
			}

			img {
				height: 6rem;
				margin-bottom: .5rem;
			}
		}
	}
}

@media screen and (max-width: 1023px) {
	.togo-options-list {
		.options-list.togo-options {
			flex-wrap: nowrap;

			.option-item {
				margin-right: 5px;
				margin-left: 5px;

				&:first-child {
					margin-left: 0px;
				}

				&:last-child {
					margin-right: 0px;
				}
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.togo-options-list {
		max-width: 500px;
		margin: 1rem auto 0;
	}
}
