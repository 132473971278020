.more-help {
	margin-top: 40px;

	.title {
		margin: 15px 10px 0;

		@include fluid-type-default(14px, 20px);
	}

	.text {
		padding: 10px 10px 0;

		@include fluid-type-default(14px, 16px);
	}

	.details-contact {
		display: flex;
		padding: 0 10px;

		.contact-info {
			a {
				transition: border 0s;
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.more-help {
		margin-top: 45px;

		.title {
			margin: 15px 15px 5px;
		}

		.text,
		.details-contact {
			padding-left: 15px;

			.contact-info {
				display: flex;
				flex-direction: column;

				.contact-info-item {
					min-height: 38px;
				}

				.contact-info-item:first-child {
					padding-right: 10px;
					padding-bottom: 10px;
					margin-right: 10px;
					border-width: 0 0 3px;
				}
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.more-help {
		.details-contact {
			.contact-info {
				flex-wrap: wrap;

				.contact-info-item {
					width: 100%;

					&:first-child {
						padding: 0 0 10px;
						border-width: 0 0 2px;
					}

					&:nth-child(2) {
						padding: 10px 0 0;
						border-width: 0 0 2px;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 1336px) {
	.more-help {
		.details-contact {
			.contact-info {
				flex-flow: row wrap;

				.contact-info-item {
					width: auto;

					&:first-child {
						padding: 0 15px 0 0;
						margin-right: 0;
						border-width: 0 2px 0 0;
					}

					&:nth-child(2) {
						padding: 0 0 0 15px;
					}
				}
			}
		}
	}
}
