@media screen and (min-width: 768px) {
	.dropdown {
		.label-wrapper {
			padding: 9px 12px 10px;
			padding: 17px 20px 16px;
			margin-right: 20px;

			.label {
				@include fluid-type-default(18px, 20px);
			}

			.value {
				margin: 0;

				@include fluid-type-default(18px, 20px);
			}
		}

		&.has-value {
			.label-wrapper {
				padding: 18px 20px 16px;

				.label {
					@include fluid-type-default(12px, 16px);

					transform: translateY(-120%);
				}
			}
		}

		&.open {
			.label-wrapper {
				padding: 15px 18px 14px;

				&.error {
					padding: 18px 18px 13px;
				}
			}

			&.has-value {
				.label-wrapper {
					padding: 18px 18px 14px;
				}
			}
		}
	}
}
