.transactions_list {
	overflow: hidden;
}

.transactions_list .Di_DateBox {
	width: 74px;
	border: 2px solid $orange-peel;
	margin: 10px 0;
}

.transactions_list .alt {
	background-color: $super-light-grey;
}

.transactions_list td {
	font-weight: 400;
}

.transactions_list .darker {
	color: $black-text;
}

.transactions_list .bigger {
	@include fluid-type-default(14px, 16px);
}

.transactions_list div {
	font-weight: 400;
	text-align: center;

	p.text {
		text-align: left;
	}
}

.transactions_list div.details_1 {
	color: $black;
	font-size: 1.4rem;
}

.transactions_list div.details_2 {
	color: $black-text;
	font-size: 1.2rem;
}

.transactions_list .centerContainer {
	display: flex;
	align-items: center;
}

.transactions_list .centerText {
	justify-content: center;
}
