@import './sv-style';
@import './mv-style';
@import './lv-style';

@-webkit-keyframes autofill {
	to {
		background: transparent;
	}
}

/* Change Autocomplete styles in Chrome */
// input::-webkit-input-placeholder,
// input::placeholder,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-animation-fill-mode: both;
	-webkit-animation-name: autofill;
	background-color: transparent !important;
	border-style: solid !important;
	border-top-width: 0px !important;
	-webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
	transition: background-color 5000s ease-in-out 0s;
}
