$filter-width: 230px;
$list-width: 30vw;
$map-with-list-width: 70vw;
$map-with-filter-width: calc(100vw - 230px);
$map-with-filter-and-list-width: calc(100vw - 30vw - 230px);
$map-alone-width: 100vw;

@media screen and (min-width: 1024px) {
	.search-page {
		.main-content-wrapper .main-content {
			.collapsible-panel {
				// filter panel
				&:first-child {
					> div {
						> div {
							width: calc(#{$filter-width} - 2rem);
						}
					}
				}

				// map panel
				&:last-child {
					width: $map-alone-width;
				}
			}

			&.show-list:not(.show-filter) {
				.collapsible-panel {
					// filter panel
					&:first-child {
						> div {
							> div {
								width: calc(#{$filter-width} - 2rem);
							}
						}
					}
					// results list panel
					&:nth-child(2) {
						width: $list-width;
						margin: 0 1rem 0 1rem; // LEAVE ME
					}

					// map panel
					&:last-child {
						width: $map-with-list-width;
					}
				}
			}

			&.show-filter:not(.show-list) {
				.collapsible-panel {
					// filter panel
					&:first-child {
						width: $filter-width;
					}

					// results list panel
					// &:nth-child(2) {
					// 	width: 0vw;
					// }

					// map panel
					&:last-child {
						width: $map-with-filter-width;
					}
				}
			}

			&.show-list.show-filter {
				.collapsible-panel {
					// filter panel
					&:first-child {
						width: $filter-width;
					}

					// results list panel
					&:nth-child(2) {
						width: $list-width;
					}

					// map panel
					&:last-child {
						width: $map-with-filter-and-list-width;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 1440px) {
	.search-page {
		.main-content-wrapper .main-content {
			&.show-list:not(.show-filter) {
				.collapsible-panel {
					// results list panel
					&:nth-child(2) {
						width: $list-width;
						margin: 0 1rem 0 0;
					}
				}
			}
		}
	}
}
