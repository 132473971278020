.profile-payment-list {
	.item {
		display: flex;
		flex-direction: row-reverse;

		> :first-child {
			width: auto;
			font-weight: 400;
		}

		> :last-child {
			width: 100%;
		}

		&:last-child {
			margin-bottom: 25px;
		}
	}

	.has-help {
		position: relative;
		margin-left: 30px;

		.with-help {
			position: absolute;

			.help-btn {
				top: -5px;
				right: unset;
				left: -45px;

				.Di_SideHelpIcon {
					width: 20px;
					height: 20px;
				}
			}

			.help-panel-wrapper.open {
				.collapse-with-close {
					margin: 25px 44px 10px -44px;

					.inner-wrapper {
						&:before {
							right: unset;
							left: 9px;
						}

						.close-btn {
							right: unset;
							left: 16px;
						}

						.content {
							div.text.md {
								padding-top: 30px;
							}
						}
					}
				}
			}
		}

		&.help-open {
			.with-help {
				z-index: 100;
			}
		}
	}

	.ineligible {
		.item {
			.profile-section-item {
				h3.title,
				span.item-value {
					opacity: .3;
				}
			}
		}
	}
}
