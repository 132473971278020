.select.dropdown {
	.label-wrapper {
		.label {
			&.required {
				&:after {
					margin-left: 1px;
					color: $danger-red;
					content: '*';
				}
			}
		}
	}

	&.error {
		.label-wrapper {
			padding: 8px 10px 7px 43px;
			border-width: 3px;
		}

		.label-wrapper,
		.dropdown-list-wrapper {
			border-color: $danger-red !important;
		}

		&.has-value {
			.label-wrapper {
				padding: 11px 10px 7px 43px;
			}

			.label {
				margin-left: -33px;
			}
		}
	}
}
