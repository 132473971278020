// Just another parallax
.jarallax-keep-img {
  position: relative;
  z-index: 0;
}
.jarallax-keep-img > .jarallax-img {
  position: relative;
  display: block;
  max-width: 100%;
  height: auto;
  z-index: -100;
}
span[data-jarallax-element] {
  display: inline-block;
}
.jarallax {
  min-height: 600px;
}
