.page-error {
	.row.Show {
		display: block;
	}

	.row.Hide {
		display: none;
	}

	.error-box {
		padding: 10px 15px;
		border: solid 3px $danger-red;
		margin-right: 18px;
		margin-bottom: 15px;
		margin-left: 15px;
		color: $danger-red;
	}

	.error-icon {
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: center;
		margin-right: 18px;
		float: left;
	}

	.error-text p {
		margin-bottom: 0;
		color: $danger-red !important;
		font-weight: 500;
	}

	.terms-error-icon {
		margin-top: 0;
		color: $danger-red;
		font-size: 30px;
	}
}
