@media screen and (min-width: 1024px) {
	label.checkbox.sub {
		&:after {
			width: 25px;
			height: 25px;
		}
	}

	input:checked + label.checkbox.sub {
		&:before {
			width: 9px;
			height: 18px;
		}
	}

	.input-label.checkbox.sub {
		padding-left: 35px;
	}
}
