.faq-referral {
	padding: 30px 10px;
	margin: 10px 0;
	border-color: $grey;
	border-style: solid;
	border-width: 2px 0;

	.title {
		margin-bottom: 0px;
	}

	.refer-sharing {
		.share-box {
			.row.mt-3.mb-3 {
				margin-top: 0 !important;
			}
		}

		.darker {
			span {
				@include fluid-type-default(14px, 16px);

				span {
					color: $black !important;
				}
			}
		}

		.row {
			// flex-wrap: nowrap;
			display: inline-flex;
			flex-flow: row nowrap;
		}

		.link.col-6 {
			width: auto;
			max-width: 100%;
			flex: unset;
			margin-right: 10px;
			white-space: nowrap;
		}

		.copy.col-6 {
			width: auto;
			flex: unset;
		}
	}
}

@media screen and (min-width: 768px) {
	.faq-referral {
		margin-top: 45px;
		margin-right: 30px;
		margin-left: 30px;
		border-width: 2px;

		.refer-sharing {
			max-width: 400px;
			margin-right: auto;
			margin-left: auto;

			.share-box {
				.row.mt-3.mb-3 {
					margin-bottom: 15px !important;
				}
			}
		}
	}
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
	.faq-referral {
		.refer-sharing {
			.share-box {
				.row:nth-of-type(2) {
					flex-wrap: wrap;
					align-content: center;
					justify-content: center;
				}
			}
		}
	}
}
