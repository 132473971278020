.recent-searches {
	.label {
		display: inline-block;
		padding: 0 15px;
		margin: 10px 0;
		font-weight: bold;
		line-height: 22px;
		text-transform: uppercase;

		@include fluid-type-default(12px, 18px);
	}

	.list {
		max-height: 156px;
		padding: 0 10px;
		-webkit-overflow-scrolling: touch;
		overflow-y: auto;

		.item {
			display: block;
			width: 100%;
			min-height: 50px;
			padding: 0 10px;
			margin: 0;
			color: $black-text !important;
			line-height: 22px;
			text-align: left;
			text-decoration: none !important;
			text-transform: none;

			@include fluid-type-default(12px, 18px);

			&:not(:last-child) {
				border-bottom: solid 2px #e7e7e7;
			}

			&:hover {
				text-decoration: none;
			}
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		.list {
			max-height: none;
		}
	}
}

.input-container.no-label.error + .input-footer {
	.recent-searches {
		border-color: $danger-red;
	}
}
