.mfa-modalpage {
	overflow: hidden;
	min-height: 200px;

	.wrapper {
		display: flex;
		width: 300%;
		transition: transform 1s;

		.screen {
			width: calc(100% / 3);
		}

		&[class*='screen-1'] {
			transform: translateX(0);
		}

		&[class*='screen-2'] {
			transform: translateX(calc(100% / 3 * -1));
		}

		&[class*='screen-3'] {
			transform: translateX(calc(100% / 3 * -2));
		}
	}
}
