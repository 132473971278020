/* stylelint-disable length-zero-no-unit */
@import './vars/index.scss';

$imageBaseUrl: $cdn-basePath;

$grid-breakpoints: (
	xs: 0px,
	sm: 320px,
	md: 768px,
	lg: 1024px,
	xl: 1440px
);

$container-max-widths: (
	sm: 768px,
	md: 1024px,
	lg: 1440px
);

// $cdn_basePath: get('cdn_basePath');
