.btn.more-button {
	// width: 100% !important;

	@include fluid-type-default(13px, 16px);

	width: auto !important;
	padding: 0;
	border: none !important;
	margin: 0 0 0 2px;
	font-weight: 300;
	text-decoration: underline;
	text-transform: none;

	&#read-more-button {
		text-transform: lowercase;
	}

	&.btn-secondary:active {
		border: none !important;
		background-color: $adminDining_white !important;
		color: $dinova-green !important;
	}

	&.bold {
		font-weight: 400 !important;
	}
}

@media screen and (min-width: 768px) {
	.btn.more-button {
		margin-right: 0;
		margin-left: 0;
	}
}
