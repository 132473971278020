.express-connect-page {
	background-color: $medium-grey;

	> div {
		max-width: 640px;
		padding: 40px 5rem;
		margin: 0 auto;
		background-color: $adminDining_white;
	}

	.page-header {
		padding: 0;
		margin-bottom: 10px;

		.header-bar {
			padding: 10px 0;

			.title {
				margin: 0;
			}
		}
	}

	p.sm {
		@include fluid-type-default(14px, 16px);
	}

	.desc {
		margin-bottom: 10px;
	}

	// for table and up
	@include media-breakpoint-up(md) {
		padding-top: 40px;
		padding-bottom: 40px;

		.page-header,
		.desc {
			margin-bottom: 40px;
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		// styles
	}

	.sucess-wrapper {
		padding: 1rem 0 0 0;

		> p:first-child {
			color: $dinova-green;

			@include fluid-type-default(22px, 24px);

			font-weight: 600;
		}
	}

	.table-wrapper {
		overflow: hidden;
		max-width: 100%;
		border: solid $checkbox-border-grey 1px;
		background-color: $adminDining_white;
		border-radius: 5px;

		> p:first-child {
			padding: 1rem 0;
			margin: 0;
		}

		> p.error {
			padding: 0 0 1rem 0;
			// border-bottom: solid $checkbox-border-grey 1px;
			margin: 0 -1px 0 0;
			color: $danger-red;
		}

		table {
			width: 100%;

			thead,
			tbody tr {
				display: table;
				width: 100%;
				table-layout: fixed; /* even columns width , fix width of table too */
			}

			thead {
				padding: 0 1.25rem;
				border-width: 1px 0px;
				border-style: solid;
				border-color: $checkbox-border-grey;

				tr {
					th,
					td {
						padding: 0 0 0 1.25rem;

						@include fluid-type-default(14px, 16px);
					}

					th {
						border-right: solid $checkbox-border-grey 1px;
						font-weight: 600;

						&:first-child {
							width: 10%;
						}

						&:nth-child(2) {
							width: 90%;
						}

						&:last-child {
							border-right: none;
						}
					}

					td {
						white-space: nowrap;
					}
				}
			}

			tbody {
				display: block;
				overflow: auto;
				max-height: 300px;

				tr {
					td {
						padding: .5rem 1.25rem;

						&:first-child {
							width: 10%;
							padding: 0 1.25rem;
							border-right: solid 1px $checkbox-border-grey;
							text-align: center;
						}

						&:nth-child(2) {
							width: 90%;
						}

						:nth-child(even) {
							text-align: center;
						}
					}
				}

				tr:nth-child(even) {
					background-color: $accordion-title-grey;
				}
			}
		}
	}
}

@media screen and (min-width: 646px) and (max-width: 767px) {
	.express-connect-page {
		padding-top: 20px;
		padding-bottom: 20px;
		background-color: $medium-grey;
	}
}

@media screen and (min-width: 768px) {
	.express-connect-page {
		.edit-profile-actions .btn + * {
			margin-left: 20px;
		}
	}
}
