// Mobile and Tablet
@media screen and (max-width: 1024px) {
	.search-page {
		.search-tab.business-search-tab {
			.search-wrapper {
				.search-group.input-group {
					.input-label {
						padding: 0 0 0 8px;
						margin: 0 0 3px;
					}

					.search-dropdown {
						.form-control {
							padding: 0px 9px;
						}

						::placeholder {
							font-style: normal;
							font-weight: 400;
						}
					}

					.search-btn-wrapper.input-group-append {
						button {
							padding: 3px 0;
						}
					}
				}
			}
		}
	}
}
