.funnel-chart-wrapper {
	min-width: 60%;

	p.lg {
		margin: 20px 0 15px;
		color: $adminDining_white;
		text-align: center;

		@include fluid-type-default(32px, 36px);
	}
}

.funnel-chart {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 50px;

	.funnel-slice {
		position: relative;
		width: 90%;
		padding: 20px 0px 15px;
		background-color: $loading-yellow;
		border-radius: 4px;
		box-shadow: 0px 0px 11px -1px rgba(0, 0, 0, .1);

		.left,
		.right {
			position: absolute;
			top: 0;
			height: 100%;
		}

		.left {
			left: 0;
			display: none;
			transform: translateX(-100%);
			visibility: hidden;
		}

		.right {
			right: 0;
			border-radius: 0 4px 4px 0;
			transform: translateX(100%);

			p.md {
				@include fluid-type-default(16px, 18px);
			}

			p.xl {
				@include fluid-type-default(40px, 48px);

				font-weight: 600;
			}
		}

		&.one {
			.icon {
				position: absolute;
				top: 50%;
				left: 15%;
				display: flex;
				width: 75px;
				height: 75px;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				background-color: rgba(225, 225, 225, .6);
				border-radius: 100%;
				transform: translate(-50%, -50%);

				p {
					margin: 0;
				}

				p:first-child {
					margin: 0 0 2px 0;
				}

				p.xs {
					letter-spacing: -.2px;
					line-height: 10px;
					text-align: center;

					@include fluid-type-default(7px, 9px);
				}

				p.sm {
					line-height: 19px;

					@include fluid-type-default(16px, 18px);
				}

				img {
					width: 30px;
					height: 30px;
				}
			}

			.right {
				display: flex;
				align-items: flex-end;
			}
		}

		&.two {
			width: 70%;
			background-color: transparentize($loading-orange, .1);

			.right {
				// background-color: transparentize($loading-orange, .8);
				background-color: rgb(252, 231, 214);
			}
		}

		&.three {
			width: 50%;
			background-color: transparentize($loading-red, .2);

			.right {
				// background-color: transparentize($loading-red, .9);
				background-color: rgb(250, 234, 232);
			}
		}

		&.four {
			width: 30%;
			background-color: transparentize($dinova-green, .1);

			.right {
				// background-color: transparentize($dinova-green, .8);
				background-color: rgb(217, 231, 213);
			}
		}
	}

	.el-connector {
		position: relative;
		height: 28px;

		.el-connector-bg {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 3px;
			height: 0;
			border-top: 28px solid $input-checked-background-grey;
		}

		.el-connector-text-wrapper {
			position: absolute;
			top: 50%;
			width: 100%;
			text-align: center;
			transform: translateY(-50%);
		}
	}

	.chart-caption {
		margin-bottom: 0px;
		font-size: 14px;
	}
}
