$ie-xl-closed-card-width: calc((1440px / 3) - (#{$card-side-margin} * 2));
$ie-xl-open-card-width: calc((1325px) - (#{$card-side-margin} * 2));

.ie {
	@media screen and (min-width: 1440px) {
		.landing-page {
			.splash-wrapper {
				.cards {
					&.has-active {
						&[class*='1'] {
							transform: translateX(-480px) translateX(-50px);
							// transform: translateX(calc(-1 * #{$xl-closed-card-width}));
						}

						&[class*='2'] {
							transform: translateX(-960px) translateX(100px);
							// transform: translateX(calc(-1 * (#{$xl-closed-card-width} * 2 - (#{$card-side-margin} * 4))));
						}
					}

					.card.dinova-card.slide.active.right {
						.content-wrapper {
							transform: translateX(-480px) translateX(50px);
							// transform: translateX(calc(#{$xl-closed-card-width} * -1));
						}
					}
				}
			}
		}
	}
}
