// @import './colors';


.MuiInputBase-input.MuiOutlinedInput-input {
  @include fluid-type-default(16px, 20px);

  color: $black-text;
  font-family: 'Lato', sans-serif !important;
}

@media screen and (min-width: 1440px) {
  .MuiInputBase-input.MuiOutlinedInput-input {
    font-size: 20px;
  } 
}

// MUI Outlined Input
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-sizeMedium.MuiInputLabel-outlined {
  font-size: 20px;
  font-family: 'Lato', sans-serif !important;
  font-weight: 300;

  &.Mui-focused {
    color: $black-text;
  }
}


.Mui-focused {
  .MuiOutlinedInput-notchedOutline {
    border-color: $dinova-green !important;
    border-width: 3px !important;
  }
}