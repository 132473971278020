$bright-dinova-green: rgb(137, 188, 64);

$cards-closed-wrapper-width: 100vw;
$card-top-margin: 2rem;
$card-side-margin: 1vw;
// $ie-card-side-margin: 25px;
$card-padding: 2rem;
$transition-duration: 300ms;

@keyframes overflowExpanding {
	0% {
		overflow: hidden;
	}

	100% {
		overflow: visible;
	}
}

@keyframes maskExpanding {
	0% {
		height: 100%;
	}

	90% {
		height: 100%;
	}

	100% {
		height: 0;
		padding: 0;
	}
}

.landing-page {
	.splash-wrapper {
		width: 100vw;
		max-width: 1440px;
		background-image: url(#{$imageBaseUrl}/graphics/image-hero-mobile.jpg);
		background-position: 0px -9vw;
		background-repeat: no-repeat;
		background-size: contain;

		.catch-phrase {
			padding: 1rem;

			h1 {
				@include fluid-type-default(28px, 32px);

				max-width: 80%;
				margin-bottom: .5rem;
			}

			p {
				max-width: 67%;
			}
		}

		.horizontal-nav {
			justify-content: flex-start;

			.nav-item {
				p {
					color: $bright-dinova-green !important;
					font-weight: 400;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		.close {
			width: 20px;
			height: 20px;
			cursor: pointer;
			opacity: 1;
		}

		.input-container .input:focus,
		.input-container .input.focused {
			padding: 5px 12px 7px;
			border-right-width: 1px;
			border-right-color: $input-border-grey;
			border-bottom-width: 1px;
			border-bottom-color: $input-border-grey;
			border-left-width: 1px;
			border-left-color: $input-border-grey;
		}

		.focused .top-border-wrapper .focus-border,
		.input-wrapper.open .top-border-wrapper .focus-border {
			border-color: $input-border-grey;
			border-top-width: 1px;
		}

		input.input,
		.dropdown .label-wrapper,
		.calendar-dropdown.dark {
			background-color: rgba(76, 75, 71, 1);
			border-radius: 5px;
		}

		.input-wrapper.focused:not(.error) .typeahead input {
			padding: 8px 12px 7px;
			border-width: 0px 1px 1px;
		}

		input::placeholder {
			color: $adminDining_white;
			font-style: italic;
			font-weight: 300;
		}

		.dropdown.open .label-wrapper {
			border-width: 1px;
			border-color: $input-border-grey;
			border-radius: 5px 5px 0px 0;
		}

		.dropdown .dropdown-list-wrapper {
			border-width: 0px;
			border-color: $input-border-grey;
		}

		.calendar-dropdown.dark.show .dropdown-toggle {
			border-color: $input-border-grey !important;
		}

		.calendar-dropdown.dark {
			.btn-secondary:not([disabled]):not(.disabled):hover {
				border-color: $input-border-grey !important;
			}
		}

		.accordion__arrow:before,
		.accordion__arrow:after {
			background-color: $adminDining_white;
		}

		.cards {
			width: $cards-closed-wrapper-width;
			max-width: 1440px;
			margin-bottom: 3rem;

			.card.dinova-card {
				position: relative;
				overflow: hidden;
				min-height: 100px;
				margin: $card-top-margin $card-side-margin;
				background: $black;
				border-radius: 15px;

				&.dine-assist {
					.promo-banner-content {
						p.promo-header {
							margin-left: 1rem;
						}
					}
				}

				a.card-button {
					border: 2px solid #1c871c !important;
				}

				*:not(.transparent):not(.input-error-text),
				.dropdown .label-wrapper .label {
					color: $adminDining_white;
				}

				.input-error-text {
					@include fluid-type-default(12px, 14px);
				}

				.left {
					display: block;
				}

				.card-background {
					position: absolute;
					overflow: hidden;
					width: 100%;
					height: 100%;
					border-radius: 15px;

					.gradient {
						position: absolute;
						z-index: 2;
						right: -92%;
						bottom: -50%;
						width: 200%;
						height: 200%;
					}
				}

				.promo-banner.bubble {
					position: absolute;
					z-index: 4;
					top: -9rem;
					right: -8.75rem;
					overflow: hidden;
					width: 20rem;
					height: 19rem;
					opacity: 1;
					transition: opacity $transition-duration;

					.promo-banner-content {
						width: 100%;
						height: 100%;
						padding-top: 10rem;
						padding-left: 2rem;
						background-color: rgba(55, 55, 55, .9);
						border-radius: 100%;

						p.promo-header {
							@include fluid-type-default(11px, 12px);

							margin-bottom: 0;
							color: $bright-dinova-green;
							font-style: italic;
							font-weight: 600;
						}

						ul {
							padding: 0 0 0 1rem;
							margin-top: 0;
							margin-bottom: 1rem;
							margin-left: 2rem;

							li {
								line-height: 1;

								p {
									margin-bottom: 0;
									margin-left: -8px;

									@include fluid-type-default(12px, 13px);
								}
							}
						}

						p {
							line-height: 1.5;
						}
					}

					&.sub-content,
					&.blurb {
						.promo-banner-content {
							p:not(.promo-header) {
								@include fluid-type-default(12px, 13px);

								max-width: 85px;
								text-align: center;
							}
						}
					}

					&.blurb {
						.promo-banner-content {
							p:not(.promo-header) {
								margin-left: 4px;
							}
						}
					}
				}

				&.active {
					.promo-banner.bubble {
						z-index: -1;
					}
				}

				.promo-banner:not(.bubble) {
					p.promo-header {
						@include fluid-type-default(14px, 16px);

						margin-bottom: .5rem;
						color: $bright-dinova-green;
						font-style: italic;
						font-weight: 600;
						line-height: 1.2;
					}

					&.list {
						overflow: auto;

						.promo-banner-content {
							ul {
								display: flex;
								padding: 0;
								list-style: none;

								li {
									margin-right: 2rem;
									text-align: center;

									p {
										margin-bottom: .5rem;

										@include fluid-type-default(16px, 18px);
									}

									.promo-image-wrapper {
										display: flex;
										width: 80px;
										height: 80px;
										align-items: center;
										justify-content: center;
										margin: 0 auto;
										
										img {
											width: 100%;
										}
									}
								}
							}
						}
					}
				}

				.content-wrapper {
					z-index: 3;
					display: flex;
					background-color: transparent;

					.dropdown-list {
						p.option {
							width: 100%;
							max-width: unset !important;
							border-width: 0 1px;
							border-color: rgba(76, 75, 71, 1);
							margin-bottom: 0 !important;
							background-color: rgba(76, 75, 71, 1);

							&.selected {
								border-color: rgba(122, 122, 122, 1);
								background-color: rgba(122, 122, 122, 1);
							}

							&:hover {
								border-color: rgba(102, 102, 102, 1);
								background-color: rgba(102, 102, 102, 1);
							}
						}
					}

					.input-footer {
						width: 100%;

						.no-suggestions,
						.suggestions,
						.recent-searches {
							width: 100%;
							padding: 1rem !important;
							border-width: 0;
							margin-bottom: 0;
							background-color: rgba(76, 75, 71, 1);

							li {
								padding: 1rem;
								font-weight: 300;

								@include fluid-type-default(12px, 14px);

								&:hover {
									background-color: rgba(102, 102, 102, 1);
								}
							}

							.label {
								padding-left: 1rem;
								margin: 0;

								@include fluid-type-default(12px, 14px);
							}

							button.item.btn-link {
								width: calc(100% - 1rem);
								padding: 1rem;
								border-bottom: solid 1px rgba(102, 102, 102, 1);
								margin: 0;
								color: $adminDining_white !important;
								cursor: pointer;
								font-weight: 300;
								line-height: 1;
								text-align: left;

								@include fluid-type-default(12px, 14px);

								&:hover {
									background-color: rgba(102, 102, 102, 1);
								}

								&:last-child {
									border-bottom: none;
								}
							}
						}

						.suggestions + .recent-searches {
							padding-top: 0 !important;
						}
					}

					button.btn.btn-secondary.bright.link-button {
						width: auto;
						background-color: transparent !important;
						color: $bright-dinova-green !important;
						font-weight: 600;
						text-align: left;
						text-decoration: none !important;
						white-space: nowrap;

						&:hover {
							text-decoration: underline;
						}
					}

					.face {
						padding: $card-padding;

						.mask {
							height: 100%;
						}
					}

					.face,
					.face .mask {
						width: 100%;
						min-width: 100%;
						max-width: 100%;
					}

					.fold {
						display: flex;

						.left {
							width: 50%;
							padding: $card-padding;
						}

						.right {
							width: 50%;
							padding: $card-padding;

							.promo-banner {
								&.image:not(.list) {
									img {
										width: 90%;
										margin: 3rem 5% 0;
									}
								}
							}
						}
					}
				}
			}

			.card.dinova-card.active {
				z-index: 5;
				animation-duration: .01s;
				animation-fill-mode: forwards;
				animation-iteration-count: 1;
				animation-name: overflowExpanding;

				.promo-banner.bubble {
					opacity: 0;
				}

				.face .mask {
					overflow: hidden;
					animation-duration: $transition-duration;
					animation-fill-mode: forwards;
					animation-iteration-count: 1;
					animation-name: maskExpanding;
				}
			}
		}
	}
}

.ie {
	.landing-page {
		.splash-wrapper {
			.cards {
				.card.dinova-card {
					// margin: $card-top-margin $ie-card-side-margin;
				}
			}
		}
	}
}

.ie,
.safari {
	.landing-page {
		.splash-wrapper {
			.cards {
				.card.dinova-card.active {
					overflow-x: visible;
					overflow-y: visible;

					.promo-banner.bubble {
						z-index: -1;
					}

					.face .mask {
						height: 0;
						padding: 0;
					}
				}
			}
		}
	}
}

@import './sv-style';
@import './mv-style';
@import './lv-style';
@import './xl-style';
@import './ie_styles';
@import './ie-sv-style';
@import './ie-xlv-style';
@import './ie-lv_widths';
