@import '../../vars/colors';

span.Di_HasError {
	input {
		padding-top: 0px !important;
		padding-bottom: 0px !important;
		border-color: $danger-red !important;
		border-width: 3px;
	}
}

@media screen and (min-width: 1024px) {
	span.Di_HasError {
		input {
			padding-top: 4px !important;
			padding-bottom: 4px !important;
			border-color: $danger-red !important;
			border-width: 3px;
		}
	}
}
// Input Wrapper Has Side Icon
.Di_HasSideIcon {
	.Di_SideIcon {
		border-top: solid 0 $input-border-grey !important;
		border-right: solid 0 $input-border-grey !important;
		border-bottom: solid 0 $input-border-grey !important;
	}

	.md-form {
		margin-right: 50px;
	}

	&.Di_HasError {
		.md-form {
			input {
				border-color: $danger-red !important;
			}
		}
	}

	.contained {
		.md-form {
			margin-right: auto;
		}
	}
}

.Di_HasError.Di_NoSideIcon {
	.md-form {
		input {
			border-color: $danger-red !important;
		}
	}
}
