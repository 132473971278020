@media screen and (min-width: 1024px) {
	.Di_PageLimit {
		padding-right: 20px;
		padding-left: 20px;
	}

	.Di_PageLimit-LG {
		width: 50%;
		padding-right: 20px;
		padding-left: 20px;
	}

	.Di_PageLimit-XLG {
		width: 900px;
		padding-right: 20px;
		padding-left: 20px;
	}

	.Di_PageLimit-XXLG {
		width: 1100px;
		padding-right: 20px;
		padding-left: 20px;
	}
}
