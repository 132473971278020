.ie {
	@media screen and (max-width: 1024px) {
		.landing-page {
			.splash-wrapper {
				.cards {
					.card.dinova-card.slide.active.right {
						.content-wrapper {
							transform: translateX(-100%);
							// transform: translateX(calc(#{$mobile-closed-card-width} * -1));
						}
					}

					.card.dinova-card.slide.active.left {
						.content-wrapper {
							transform: translateX(-200%);
							// transform: translateX(calc((#{$mobile-closed-card-width} * -2) - (#{$card-padding} / 5)));
						}
					}
				}
			}
		}

		.dark.calendar-dropdown {
			// this is for when the dropdown is in a card, prevent default positioning when open
			.dropdown-menu {
				@media screen and (max-width: 444px) {
					transform: translate3d(-0px, -100%, 0px) !important;
				}

				@media screen and (min-width: 445px) {
					transform: translate3d(-100%, -100%, 0px) translate3d(360px, 0px, 0px);
					// transform: translate3d(calc((100% - 360px) * -1), -100%, 0px) !important;
					// transform: translate3d(-0px, -100%, 0px) !important;
				}
			}
		}
	}
}
