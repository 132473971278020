.search-page .quick-filters {
	display: flex;
	min-height: 40px;
	align-items: center;

	ul {
		padding: 0;
		margin-left: 1rem;
		list-style-type: none;

		li {
			padding: .2rem 1rem;
			border: solid 2px $dinova-green;
			margin: 0 1rem 0 0;
			border-radius: 20px;
			color: $dinova-green;

			&.selected {
				background-color: $pale-dinova-green;
			}

			p {
				@include fluid-type-default(14px, 16px);
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.search-page .quick-filters {
		overflow: scroll;
		max-width: 100%;

		ul {
			li {
				p {
					white-space: nowrap;
				}
			}
		}
	}
}
