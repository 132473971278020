@media screen and (min-width: 1024px) {
	.top-border-wrapper {
		.focus-border {
			&.front {
				width: 17px;
			}
		}

		.transparent {
			@include fluid-type-default(12px, 16px);

			margin-left: 5px;
		}
	}

	.dropdown {
		.top-border-wrapper {
			.focus-border {
				&.front {
					width: 17px;
				}
			}

			.transparent {
				margin-left: 7px;
			}
		}
	}

	&.required {
		.dropdown {
			.top-border-wrapper {
				.transparent {
					margin-left: 13px;
				}
			}
		}
	}
}
