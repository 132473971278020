.textbox-input {
	@each $key, $value in $cards {
		.#{$key} {
			padding-left: 45px !important;
			background-image: $value;
			background-position-x: 5px !important;
			background-position-y: 21px !important;
			background-repeat: no-repeat !important;
			background-size: 35px !important;

			&:focus {
				background-position-y: 20px !important;
			}
		}
	}
}

.input-wrapper {
	@each $key, $value in $cards {
		&.#{$key} {
			// padding-left: 45px !important;
			background-image: $value;
			background-position-x: 5px !important;
			background-position-y: 10px !important;
			background-repeat: no-repeat !important;
			background-size: 35px !important;

			&:focus {
				background-position-y: 20px !important;
			}

			&:not(.focused) {
				:not(.has-value) {
					.input-label {
						margin-left: 43px !important;
					}
				}
			}

			input {
				padding-left: 45px;

				&.focused,
				&.has-error {
					padding-left: 43px;
				}
			}

			&.error {
				svg {
					display: none;
					opacity: 0;
				}

				input {
					padding-left: 50px !important;
				}
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.input-wrapper {
		@each $key, $value in $cards {
			&.#{$key} {
				background-position-y: 20px !important;
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.input-wrapper {
		@each $key, $value in $cards {
			&.#{$key} {
				background-position-y: 18px !important;
			}
		}
	}
}
