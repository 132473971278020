.missing-registration .Di_HomeText {
	font-size: 2.2rem;
	font-weight: bold;
}

.missing-registration button {
	width: auto !important;
	height: auto !important;
	padding: 15px 20px !important;
	margin-top: 25px;
}
