.Di_HeaderIconMenu {
	display: none;
}

@media screen and (min-width: 1024px) {
	.Di_HeaderIconMenu {
		display: flex;
		flex-direction: column;

		.nav-item {
			&:nth-child(n + 2) {
				// stuff here
				margin-left: 2rem;
			}
		}
	}
}
