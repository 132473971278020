$ie-scrollbar: 20px;
$ie-desktop-closed-card-width: calc((100% / 3) - (#{$card-side-margin} * 2));
$ie-desktop-open-card-width: calc(92% - (#{$card-side-margin} * 2));
$transition-duration: 600ms;

@media screen and (min-width: 1024px) {
	.ie {
		.landing-page {
			.splash-wrapper {
				.cards {
					&.has-active {
						&[class*='1'] {
							transform: translateX(-33.333%);
						}

						&[class*='2'] {
							transform: translateX(
								calc(-1 * (#{$ie-desktop-closed-card-width} * 2 - (#{$card-side-margin} * 4) - #{$ie-scrollbar}))
							);
						}
					}

					.card.dinova-card {
						width: $ie-desktop-closed-card-width;
						min-width: $ie-desktop-closed-card-width;
						max-width: $ie-desktop-closed-card-width;

						.content-wrapper {
							.face {
								width: 100%;
								min-width: 100%;
								max-width: 100%;
							}

							.fold {
								width: 200%;
								min-width: 200%;
								max-width: 200%;
								transition: width $transition-duration;
							}
						}
					}

					.card.dinova-card.active {
						width: $ie-desktop-open-card-width;
						min-width: $ie-desktop-open-card-width;
						max-width: $ie-desktop-open-card-width;
						animation-delay: $transition-duration;

						.content-wrapper {
							.fold {
								width: 100%;
								min-width: 100%;
								max-width: 100%;
							}
						}
					}

					.card.dinova-card.slide.active.right {
						.content-wrapper {
							transform: translateX(-100%);
							// transform: translateX(-92vw) translateX(-20px) translateX(-2vw);
						}
					}
				}
			}
		}
	}
}
