// Link Button
.btn-link,
.btn.btn-link {
	color: $dinova-green !important;
	text-decoration: none;

	&:not([disabled]):not(.disabled) {
		&:active,
		&.active {
			box-shadow: none !important;
		}
	}
}
