.welcome-phrase,
.welcome {
	color: $grey-yellow;
	font-size: 14px;
	font-weight: 500;
	line-height: 24px;
}

@media (min-width: 768px) {
	.welcome-phrase,
	.welcome {
		font-size: 20px;
		line-height: 20px;
	}
}

@media (min-width: 1024px) {
	.welcome-phrase,
	.welcome {
		@include fluid-type-default(14px, 16px);
	}
}
