.funnel-chart-wrapper {
	&.mv {
		max-width: 65%;

		// So, how do you increase...?
		.funnel-chart .one .right h1 {
			max-width: 300px;
			margin: 0 0 0 12px;
		}

		.right {
			top: 5%;
			// right: 1px;
			display: flex;
			height: 90%;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 0 12px;

			p {
				width: 100%;
				text-align: center;
			}
		}

		.funnel-chart {
			.funnel-slice {
				&.two .right {
					min-width: 239px;
				}

				&.three .right {
					min-width: 287px;
				}

				&.four .right {
					min-width: 335px;
				}
			}
		}
	}
}
