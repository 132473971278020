.options-list {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	padding: 0;
	margin: 0;
	list-style-type: none;

	&.single-col {
		display: block;

		.option-item {
			width: auto;
			max-width: 100%;
			white-space: nowrap;
		}
	}

	&.bulletless {
		.option-item {
			&:before {
				content: '';
			}
		}

	}
}
