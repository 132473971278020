@media screen and (min-width: 768px) {
	.landing-page {
		.splash-wrapper {
			.input-container .input:focus,
			.input-container .input.focused {
				padding: 17px 20px 18px;
			}

			.input-wrapper.focused:not(.error) .typeahead input {
				padding: 18px 20px;
				border-width: 0px 1px 1px;
			}

			.input-container.text .input-label {
				margin-left: 18px;
			}
		}
	}
}
