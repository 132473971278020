// REMOVE ME
@import '../../../styles/common/mixins/responsive';

.input-trigger {
	position: absolute;
	top: 4px;
	right: 0px;
	display: inline-flex;
	min-width: 50px;
	height: 100%;
	flex-direction: column;
	align-items: center;

	img {
		width: 20px;
		height: 20px;
	}

	p {
		display: inline;
		margin: 1px 0 0;

		@include fluid-type-default(10px, 14px);
	}
}

@media screen and (min-width: 768px) {
	.input-trigger {
		top: 0px;

		img {
			width: 30px;
			height: 30px;
			margin-top: 4px;
		}

		p {
			margin-top: .5em;
			line-height: unset;
		}
	}
}

.app-wrapper:not(.ie) {
	@media screen and (min-width: 1024px) {
		.input-trigger {
			top: -4px;
		}
	}
}
