.input-label.text,
.input-label {
	margin-bottom: 0;
	color: $black-text;
	font-weight: 300;
	transition: all .3s ease;

	@include fluid-type-default(14px, 18px);
}

.required {
	.input-label:not(.checkbox):not(.radio):after {
		margin-left: 1px;
		color: $danger-red;
		content: '*';
	}

	&.label:after {
		margin-left: 1px;
		color: $danger-red;
		content: '*';
	}
}

label.input-label.required.radio:after,
p.input-label.required.radio:after {
	margin-left: 1px;
	color: $danger-red;
	content: '*';
}

// Text and Password Input Labels
.input-container.password,
.input-container.text,
.input-container.email,
.input-container.dropdown {
	.input-label {
		position: absolute;
		z-index: 2;
		top: 10px;
		margin-left: 12px;
	}

	&.focused,
	&.open,
	&.has-value {
		.input-label {
			top: -10px;

			@include fluid-type-default(12px, 16px);
		}
	}

	&.error {
		.input-label {
			margin-left: 46px; // to make space for the error icon
		}

		&.has-value {
			.input-label {
				top: -10px;
			}
		}
	}

	&.has-value.error,
	&.focused.error {
		.input-label {
			margin-left: 9px;
		}
	}

	&.focused.error &.open.error {
		.input-label {
			top: -10px;
			margin-left: 12px;
		}
	}
}

label.sub {
	position: relative;
	padding: 0 0 0 30px;
}
