@import '../../mixins/responsive';
@import '../../vars/colors';
@import '~bootstrap/scss/mixins';

// Page Header Links Back Button className.
.link-back {
	color: $dinova-green;
	font-weight: bold;
	text-decoration: none !important;
	white-space: nowrap;

	@include fluid-type(map-get($grid-breakpoints, xs), map-get($grid-breakpoints, md), 14px, 16px);
}
