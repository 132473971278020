.promo {
	position: relative;
	display: block;
	background-position: left center;
	background-repeat: no-repeat;

	.promo-img {
		width: 100%;
	}

	&:not(:first-child) {
		margin-top: 5px;
	}

	.learn-more {
		position: absolute;
		right: 15px;
		bottom: 10px;
		display: inline-block;
		color: #fff;
		font-size: 12px;
		font-weight: bold;
		text-transform: uppercase;
	}

	// for table and up
	@include media-breakpoint-up(md) {
		max-width: calc(50% - 3px);

		.promo-img {
			width: 100%;
		}

		&:not(:first-child) {
			margin-top: 0;
		}

		&:not(:nth-child(-n + 2)) {
			margin-top: 10px;
		}

		.learn-more {
			right: 10px;
			bottom: 7px;
			font-size: 12px;
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		max-width: calc(50% - 5px);

		.learn-more {
			right: 10px;
			bottom: 10px;
			font-size: 16px;
		}
	}
}
