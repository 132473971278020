.basic-modal.points-elegibility-modal {
	.basic-modal-content-wrapper {
		max-width: 550px !important;

		.basic-modal-content {
			.title {
				max-width: 90%;
				margin: 20px auto;
			}

			.points-elegibility-content-wrapper {
				display: flex;
				flex-direction: column;
				padding: 0 0 2.5rem 0;

				button.btn {
					width: 100%;
					margin-right: 0px;
					margin-bottom: 0px;
					margin-left: 0px;
				}
			}
		}
	}
}
