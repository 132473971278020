.input-container.radio {
	.radio-options {
		margin-top: 13px;

		&.horizontal {
			display: flex;

			> div {
				margin-right: 30px;

				&:first-child {
					margin-left: 10px;
				}

				&:last-child {
					margin-right: 0px
				}
			}
		}
	}

	/* stylelint-disable no-descending-specificity */
	&.inline {
		> div {
			display: flex;
			justify-content: space-between;

			label.input-label.radio {
				margin-right: 10px;
			}

			.radio-options.horizontal {
				margin-top: 0;
			}
		}
	}
	/* stylelint-enable no-descending-specificity */
}
