.container {
	padding-right: 0px;
	padding-left: 0px;
}

@media screen and (min-width: 320px) {
	.container {
		max-width: 768px;
	}
}

@media screen and (min-width: 768px) {
	.container {
		max-width: 1024px;
	}
}

@media screen and (min-width: 1024px) {
	.container {
		max-width: 1440px;
	}
}
