// for search page
.search-top-bar {
	form.business-search {
		display: flex;
		flex-direction: column;
		padding: 0 10px 10px;

		.input-wrapper,
		.input-footer {
			background-color: $adminDining_white;
		}


		.input-wrapper:first-child {
			margin-top: 0;
		}

		.input-wrapper {
			height: 40px;

			.input-container {
				.input.has-value {
					border-top-color: $input-border-grey !important;

					&.focused {
						border-top-color: $dinova-green !important;
						border-right-width: 3px !important;
					}

					&.no-label {
						&.error {
							border-top-color: $danger-red !important;
						}
					}
				}
			}

			&:first-of-type {
				margin-bottom: 25px;

				.input-container {
					.input.has-value {
						&.focused {
							border-bottom-left-radius: 0px !important;
						}
					}
				}
			}

			&:nth-of-type(2) {
				margin-bottom: 0px;

				.input-container {
					.input.has-value {
						&.focused {
							border-width: 3px !important;
						}
					}
				}
			}

			.input-container.text .input-label {
				top: -19px;
				margin-left: 10px;
				font-weight: 600;

				&.typeahead {
					top: -19px;
				}
			}
		}

		.input-container .input.text,
		input::placeholder {
			@include fluid-type-default(14px, 16px);
		}

		.recent-searches {
			border-width: 0px 3px 3px;
			border-style: solid;
			border-color: $dinova-green;

			.item.btn.btn-link {
				width: 100%;
				padding: 1rem;
				margin: 0;
				color: black !important;
				font-weight: 600;
				text-align: left;
				text-transform: none;

				@include fluid-type-default(12px, 14px);

				&:hover {
					background-color: $dinova-green;
					color: $adminDining_white !important;
				}
			}
		}

		.suggestions {
			border-bottom: none;

			li {
				padding: 1rem;

				@include fluid-type-default(12px, 14px);
			}
		}
	}

	@media screen and (min-width: 768px) {
		form.business-search {
			flex-direction: row;
			padding: 0;

			.input-wrapper {
				margin: 0;

				.input-container.text .input-label {
					margin-left: 0px;
				}

				.input-container.text .input-label {
					top: -21px;

					&.typeahead {
						top: -21px;
					}
				}

				&:first-of-type {
					max-width: 353px;
					margin-bottom: 0;
				}

				&:first-child {
					.top-border-wrapper {
						.front {
							border-radius: 5px 0 0 0;
						}

						.back {
							border-radius: 0;
						}
					}
				}

				&:nth-child(2) {
					.top-border-wrapper {
						.front,
						.back {
							border-radius: 0;
						}
					}
				}
			}

			#business-find {
				border-right-width: 0px;
				border-radius: 5px 0px 0 5px;
			}

			#business-near-autocomplete {
				border-radius: 0;
			}

			input {
				height: 40px;
				padding: 0px 9px !important;
			}

			button[type='submit'].btn.btn-primary {
				width: 95px !important;
				height: 40px;
				padding: 0px;
				border-width: 1px;
				margin: 0;
				border-radius: 0 5px 5px 0;
				font-size: 16px;
			}
		}
	}
}
