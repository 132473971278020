.accordion.table {
	margin-bottom: 0px;

	.accordion__item {
		.accordion__title {
			border: none;
			background-color: $accordion-title-light-grey;

			.section-title {
				@include fluid-type-default(14px, 16px);

				padding-top: .5em;
				padding-bottom: .5em;
				font-weight: 400;
			}
		}
	}
}
