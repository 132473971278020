.social-share {
	.fa-facebook-f,
	.fa-twitter,
	.fa-linkedin-in,
	.fa-envelope {
		width: 44px;
		height: 44px;
		padding: 10px;
		background: transparent;
		color: white;
		font-size: 44px;
		text-align: center;
		text-decoration: none;
	}

	.social div {
		display: flex;
		width: 50px;
		height: 50px;
		align-items: center;
		justify-content: center;
		background-color: black;
		border-radius: 50%;
	}

	@media (min-width: 768px) {
		.fa-facebook-f,
		.fa-twitter,
		.fa-linkedin-in,
		.fa-envelope {
			width: 55px;
			height: 55px;
			padding: 10px;
			font-size: 50px;
		}

		.fa-linkedin-in {
			padding-bottom: 10px;
		}

		.social div {
			width: 77px;
			height: 77px;
		}
	}
}
