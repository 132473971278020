.COVIDSafetyMeasures {
	padding: 2rem;
	background-color: $covid-yellow;

	* {
		color: $black;
	}

	.title {
		@include fluid-type-default(16px, 26px);

		margin-bottom: .5rem;
	}

	.text {
		@include fluid-type-default(14px, 16px);
	}

	.header {
		.text {
			@include fluid-type-default(20px, 22px);
		}
	}

	.more-button.btn.btn-secondary {
		background-color: transparent !important;

		&:active {
			background-color: $covid-yellow !important;
		}
	}

	.COVIDRestaurantUniquePrecautions,
	.COVIDRestaurantFeedback {
		.text,
		button.btn.more-button {
			@include fluid-type-default(18px, 18px);
		}
	}

	.COVIDRestaurantUniquePrecautions {
		.text {
			position: relative;

			button.more-button {
				position: absolute;
				bottom: -5px;
			}
		}
	}

	.COVIDRestaurantFeedback {
		.text {
			button.more-button {
				margin-right: 3px !important;
				margin-bottom: 4px;
			}
		}
	}

}

@media screen and (max-width: 1023px) {
	.COVIDSafetyMeasures {
		.header {
			.text {
				margin-bottom: 1rem !important;
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.COVIDSafetyMeasures {
		border-radius: 4px;
	}

	.COVIDRestaurantUniquePrecautions,
	.COVIDRestaurantFeedback {
		.text {
			display: inline-flex;
		}
	}
}
