.icon-nav-item {
	display: flex;
	align-items: center;
	justify-content: center;
	color: $black;
	text-decoration: none;

	&:nth-child(n + 2) {
		margin-left: 2rem;
	}

	&.active {
		color: $dinova-green;
	}

	img {
		width: 18px;
		height: 18px;
		margin-right: 5px;
		margin-left: 5px;
	}

	span {
		@include fluid-type-default(12px, 14px);

		font-weight: 500;
		line-height: 20px;
	}

	&:hover {
		color: $black;
		text-decoration: none;

		&.active {
			color: $dinova-green;
		}
	}
}

@media screen and (min-width: 768px) {
	.icon-nav-item {
		// height: 30px;
	}
}
