$xl-closed-card-width: calc((1440px / 3) - (#{$card-side-margin} * 2));
$xl-close-card-mask-width: calc((1440px / 3) - (#{$card-side-margin} * 2) - (#{$card-padding-side} * 2));
$xl-open-card-width: calc((1325px) - (#{$card-side-margin} * 2));

@media screen and (min-width: 1440px) {
	.landing-page {
		.splash-wrapper {
			.cards {
				&.has-active {
					&[class*='1'] {
						transform: translateX(calc(-1 * #{$xl-closed-card-width}));
					}

					&[class*='2'] {
						transform: translateX(calc(-1 * (#{$xl-closed-card-width} * 2 - (#{$card-side-margin} * 4))));
					}
				}

				.card.dinova-card {
					width: $xl-closed-card-width;
					min-width: $xl-closed-card-width;
					max-width: $xl-closed-card-width;

					.content-wrapper {
						.fold {
							width: $xl-open-card-width;
							min-width: $xl-open-card-width;
							max-width: $xl-open-card-width;
						}

						.face {
							width: $xl-closed-card-width;
							min-width: $xl-closed-card-width;
							max-width: $xl-closed-card-width;
						}

						.face .mask {
							width: 100%;
						}
					}
				}

				.card.dinova-card.active {
					width: $xl-open-card-width;
					min-width: $xl-open-card-width;
					max-width: $xl-open-card-width;
				}

				.card.dinova-card.slide.active.right {
					.content-wrapper {
						transform: translateX(calc(#{$xl-closed-card-width} * -1));
					}
				}
			}
		}
	}
}
