.edit-security-form {
	.required-field-note {
		margin-bottom: 30px;
	}

	.field-wrapper {
		margin-top: 35px;

		.curr-password-field {
			margin-bottom: 0;
		}
	}

	.help-panel-wrapper {
		position: absolute;
		z-index: 999;
		top: 59%;
	}

	// for table and up
	@include media-breakpoint-up(md) {
		.required-field-note {
			margin-bottom: 24px;
		}

		.field-wrapper {
			margin-top: 28px;
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		.required-field-note {
			margin-bottom: 25px;
		}

		.field-wrapper {
			margin-top: 25px;
		}
	}
}
