.row {
  display: flex;
  flex-direction: row;
}

.fullWidth {
  width: 100%;
}

.fullHeight {
  height: 100%;
}

.align-center {
    align-items: center;
  }


.justify-space-between {
  justify-content: space-between;
}

.justify-flex-start {
  justify-content: flex-start;
}

.justify-flex-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}





