$card-padding-top: 3rem;
$card-padding-side: 3rem;
$transition-duration: 500ms;

@import './lv_widths';

@media screen and (min-width: 1024px) {
	.landing-page {
		.splash-wrapper {
			background-image: url(#{$imageBaseUrl}/graphics/image-hero.jpg);
			background-position: top right;

			.horizontal-nav {
				.nav-item {
					padding: 2px 15px;
				}
			}

			.input-container .input:focus,
			.input-container .input.focused {
				padding: 14px 17px 15px;
			}

			.input-wrapper.focused:not(.error) .typeahead input {
				padding: 17px 17px 16px;
				border-width: 0px 1px 1px;
			}

			.input-container.text .input-label {
				// margin-left: 18px;
			}

			.cards {
				display: flex;
				flex-wrap: nowrap;
				transition: width $transition-duration, min-width $transition-duration, transform $transition-duration,
					height $transition-duration, max-height $transition-duration, min-height $transition-duration;

				.card.dinova-card {
					overflow: hidden;

					.card-background {
						width: 100%;
						height: 100%;
						max-height: 100%;
						transition: height $transition-duration, max-height $transition-duration, border-radius $transition-duration;

						.gradient {
							right: 0;
							bottom: 0;
							width: 100%;
							height: 100%;
							background: linear-gradient(
								0deg,
								rgba(255, 255, 255, 0) 0%,
								rgba(0, 0, 0, 0) 1%,
								rgba(0, 0, 0, .5) 17%,
								black 47%,
								black 100%
							);
							transition: height $transition-duration, max-height $transition-duration, bottom $transition-duration,
								right $transition-duration;
						}

						img {
							width: 100%;
						}
					}

					&[class*='0'] {
						// business card
						.card-background {
							img {
								position: absolute;
								z-index: 1;
								bottom: 0;
								transition: bottom $transition-duration;
							}
						}

						.content-wrapper {
							.face .mask {
								h2 {
									max-width: calc(100% - 161px);
								}

								p {
									max-width: calc(100% - 112px);
								}
							}
						}
					}

					&[class*='1'] {
						// catering card
						.card-background {
							img {
								position: absolute;
								z-index: 1;
								bottom: -8%;
								width: 100%;
								transition: bottom $transition-duration;
							}
						}
					}

					&[class*='2'] {
						// dineassit card
						.card-background {
							img {
								position: absolute;
								z-index: 1;
								right: 0;
								bottom: -4%;
								width: 100%;
								height: 110%;
							}
						}
					}

					.promo-banner.bubble {
						width: 23rem;
						height: 21rem;

						.promo-banner-content {
							padding-top: 11rem;
							padding-left: 3.75rem;
							opacity: 1;
							transition: opacity $transition-duration;

							p:not(.promo-header) {
								line-height: 1.5;
							}
						}

						&.list {
							.promo-banner-content {
								ul {
									img {
										width: 80px;
									}
								}
							}
						}
					}

					.content-wrapper {
						height: 100%;

						button.btn.btn-primary.card-button,
						a.card-button {
							width: 50%;
							min-width: 240px;
							align-self: center;
							padding: .5rem 0rem;
							margin: 0;
							border-radius: 15px;
						}

						a.card-button {
							background-color: $dinova-green;
							font-size: 20px;
							line-height: 1.5;
							text-align: center;
							text-decoration: none;
						}

						.face,
						.fold {
							form {
								button.btn.btn-primary[type='submit'] {
									width: 50%;
									min-width: unset;
									align-self: center;
									padding: .5rem 0rem;
									margin: 0;
									border-radius: 15px;

									& + .footer {
										margin-left: 2rem;
									}
								}

								&[name='catering-search'] {
									display: flex;
									flex-wrap: wrap;

									.input-wrapper {
										&:nth-child(2),
										&:nth-child(3) {
											width: 100%;
										}

										&:nth-child(2) {
											padding: 0;
										}

										&:nth-child(3) {
											padding: 0 0 0 1rem;

											.calendar-dropdown {
												width: 100%;
												height: 100%;
											}
										}
									}
								}
							}
						}

						.face {
							padding: $card-padding-top $card-padding-side;
						}

						.face .mask {
							display: flex;
							flex-direction: column;
							justify-content: flex-start;

							h2 {
								@include fluid-type-default(28px, 32px);

								max-width: 63%;
								margin-bottom: .5rem;
							}

							p {
								max-width: 65%;
								margin-bottom: 0px;
							}

							> div:first-child {
								height: 51%;
							}
						}

						.fold {
							position: relative;
							display: flex;
							flex-direction: row-reverse;

							.close {
								position: absolute;
								top: 2rem;
								right: 2rem;
							}

							p.text.sm {
								@include fluid-type-default(12px, 14px);
							}

							.left,
							.right {
								padding: $card-padding-top $card-padding-side;

								h3 {
									@include fluid-type-default(24px, 28px);
								}
							}

							.left {
								.btns-wrapper {
									display: flex;
									width: 100%;
									// flex-direction: column;
									align-items: center;
									justify-content: space-between;
								}
							}

							.right {
								position: relative;

								&:after {
									position: absolute;
									top: 11%;
									right: 0;
									height: 70%;
									border: solid rgb(147, 147, 147) 1px;
									border-width: 0 1px 0 0;
									content: '';
								}
							}
						}
					}

					&.catering {
						// about blurb with promo image below
						.content-wrapper .fold p.text.sm {
							margin-bottom: 0px;
						}
					}
				}

				.card.dinova-card.active {
					animation-delay: $transition-duration;

					.card-background {
						.promo-banner.bubble {
							z-index: -1;

							.promo-banner-content {
								opacity: 0;
							}
						}
					}

					&[class*='0'] {
						// business card
						.card-background {
							img {
								bottom: -33%;
								// top: -45vw;
								// right: -12vw;
								// width: 67vw;
							}
						}
					}

					&[class*='1'] {
						// catering card
						.card-background {
							img {
								bottom: -97%;
							}
						}
					}

					.content-wrapper {
					}
				}

				.card.dinova-card.slide {
					transition: margin $transition-duration, border-radius $transition-duration, width $transition-duration,
						min-width $transition-duration, height $transition-duration, max-height $transition-duration,
						min-height $transition-duration;

					.content-wrapper {
						transition: transform $transition-duration, width $transition-duration, min-width $transition-duration,
							height $transition-duration, max-height $transition-duration, min-height $transition-duration;
						min-height: 500px;
					}
				}

				.card.dinova-card.slide.active.left {
					.content-wrapper {
					}
				}
			}
		}
	}

	.safari {
		.landing-page {
			.splash-wrapper {
				.cards {
					.card.dinova-card {
						.promo-banner.bubble {
							transition: none;

							.promo-banner-content {
								transition: none;
							}
						}
					}
				}
			}
		}
	}
}
