@media screen and (max-width: 1024px) {
	.restaurant-cta-menu {
		display: inline-flex;
		width: 100%;
		flex-wrap: wrap;
		margin-top: 1em;
		margin-bottom: 1em;

		.cta-btn {
			flex: 1;
			padding: 8px;
			// border: solid orange 2px;
			margin: 0 1.5vw;
			color: $black-text;
			font-weight: 600;
			text-align: center;
			text-decoration: none;

			@include fluid-type-default(14px, 18px);

			img {
				height: 25px;
				padding: 0 .5em;
				margin-top: -3px;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.restaurant-cta-menu {
		.cta-btn {
			img {
				height: 40px;
			}
		}
	}
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
	.restaurant-cta-menu {
		width: auto;
	}
}

@media screen and (min-width: 1024px) {
	.restaurant-cta-menu {
		padding: 0 2em;

		.cta-btn {
			padding: 1em 0;
			border-bottom: solid $grey 1px;

			&:last-child {
				border-bottom: none;
			}

			img {
				width: 50px;
				height: 30px;
				padding: 0 .5em;
				margin-top: -3px;
				margin-right: .5em;
			}
		}
	}
}
