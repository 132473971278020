.grid-header {
	.grid-header-item {
		margin-bottom: 5px;
	}
}

.grid-header.table {
	padding-top: .5em;
	padding-bottom: .5em;
	border-bottom: solid 1px $dnaGray;
	margin-bottom: 0px;
	// background-color: $accordion-title-light-grey;

	.grid-header-item {
		margin-bottom: 0px;
		font-weight: 600;
	}
}
