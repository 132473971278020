$mobile-closed-card-width: 98vw;
$mobile-closed-card-height: 40vw;
$mobile-closed-card-min-height: 210px;
$mobile-closed-card-max-height: 200px;
$mobile-open-card-height: 70vw;
$mobile-open-card-min-height: 440px;
$mobile-open-card-max-height: 435px;
$mobile-open-catering-card-height: 180vw;
$mobile-open-catering-card-min-height: 440px;
$mobile-open-catering-card-max-height: 560px;
$transition-duration: 300ms;

@media screen and (max-width: 1024px) {
	.landing-page {
		.splash-wrapper {
			.cards {
				transition: width $transition-duration, min-width $transition-duration, transform $transition-duration,
					height $transition-duration, max-height $transition-duration, min-height $transition-duration;

				.card.dinova-card {
					height: $mobile-closed-card-height;
					min-height: $mobile-closed-card-min-height;
					// max-height: $mobile-closed-card-max-height;

					.input-error-text {
						max-width: 84%;
					}

					&:first-child {
						margin-top: 0;
					}

					.card-background {
						height: 100%;
						max-height: 100%;
						transition: height $transition-duration, max-height $transition-duration, border-radius $transition-duration;

						.gradient {
							background: radial-gradient(
								circle,
								rgba(255, 255, 255, 0) 0%,
								rgba(140, 140, 140, 0) 10%,
								black 28%,
								black 100%
							);
							transition: height $transition-duration, max-height $transition-duration, bottom $transition-duration,
								right $transition-duration;
						}
					}

					&[class*='0'] {
						// business card
						.card-background {
							img {
								position: absolute;
								z-index: 1;
								top: -7.5vw;
								right: -12%;
								width: 67vw;
							}
						}
					}

					&[class*='1'] {
						// catering card
						.card-background {
							img {
								position: absolute;
								z-index: 1;
								right: -17%;
								width: 54vw;
							}
						}
					}

					&[class*='2'] {
						// dineassit card
						min-height: 220px;

						.card-background {
							img {
								position: absolute;
								z-index: 1;
								right: 0;
								bottom: -52%;
								width: 41vw;
							}
						}
					}

					.content-wrapper {
						height: 100%;
						max-height: 100%;

						.fold {
							width: 200%;
							min-width: 200%;
							max-width: 200%;

							.left {
								.catering-zones {
									max-width: 85%;

									.nav-item {
										width: 175px;
										max-width: 50%;

										p.text {
											@include fluid-type-default(14px, 16px);
										}
									}
								}
							}

							.right {
								display: flex;
								flex-direction: column;
								justify-content: space-between;

								> div:first-child {
									display: flex;
									height: 100%;
									flex-direction: column;
									justify-content: space-between;

									button {
										align-self: center;
									}
								}
							}
						}
					}
				}

				.card.dinova-card.active {
					height: $mobile-open-card-height;
					min-height: $mobile-open-card-min-height!important;
					// max-height: $mobile-open-card-max-height;
					animation-delay: $transition-duration;

					&.catering {
						height: $mobile-open-catering-card-height;
						min-height: $mobile-open-catering-card-min-height;
						// max-height: $mobile-open-catering-card-max-height!important;
					}
				}

				.card.dinova-card.slide {
					transition: margin $transition-duration, border-radius $transition-duration, width $transition-duration,
						min-width $transition-duration, height $transition-duration, max-height $transition-duration,
						min-height $transition-duration;
					min-height: 300px;

					.content-wrapper {
						transition: transform $transition-duration, width $transition-duration, min-width $transition-duration,
							height $transition-duration, max-height $transition-duration, min-height $transition-duration;
					}
				}

				.card.dinova-card.slide.active.right {
					margin: 2rem 0vw 2rem 1vw;
					border-radius: 15px 0 0 15px;

					&:first-child {
						margin-top: 0;
					}

					.card-background {
						border-radius: 15px 0 0 15px;
					}

					.content-wrapper {
						transform: translateX(calc(#{$mobile-closed-card-width} * -1));
					}
				}

				.card.dinova-card.slide.active.left {
					margin: 2rem 1vw 2rem 0vw;
					border-radius: 0 15px 15px 0;

					&:first-child {
						margin-top: 0;
					}

					.card-background {
						border-radius: 0 15px 15px 0;
					}

					.content-wrapper {
						transform: translateX(calc((#{$mobile-closed-card-width} * -2) - (#{$card-padding} / 5)));
					}
				}

				&.has-active {
					.card.dinova-card.active {
						.card-background {
							.gradient {
								bottom: -27%;
							}
						}
					}
				}
			}
		}
	}

	.landing-page {
		.splash-wrapper {
			.cards {
				.card.dinova-card {
					.content-wrapper {
						button.btn.btn-primary.card-button,
						a.card-button {
							width: 50%;
							min-width: 240px;
							padding: .5rem 0rem;
							margin: 0;
							border-radius: 15px;
						}

						a.card-button {
							background-color: $dinova-green;
							font-size: 20px;
							line-height: 1.5;
							text-align: center;
							text-decoration: none;
						}

						.face .mask {
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: space-between;

							> div {
								width: 100%;
							}

							h2 {
								@include fluid-type-default(24px, 28px);

								max-width: 200px;
								margin-bottom: 0px;
							}

							p {
								max-width: 200px;
								margin-bottom: 0px;
							}
						}

						.fold {
							.left,
							.right {
								position: relative;
							}

							.left {
								h3 {
									@include fluid-type-default(24px, 28px);

									margin-bottom: 0px;
								}

								p:not(.value):not(.label):not(.input-error-text) {
									max-width: 200px;
									margin-bottom: 1rem;
								}

								.btns-wrapper {
									display: flex;
									flex-direction: column;
									align-items: center;

									button.btn.btn-primary {
										width: 50%;
										min-width: 240px;
										padding: .5rem 0rem;
										margin: 0;
										border-radius: 15px;
									}

									button.btn.btn-secondary.link-button {
										text-align: center;
									}
								}

								.close {
									position: absolute;
									right: 2rem;
									bottom: 2rem;
								}
							}

							.right {
								h3 {
									@include fluid-type-default(24px, 28px);

									max-width: 234px;
									margin: 1rem 0 1.5rem;

									& + p {
										max-width: 70%;
									}
								}

								p {
									margin-bottom: 1rem;
								}

								.close {
									position: absolute;
									right: 3rem;
									bottom: 2rem;
								}
							}

							.footer {
								display: flex;
								width: 100%;
								justify-content: center;
								margin: 2rem 0;
							}
						}
					}
				}
			}
		}
	}

	.dark.calendar-dropdown {
		// this is for when the dropdown is in a card, prevent default positioning when open
		.dropdown-menu {
			width: 100%;
			max-width: 360px;

			@media screen and (max-width: 444px) {
				transform: translate3d(-0px, -100%, 0px) !important;
			}

			@media screen and (min-width: 445px) {
				transform: translate3d(calc((100% - 360px) * -1), -100%, 0px) !important;
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
	// face catch phrase p tag
	.landing-page .splash-wrapper .cards .card.dinova-card .content-wrapper .face .mask p {
		max-width: 270px;
	}
}

// catering tab heights
@media screen and (max-width: 432px) {
	.landing-page .splash-wrapper .cards .card.dinova-card.active.catering {
		min-height: calc((562px) + 1vw);
	}
}

@media screen and (min-width: 433px) and (max-width: 580px) {
	.landing-page .splash-wrapper .cards .card.dinova-card.active.catering {
		min-height: calc((521px) + 2vw);
	}
}

@media screen and (max-width: 500px) {
	// business search card face title and blurb
	.landing-page .splash-wrapper .cards .card.dinova-card[class*='0'] .content-wrapper .face .mask {
		h2 {
			max-width: calc(100% - 154px);
		}

		p {
			max-width: calc(100% - 147px);
		}
	}
}

@media screen and (min-width: 581px) and (max-width: 767px) {
	.landing-page .splash-wrapper .cards .card.dinova-card.active.catering {
		min-height: calc(518px + 2vw);
	}
}

@media screen and (max-width: 833px) {
	// landing page title h2
	.landing-page {
		.splash-wrapper {
			.catch-phrase {
				h1 {
					width: 66%;
				}
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 837px) {
	.landing-page .splash-wrapper .cards .card.dinova-card.active.catering {
		min-height: calc(544px + 2vw);
	}
}

@media screen and (min-width: 838px) and (max-width: 1024px) {
	.landing-page .splash-wrapper .cards .card.dinova-card.active.catering {
		min-height: calc(563px + 2vw);
	}
}

// catering zones
@media screen and (min-width: 637px) {
	.landing-page .splash-wrapper .cards .card.dinova-card .content-wrapper .fold .left .catering-zones {
		.nav-item {
			// width: 155px;
		}
	}
}

@media screen and (min-width: 776px) and (max-width: 1024px) {
	.landing-page .splash-wrapper .cards .card.dinova-card .content-wrapper .fold .left .catering-zones {
		.nav-item {
			width: 190px;
		}
	}
}

@media screen and (max-width: 768px) {
	.landing-page .splash-wrapper .cards .card.dinova-card.active.business.slide.left {
		height: calc(730px - (100vw * .2));
	}

	// catering zones
	.landing-page .splash-wrapper .cards .card.dinova-card .content-wrapper .fold .left .catering-zones .nav-item p.text {
		@include fluid-type-default(12px, 14px);
	}
}
