#catering {
	max-width: 1440px;
	margin: auto;
}

.secondary-navigation {
	padding: 0 0 20px;
}

.zbutton.zbutton-link.z-dashboard-button.zbutton-active {
	height: 34px;
	padding: 2px 0px !important;
	border-width: 0 0 3px !important;
	border-style: solid !important;
	border-color: transparent !important;
	margin: 0 10px 0 20px;
	line-height: unset !important;
	text-decoration: none !important;

	&:hover {
		border-color: $dinova-green !important;
	}

	&.z-active {
		border-color: black !important;
		color: black !important;
	}
}

.secondary-navigation > a {
	position: relative;
	padding: 2px 0px;
	border-width: 0 0 3px;
	border-style: solid;
	border-color: transparent;
	margin: 0 10px 0 20px;
	float: left;
	font-size: 18px;
	font-weight: bold;
	text-decoration: none !important;

	&:hover {
		border-color: $dinova-green;
	}

	&.inactive {
		color: $dinova-green !important;

		&:hover {
			border-color: $dinova-green !important;
			color: $dinova-green;
		}
	}

	&.active {
		border-color: black;
		color: black;
	}
}

// .secondary-navigation > a:after {
// 	position: absolute;
// 	right: 0;
// 	bottom: -3px; /* adjust this to move up and down. you may have to adjust the line height of the paragraph if you move it down a lot. */
// 	left: 0;
// 	width: 90%;
// 	height: 1px;
// 	margin: 0 auto;
// 	background-color: red;
// 	color: #000;
// 	content: '';
// }

.secondary-navigation > * > * > .z-dashboard-button {
	padding: 0 !important;
	font-size: 18px !important;
	font-weight: bold !important;
}

#catering.details-pad {
	padding: 0 10px;
}

#catering.hidden {
	#zloading {
		height: 0px;
		opacity: 0;
		pointer-events: none;
		visibility: collapse;
	}
}

@media screen and (min-width: 768px) {
	#catering.details-pad {
		padding: 0 20px;
	}
}

@media screen and (min-width: 1024px) {
	#catering.details-pad {
		padding: 0 25px;
	}
}

.messaging {
	max-width: 560px;
	margin: 0px auto 20px;
}

.messaging .dashboard-connect-message {
	margin-bottom: 20px;
}

.messaging .back,
.messaging .thanks {
	text-align: center;
}

.messaging .thanks {
	padding-top: 10px;
}

.cart-orderInfo-holder p,
.cart-orderInfo-holder div {
	color: #4d4c48 !important;
}

.cart-orderInfo-holder {
	border-top: 2px solid #1c871c;
	border-bottom: 2px solid #1c871c;
	background: #fff !important;
}

.portal-components select {
	display: block !important;
}

.details-page select {
	display: block !important;
}

#z-navbar,
.portal-breadcrumb {
	max-width: 1280px !important;
	margin: 0 auto !important;
}

.portal-map-restaurants-row {
	flex-direction: row-reverse !important;
}

.portal-map-restaurants-row > div {
	margin: 0 !important;
}

.portal-map-restaurants-row > div:first-child {
	flex: 2 !important;
}

.portal-filters {
	padding: 40px 0;
	background: #f2f2f2;
}

.portal-filters .portal-filters-row {
	max-width: 1000px;
	margin: 0 auto;
}

.portal-avg-price,
.portal-cuisines,
.portal-dps {
	margin: 0 5px 0 0;
}

.avg-price-trigger,
.cuisines-trigger,
.dps-trigger {
	border: 2px solid #128815 !important;
}

.portal-tags {
	padding: 0 10px !important;
	border: 0 none !important;
}

.portal-tag {
	background-color: #dcdcdc !important;
}

.portal-tag-group > div:first-child {
	display: none;
}

.protal-address input,
.portal-party-size input,
.portal-address input {
	border: 1px solid #4f4f4f !important;
	background: #fff !important;
}

.rest-face-front {
	padding: 0 !important;
}

.rest-title {
	padding: 10px !important;
	background: #fff;
}

.rest-delivery {
	padding: 10px 0 !important;
	border: 0 none !important;
}

.rest-title-heading {
	color: #128815;
}

.z-modal-close-btn .material-icons {
	font-size: 30px !important;
}

.z-user-modal-content .header-title {
	font-size: 24px !important;
	text-align: left !important;
}

// All Zuppler modals are to cover the entire site
#zuppler-modal-container .t-modal {
	background-color: rgba(255, 255, 255, .8) !important;
	pointer-events: initial !important;
}

.z-back-button {
	top: -339px;
	margin-left: 20px;
	font-weight: 400;
	text-decoration: none;
	transition: none;

	@include fluid-type-default(14px, 20px);

	@media screen and (min-width: 768px) {
		top: -91px;
	}
}

#zuppler-menu #z-navbar {
	max-width: 1440px !important;
}

#zuppler-menu .portal-components {
	max-width: 1440px !important;
}

@media (max-width: 30em) {
	.portal-filters {
		display: flex;
		flex-direction: column;
	}

	.portal-filters .portal-filters-row {
		margin: 0;
	}

	.portal-filters .portal-filters-row:nth-child(2) {
		order: -1;
	}
}
