a.back-link {
	color: $dinova-green !important;
	font-weight: bold;

	&.invisible {
		opacity: 0;
		pointer-events: none;
		visibility: hidden;
	}

	svg {
		margin-right: .25rem;
	}
}
