.amenities {
	.detailed {
		margin-top: 1em;

		p {
			@include fluid-type-default(13px, 16px);

			span {
				font-weight: 600;
			}
		}
	}
}
