// Default Appearance
.tab-panel {
	.tab-panel-header {
		.tabs {
			display: flex;
			margin-top: .5em;
			border-color: $input-border-grey;
			border-style: solid;
			border-width: 0 0 1px;

			.tab {
				padding: .25em 0;
				margin: 0 1em 0 0;
				border-color: transparent;
				border-style: solid;
				border-width: 0 0 2px;
				color: $dinova-green;
				cursor: pointer;
				font-weight: 400;
				text-decoration: none;

				@include fluid-type-default(14px, 18px);

				&.active {
					border-color: $black-text;
					color: $black-text;
				}
			}
		}
	}
}
