.ads {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px 0;

	.ad-item {
		text-align: center;

		img {
			width: 100%;
		}
	}
}

@media screen and (min-width: 768px) {
	.ads {
		padding: 44px 0 18px;
	}
}

@media screen and (min-width: 1024px) {
	.ads {
		padding: 32px 0;
	}
}
