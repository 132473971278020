.input-wrapper {
	position: relative;
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 20px;

	&.disabled {
		opacity: .6;
	}

	&:first-of-type {
		margin-top: 20px;
	}

	&.open {
		.typeahead {
			input {
				border-color: $dinova-green;
				border-top-color: transparent;

				&.has-value {
					padding: 10px 18px;
					border-width: 0 3px 1px;
					border-radius: 5px 5px 0px 0px;
				}
			}
		}
	}

	&.focused {
		.typeahead {
			input {
				border-color: $dinova-green;
				border-top-color: transparent;

				&.has-value {
					padding: 10px 18px;
					border-width: 0 3px 1px;
					border-radius: 5px 5px 0px 0px;

					&.error {
						padding: 10px 10px 10px 70px;

						&.no-label {
							border-width: 3px 3px 1px;
						}
					}
				}
			}
		}

		&.has-recent {
			.typeahead {
				input {
					border-radius: 5px 5px 0px 0px;
				}
			}
		}
	}

	&.error {
		.typeahead {
			input {
				border-color: $danger-red;
			}

			&.no-label {
				input {
					padding-left: 50px !important;
				}
			}
		}
	}

	&.focused:not(.error) {
		.typeahead,
		.typeahead.has-value {
			input {
				border-width: 0 3px 3px;
			}

			&.no-label {
				input {
					border-width: 3px;
				}
			}
		}
	}
}

.input-wrapper.with-help {
	.input-container {
		width: calc(100% - 50px);
	}

	.help-btn,
	.trigger {
		img.Di_SideHelpIcon {
			width: 30px;
			height: 30px;
		}

		p.Di_IconText {
			// help text
			font-weight: 400;
			line-height: unset;

			@include fluid-type-default(10px, 14px);
		}
	}

	.trigger {
		.link {
			color: $dinova-green;
			font-weight: 400;
			text-decoration: underline;

			@include fluid-type-default(12px, 14px);

			&.collapsible {
				margin-top: 1px;
				margin-right: 52px;
			}
		}

		p.link {
			margin-bottom: 0px;
		}
	}

	.help-panel-wrapper,
	.trigger-panel-wrapper {
		width: 100%;
	}
}

.input-wrapper.with-toggle {
	.input-container {
		width: calc(100% - 50px);
	}

	.toggle-btn,
	.trigger {
		img.Di_SideShowIcon {
			width: 30px;
			height: 30px;
		}

		p.Di_IconText {
			// help text
			font-weight: 400;
			line-height: unset;

			@include fluid-type-default(10px, 14px);
		}
	}

	.trigger {
		.link {
			color: $dinova-green;
			font-weight: 400;
			text-decoration: underline;

			@include fluid-type-default(12px, 14px);

			&.collapsible {
				margin-top: 1px;
				margin-right: 52px;
			}
		}

		p.link {
			margin-bottom: 0px;
		}
	}
}

.input-example {
	margin: 3px 0 0 10px;
	color: $black-text;
	font-weight: 400;

	@include fluid-type-default(12px, 14px);
}
