// Main Header Hamburger
.Di_HeaderHambMenu {
	align-self: flex-start;
	margin-top: 5px;

	.hamburger-icon {
		width: 20px;
		object-fit: contain;
	}
}

@media screen and (min-width: 768px) {
	.Di_HeaderHambMenu {
		margin-top: 10px;

		.hamburger-icon {
			width: 30px;
			margin-top: 5px;
		}
	}
}

@media (min-width: 1024px) {
	.Di_HeaderHambMenu {
		display: none;
	}
}
