.modal-page-wrapper {
	min-width: map_get($grid-breakpoints, sm);
	max-width: map_get($grid-breakpoints, xl);
	padding: 25px 25px 50px;
	margin: 0 auto;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	.modal-container {
		margin: 0 auto;
		background-color: $adminDining_white-opacity-95;
	}

	// for table and up
	@include media-breakpoint-up(md) {
		padding-top: 30px;
		padding-bottom: 40px;

		.modal-container {
			width: 560px;
		}
	}

	// for desktop
	@include media-breakpoint-up(lg) {
		.modal-container {
			width: 535px;
		}
	}
}
