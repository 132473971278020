.cta-item {
	padding: 2px 12px;
	border: solid $black 2px;
	margin: 0 1em 0 0;
	background-color: $adminDining_white;
	cursor: pointer;
	font-weight: 400;

	@include fluid-type-default(12px, 16px);

	&:last-child {
		margin: 0px;
	}
}
