@media screen and (min-width: 1024px) {
	.dropdown {
		.label-wrapper {
			padding: 13px 17px 12px;
			margin-right: 20px;

			.label {
				@include fluid-type-default(16px, 18px);
			}

			.value {
				margin: 0;

				@include fluid-type-default(16px, 18px);
			}
		}

		&.has-value {
			.label-wrapper {
				padding: 14px 17px 12px;

				.label {
					@include fluid-type-default(12px, 16px);

					margin-bottom: 3px;
					transform: translateY(-100%);
				}
			}
		}

		&.open {
			.label-wrapper {
				padding: 11px 15px 10px;

				&.error {
					padding: 18px 18px 13px;
				}
			}

			&.has-value {
				.label-wrapper {
					padding: 14px 15px 10px;
				}
			}
		}
	}
}
