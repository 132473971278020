.reward-card {
	display: flex;
	height: 100%;

	.card-cover {
		display: block;
		width: 100%;
		min-width: 100px;
		max-height: 214px;
		flex-basis: 50%;
		flex-grow: 1;
		align-self: flex-start;
		margin-right: 20px;
		object-fit: contain;
		object-position: top;
	}

	.card-container {
		display: flex;
		min-width: 0;
		flex-basis: 50%;
		flex-direction: column;
		flex-grow: 1;

		& > * {
			display: block;
		}

		.card-title,
		.card-point {
			font: 28px/1.2 'Lato', sans-serif;

			// stylelint-disable-next-line length-zero-no-unit
			@include fluid-type(map-get($grid-breakpoints, xs), map-get($grid-breakpoints, md), 16px, 20px);
			// @include text-truncate;
		}

		.card-title {
			flex-grow: 1;
			margin: 0;
			color: $black;
			font-weight: bold;
		}

		.card-point {
			margin-top: 10px;
			color: $black-text;
		}

		.card-btn-wrapper {
			text-decoration: none !important;

			.card-btn {
				padding: 8px 20px;
				margin-top: 10px;
				margin-bottom: 0;
				text-decoration: none;
				white-space: nowrap;

				// stylelint-disable-next-line length-zero-no-unit
				@include fluid-type(map-get($grid-breakpoints, xs), map-get($grid-breakpoints, md), 14px, 20px);
			}
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		flex-direction: column;

		.card-cover {
			max-height: 180px;
			flex-basis: auto;
			flex-grow: 0;
			margin-right: 0;
			margin-bottom: 10px;
			object-fit: cover;
			object-position: center;
		}

		.card-container {
			flex-basis: auto;
			align-self: stretch;

			.card-title,
			.card-point {
				white-space: normal;

				@include fluid-type(map-get($grid-breakpoints, xs), map-get($grid-breakpoints, md), 16px, 18px);
			}

			.card-btn-wrapper .card-btn {
				padding: 15px 20px;
				margin-top: 15px;
			}
		}
	}

	// for desktop
	@include media-breakpoint-up(lg) {
		.card-cover {
			max-height: 200px;
		}

		.card-container {
			.card-title,
			.card-point {
				@include fluid-type(map-get($grid-breakpoints, xs), map-get($grid-breakpoints, md), 20px, 24px);
			}
		}
	}
}
