.help-header {
	.title {
		margin-bottom: 5px;

		@include fluid-type-default(18px, 30px);
	}

	.text,
	.text p {
		max-width: 80%;
		margin-right: auto;
		margin-bottom: 25px;
		margin-left: auto;

		@include fluid-type-default(14px, 18px);
	}
}

@media screen and (min-width: 768px) {
	.help-header {
		.title {
			margin-bottom: 20px;
		}

		.text {
			margin-bottom: 38px;
		}
	}
}
