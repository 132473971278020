@media screen and (min-width: 1024px) {
	.select.dropdown {
		&.error {
			.label-wrapper {
				padding: 12px 18px 9px 70px;
			}

			&.has-value {
				.label-wrapper {
					padding: 14px 18px 10px 70px;
				}

				.label {
					margin-left: -55px;
				}
			}
		}
	}
}
