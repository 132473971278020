@import './vars/colors';
@import './mixins/responsive';

/* stylelint-disable function-name-case, declaration-block-no-redundant-longhand-properties, font-family-no-missing-generic-family-keyword, no-duplicate-selectors, no-descending-specificity */

.Di_ErrorPage {
	font-size: 400%;
}

.Di_LogoutGRX {
	width: 158px;
	height: 180px;
	margin: 90px 0 110px;
}

.Di_ExpiredGRX {
	width: 180px;
	height: 180px;
	margin-bottom: 30px;
}

.Di_ActionBttns button {
	@include fluid-type-default(14px, 16px);
}

@media (min-width: 768px) {
	.Di_CookieShowFoot {
		display: block;
	}

	.Di_CookieShowHead {
		display: none;
	}

	.Di_LogoutGRX {
		width: 158px;
		height: 180px;
		margin: 72px 0 88px;
	}

	.Di_ActionBttns {
		text-align: center;
	}

	.Di_ActionBttns button {
		margin-right: 25px;
		margin-left: 25px;

		@include fluid-type-default(14px, 18px);
	}
}

@media (min-width: 1024px) {
	.Di_CookieShowFoot {
		display: block;
	}

	.Di_CookieShowHead {
		display: none;
	}

	.Di_ProfileHambMenu {
		display: none;
	}

	.Di_LogoutGRX {
		width: 158px;
		height: 180px;
		margin: 10px 0 30px;
	}
}

.Di_SubHead {
	max-width: 1440px;
	margin-right: auto;
	margin-left: auto;
	background-color: $adminDining_white;
	background-position-x: center;
	background-position-y: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.Di_SubHeadShort {
	max-width: 1440px;
	height: 55px;
	margin-right: auto;
	margin-bottom: 40px;
	margin-left: auto;
	background-color: $adminDining_white;
	background-position-x: center;
	background-position-y: center;
	background-repeat: no-repeat;
	background-size: cover;
}



@media (min-width: 768px) {
	.Di_SubHeadShort {
		height: 115px;
	}
}

@media (min-width: 1024px) {
	.Di_SubHeadShort {
		height: 200px;
	}
}

.Di_HeadSpace {
	padding-top: 30px;
	padding-bottom: 30px;
}

.Di_TermsText {
	// max-height: 500px;
	padding-right: 20px;
	margin-top: 20px;
	margin-bottom: 20px;
	overflow-x: hidden;
	overflow-y: scroll;
	// text-align: justify;
	// text-justify: inter-word;
}

.di_TintGrey,
.di_TintGreen {
	width: 100%;
	padding: 20px 20px 10px;
	margin-bottom: 7px;
	border-radius: 5px;
}

.di_TintGrey {
	background-color: $tint-grey;
}

.di_TintGreen {
	background-color: $green-opacity-8;
}

.Di_RememberMeText {
	position: relative;
	margin-top: 33px;
	margin-left: 15px;
}

.Di_LoginDoLink {
	margin-top: 10px;
	margin-bottom: 10px;
	clear: both;
	color: $dinova-green;
	float: left;
	font-size: 18px;
}

.Di_HomeText {
	margin-top: 20px;
}

.Di_HomeText p {
	text-align: center;
}

.Di_TopSpace {
	padding-top: 10px;
}

.WhatAreRewards {
	margin-top: 30px;
	margin-bottom: 5px;
	color: $black !important;
	font-size: 34px;
	font-weight: 600;
	line-height: 34px;
	text-align: center;
	text-transform: none;
}

.WhatAreRewards2 {
	clear: both;
	color: $dinova-green !important;
	font-size: 3em;
	font-weight: bold;
	line-height: 56px;
	text-align: center;

	@include fluid-type-default(32px, 56px);
}

.Di_mt5px {
	margin-top: 5px;
}

.Di_IconText {
	position: absolute;
	top: 35px;
	width: 100%;
	padding: 0;
	margin: 0;
	color: $black;
	font-size: 10px;
	text-align: center;
	text-decoration: none;
}

@media (min-width: 1024px) {
	.eyeball {
		top: 25px;
	}
}

.Di_SideHelpIcon {
	width: 30px;
	height: 30px;
}

.Di_StepGrx {
	margin-bottom: 40px;
}

.Di_TransactionsBlock {
	margin-top: 50px;
}

.Di_HistoryTable {
	width: 100%;
}

.Di_HistoryTable thead tr th {
	font-size: 16px;
}

.Di_HistoryTable tbody tr td {
	font-size: 16px;
	vertical-align: middle;
}

span.Di_WeekDay {
	display: block;
	width: 73px;
	margin-top: 4px;
	font-size: 11px;
	text-align: center;
}

span.Di_MonthDay {
	display: block;
	width: 73px;
	font-size: 22px;
	text-align: center;
}

table td {
	font-size: 100%;
}

#Di_PassCheck .invalid svg,
#UserCheck .invalid svg {
	display: none;
}

#Di_PassCheck .valid,
#UserCheck .valid {
	color: $dinova-green;
}

#Di_PageError.row.Show {
	display: block;
}

#Di_PageError.row.Hide {
	display: none;
}

.Di_PageErrorBox {
	display: flex;
	align-items: center;
	padding: 10px 15px !important;
	border: solid 3px $danger-red;
	margin-right: 18px;
	margin-bottom: 15px;
	margin-left: 15px;
	color: $danger-red;
}

.Di_ErrorIcon {
	display: flex;
	height: 40px;
	align-items: center;
	justify-content: center;
	margin-right: 18px;
	float: left;
}

.Di_ErrorText p {
	margin-bottom: 0;
	color: $danger-red !important;
	font-weight: 500;
}

.Di_TermsErrorIcon {
	margin-top: 0;
	color: $danger-red;
	font-size: 30px;
}

.Di_FootMargin {
	margin-top: 25px;
	margin-bottom: 40px;
}

/* Some Old Classes */
.requirement-list {
	list-style-type: none;
}

.requirement-list .valid {
	position: relative;
	color: $dinova-green;
	list-style-type: none;
}

.requirement-list .invalid svg {
	display: none;
}

.requirement-list .valid svg {
	position: absolute;
	top: 5px;
	left: -20px;
}

.text-box-trigger {
	text-align: right;
}

.text-box-trigger button {
	width: 65px;
	padding: 0;
	margin: 0;
	font-size: 1.125em;
	line-height: 25px;
	text-align: right;
}

@media (min-width: 1024px) {
	.text-box-trigger {
		padding-right: 50px;
	}
}

/* Overwrite CSS For MD_Bootstrap */
.z-depth-0,
.z-depth-0:hover {
	/* -webkit-box-shadow: none !important; */
	box-shadow: none !important;
}

.navbar {
	padding: 10px 20px;
	background-color: $adminDining_white;
}

.form-control {
	height: 60px;
	padding-left: 20px;
	color: $deeper-grey;
	font-size: 20px;
}

.card-body {
	/* -ms-flex: 1 1 auto; */
	flex: 1 1 auto;
	padding: 2.25rem 1.5rem;
	background: $adminDining_white-opacity-9;
}

.ShowAction {
	position: absolute;
	top: 30px;
	right: 30px;
	color: $deeper-grey;
}

.ShowIcon {
	position: absolute;
	top: 0;
	right: 0;
	width: 30px;
	font-size: 30px;
}

.ShowIconText {
	position: absolute;
	top: 35px;
	right: 0;
	width: 30px;
	font-size: 12px;
	text-align: center;
}

.Di_arrow {
	width: 20px;
	height: 20px;
	margin-top: 12px;
	margin-left: 5px;
	float: right;
	-webkit-transform: scaleY(-1);
	transform: scaleY(-1);
}

.Di_TermsOpen img {
	-webkit-transform: scaleY(1);
	transform: scaleY(1);
}

.Di_arrow_text {
	margin-top: 10px;
	color: $dinova-green;
	float: right;
	font-size: 14px;
	text-decoration: underline;
}

.Ri_HeadlineAndBack {
	position: relative;
	width: 100%;
	margin-bottom: 20px;
}

.Ri_BackBttn {
	position: absolute;
	left: 0;
	color: $dinova-green;
	font-size: 16px;
	text-decoration-line: none;
}

.Ri_BackBttn:hover {
	text-decoration-line: none;
}

.text-danger {
	color: $danger-red !important;
}

.modal-header {
	padding-top: 2.5rem;
	padding-right: 1.5625rem;
	padding-bottom: 1.25rem;
	padding-left: 1.5625rem;
	border-bottom: none;
}

.modal-body {
	padding: 0 1.5625rem;
	color: $black-text;
}

.modal-footer {
	border-top: none;
}

.buttons {
	margin: 40px 0;
}

.steps p {
	padding: 25px 0 30px;
}

.steps h4 {
	font-weight: bold;
}

.connect-to-points-form {
	width: 320px;
}

.doordash-login-image {
	height: 140px;
	background-size: cover;
}

@media (min-width: 400px) {
	.doordash-login-image {
		height: 180px;
	}
}

@media (min-width: 500px) {
	.doordash-login-image {
		height: 250px;
	}
}

@media (min-width: 768px) {
	.connect-to-points-form {
		width: 450px;
	}

	.doordash-login-image {
		height: 300px;
	}
}

@media (min-width: 1024px) {

	

	.connect-to-points-form {
		width: 535px;
	}

	.registration-form {
		.column1 {
			padding-right: 25px;
		}

		.column2 {
			padding-left: 25px;
		}
	}
}

.connect-to-points-form .form-check,
.registration-form .form-check {
	padding-left: 0;
}

.registration-form .btn {
	margin-bottom: 0;
}

.connect-to-points-form p {
	color: $black;
}

.modal-footer .btn {
	margin-top: .25em;
	margin-bottom: .25em;
}

.modal-footer {
	margin-top: 1em;
}

.form-check-toggle-error {
	display: flex;
	align-items: center;

	svg {
		margin-right: 5px;
	}
}

.fourohfour {
	min-height: 250px !important;
}

.connect .complete {
	width: 100%;
	padding-right: 0;
	padding-left: 0;
}

.registration-form .my-3 {
	float: right;
}

@media (min-width: 768px) {
	.registration-form .my-3 {
		float: left;
	}
}

.registration-form .mr-5 {
	margin-right: auto !important;
	// font-size: 1.5rem;
}

.color-red {
	color: red;
}

.Di_ActionBttns > div:nth-child(1) {
	padding-right: 0;
	padding-left: 0;
}

@media screen and (max-width: 768px) {
	form[name='connect-to-points'] {
		.Di_ActionBttns > div:nth-child(1) {
			.btn-primary {
				width: 49%;

				&:nth-of-type(1) {
					margin-right: 1%;
				}

				&:nth-of-type(2) {
					margin-left: 1%;
				}
			}
		}
	}
}

.Di_ActionBttns > div:nth-child(2) {
	padding-left: 0;
}

@media (max-width: 768px) {
	.Di_ActionBttns > .Di_ActionBttns:first-child {
		padding-right: 15px;
	}

	.Di_ActionBttns > .Di_ActionBttns:last-child {
		padding-left: 15px;
	}
}

.row {
	margin-right: 0;
	margin-left: 0;
}

.col,
.col-6,
.col-md-12 {
	// padding-right: 0;
	// padding-left: 0;
}

.col.right {
	text-align: right;
}

@media (min-width: 1024px) {
	.col {
		padding-right: 15px;
		padding-left: 15px;
	}
}

.Di_clearfix {
	clear: both;
}

.invisible {
	opacity: 0;
	pointer-events: none;
}
