/* stylelint-disable font-family-no-missing-generic-family-keyword */
.search-filters-bar {
	// styles here

	// for table and up
	@include media-breakpoint-up(md) {
		display: flex;
		min-height: 50px;
		align-items: center;
		padding: 0 20px;

		.title {
			margin: 0 10px 0 0;
			font-family: 'Lato';
			font-size: 18px;
			font-weight: bold;
		}

		.selected-filters-list {
			display: flex;
			flex-wrap: wrap;

			.filter-item {
				display: flex;
				align-items: center;
				color: $required-red;
				font-family: 'Lato';
				font-size: 18px;
				font-weight: bold;
				white-space: nowrap;

				.remove-filter-btn {
					display: flex;
					width: 20px;
					height: 20px;
					align-items: flex-start;
					justify-content: center;
					border: solid 2px $required-red;
					margin-left: 5px;
					background-color: $adminDining_white;
					border-radius: 50%;
					cursor: pointer;
					font-family: 'Lato';
					font-style: normal;
					line-height: 13px;
				}

				&:not(:last-child) {
					margin-right: 10px;
				}
			}
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		padding: 0 25px;
	}
}
