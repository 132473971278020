$cards-closed-wrapper-width: 100%;

.ie {
	.landing-page {
		.splash-wrapper {
			width: 100%;

			.cards {
				width: $cards-closed-wrapper-width;
			}

			.typeahead + .input-footer {
				left: 0px;
			}
		}
	}
}
