@media screen and (min-width: 768px) {
	.input-wrapper {
		&.with-help {
			.input-container {
				width: calc(100% - 50px);
			}

			&.error {
				.help-panel-wrapper,
				.trigger-panel-wrapper {
					margin-top: -17px;
				}
			}

			.help-btn,
			.trigger {
				img.Di_SideHelpIcon {
					width: 30px;
					height: 30px;
				}

				p.Di_IconText {
					margin-top: .5em;
				}
			}

			.trigger {
				.link {
					color: $dinova-green;

					@include fluid-type-default(14px, 16px);

					&.collapsible {
						margin-right: 61px;
					}
				}
			}
		}
	}

	.input-wrapper {
		&.with-toggle {
			.input-container {
				width: calc(100% - 50px);
			}

			&.error {
				.help-panel-wrapper,
				.trigger-panel-wrapper {
					margin-top: -17px;
				}
			}

			.toggle-btn,
			.trigger {
				img.Di_SideShowIcon {
					width: 30px;
					height: 30px;
				}

				p.Di_IconText {
					margin-top: .5em;
				}
			}

			.trigger {
				.link {
					color: $dinova-green;

					@include fluid-type-default(14px, 16px);

					&.collapsible {
						margin-right: 61px;
					}
				}
			}
		}
	}
}
