@import 'button/main';
@import 'checkbox/main';
@import 'radio_button/main';
@import 'form/main';
@import 'container/main';
@import 'grid/main';
@import 'scroll_bar/main';
@import 'fa/main';

.mt-6 {
	margin-top: 2rem !important;
}

.mt-8 {
	margin-top: 2.5rem !important;
}

.mt-10 {
	margin-top: 3.5rem !important;
}

.mb-6 {
	margin-bottom: 2rem !important;
}

.mb-8 {
	margin-bottom: 2.5rem !important;
}

.mb-10 {
	margin-bottom: 3.5rem !important;
}

.modal-header button.close {
	font-size: 3rem;
	opacity: 1 !important;

	span {
		display: flex;
		margin-top: -7px;
	}
}
