// .details-about .right-rail {

// }

@media screen and (min-width: 1024px) {
	.details-about .right-rail {
		margin-top: -195px;


		> div {
			border: solid $grey 1px;
			margin: 2rem 0;
		}

		.card {
			padding: 1em;

			&.map {
				display: block;
			}
		}

		.accordion {
			margin-top: 0;
			margin-bottom: 0;

			.accordion__item {
				padding-bottom: 0;
				border-bottom: none;
			}
		}

		.social-icon {
			margin: 0 5px 0 0;
		}
	}
}
