.accordion__arrow {
	position: absolute;
	top: 50%;
	right: 5px;
	width: 28px;
	height: 12px;

	&:before,
	&:after {
		position: absolute;
		display: block;
		width: 12px;
		height: 1px;
		background-color: $black-text;
		content: '';
		transition: transform .25s ease;
	}

	&:before {
		left: 8px;
		transform: rotate(-45deg);
	}

	&:after {
		transform: rotate(45deg);
	}

	&.open {
		&:before {
			transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}
	}
}
