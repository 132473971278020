/* Small Screen Normalize */
/* stylelint-disable function-name-case, declaration-block-no-redundant-longhand-properties, font-family-no-missing-generic-family-keyword, no-duplicate-selectors, no-descending-specificity */

@import url('https://fonts.googleapis.com/css?family=Lato:300,400,500,700');
@import './sm_normalize';
@import './md_normalize';
@import './lg_normalize';

html {
	background-color: $adminDining_white;
	color: $dinova-green;
	font-family: 'Lato' !important;
	font-size: 62.5%;
}

body {
	min-height: 200px;
	color: $black;
	font-family: 'Lato' !important;
	font-size: 1.6rem;
}

a {
	color: $dinova-green;
	text-decoration: underline;
}

a:hover {
	color: $dinova-green;
	text-decoration: underline;
}

p {
	color: $black-text;
	font-family: 'Lato' !important;
}

h1,
h2,
h3,
h4,
h5 {
	font-family: 'Lato' !important;
	text-transform: uppercase;
}

h1,
h2 {
	font-weight: 700;
}

h3,
h4,
h5 {
	font-weight: 500;
}
