.edit-preferences-page .modal-container {
	padding: 50px 25px;

	.page-header {
		padding: 0;
		margin-bottom: 50px;

		.header-bar {
			padding: 10px 0;

			.title {
				margin: 0;
			}
		}
	}

	.desc {
		margin-bottom: 50px;
	}

	.preferences-list .check-btn {
		padding-left: 0;
		margin: 0 !important;

		label {
			font-weight: bold;
		}

		&:not(:first-child) {
			margin-top: 30px !important;
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		padding-top: 40px;
		padding-bottom: 40px;

		.page-header,
		.desc {
			margin-bottom: 40px;
		}

		.preferences-list .check-btn:not(:first-child) {
			margin-top: 28px !important;
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		.preferences-list .check-btn:not(:first-child) {
			margin-top: 25px !important;
		}
	}
}
