.ie {
	@media screen and (min-width: 768px) {
		.search-page {
			.main-content-wrapper .main-content {
				overflow-x: hidden;
				overflow-y: hidden;

				.collapsible-panel {
					&:first-child {
						> div {
							> div {
								width: 100%;
								overflow-x: hidden;
							}
						}
					}

					&:nth-child(2) {
						> div {
							> div {
								width: 100%;
								overflow-x: hidden;

								.list.business-search-results {
									overflow-y: hidden;
								}
							}
						}
					}

					&:last-child {
						overflow-x: hidden;
						overflow-y: hidden;

						> div {
							overflow-x: hidden;
							overflow-y: hidden;

							> div {
								overflow-x: hidden;
								overflow-y: hidden;
							}
						}
					}
				}
			}
		}
	}
}
