.special-offers {
	overflow: hidden;

	.text {
		@include fluid-type-default(13px, 16px);

		font-weight: 300;
		line-height: 1.5;
	}
}
