.with-help {
	position: relative;

	a {
		color: $black;
	}

	&.disabled {
		opacity: .6;
	}

	.children {
		width: calc(100% - 50px); // 50px = help button with.
		min-height: 10px; // Help button height.
	}

	.title {
		margin-top: 1px;
		margin-right: 51px;
		margin-bottom: 0px;
		cursor: pointer;
	}

	& > .title {
		color: $dinova-green;
	}

	.help-btn,
	.trigger {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		cursor: pointer;
	}
}

.with-help.floating {
	position: relative;

	.help-btn {
		position: static;
	}

	.help-panel-wrapper {
		position: absolute;
		z-index: 999;
		top: 35px;
		right: 0px;
		width: 400px;
		max-width: 70vw;
	}
}
