.ie {
	.dropdown {
		.label-wrapper {
			.value {
				top: 10px;
				left: 5px;
			}
		}
	}

	@media screen and (min-width: 768px) {
		.dropdown {
			.label-wrapper {
				.value {
					top: 15px;
					left: 14px;
				}
			}
		}
	}

	@media screen and (min-width: 1024px) {
		.dropdown {
			.label-wrapper {
				.value {
					top: 15px;
					left: 14px;
				}
			}
		}
	}
}
