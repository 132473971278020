/* Admin form */
.auto-enroll-form {
	.input-wrapper {
		.file-input-wrapper {
			width: 100%;
			margin-top: 15px;

			.file-input-container {
				width: 100%;

				> div {
					// flex-direction: row-reverse;
					// justify-content: flex-start;
				}
			}

			p.text {
				overflow: hidden;
				max-width: calc(100% - 120px);
				text-overflow: ellipsis;
			}

			button.label {
				width: 120px;
				margin: 0 1rem 0 0 !important;
				background-color: $adminDining_white !important;

				label {
					padding: 1rem 0;
					color: #1c871c;
					font-size: 14px;
					font-weight: 600;
					white-space: nowrap;
				}
			}
		}
	}

	.with-help {
		margin-top: 1rem;
	}
}

@media screen and (min-width: 768px) {
	.auto-enroll-form {
		.with-help {
			width: 70%;
		}
	}
}
