.page-header-links {
	// styles
	@media screen and (max-width: 1024px) {
		padding-right: 2.5vw;
	}

	@media screen and (min-width: 1024px) {
		padding-right: calc(2.5vw - 20px);
	}

	a {
		@include fluid-type(map-get($grid-breakpoints, xs), map-get($grid-breakpoints, md), 14px, 16px);
	}
}
