.basic-modal.recommendations_ty_modal {
	.basic-modal-content-wrapper {
		max-width: 550px !important;

		.title {
			max-width: 90%;
			margin: 20px auto;
		}
	}

	.text.md.normal.text-none {
		line-height: 2.5rem;
	}

	.link {
		border: none;
		margin: 20px auto 0;

		p.text {
			display: flex;
			justify-content: center;
			border: none;
		}
	}

	.submit.btn {
		width: 100%;
		margin-top: 25px;
		margin-bottom: 0px;
	}
}
