.restaurant-list-marker {
	width: 24.5px;
	height: 35px;
	cursor: pointer;
	transform: scale(1, 1);
	transition: all .15s ease;

	&.active {
		transform: scale(1.2, 1.2);
		transition: all .25s ease;
	}
}
