// Footers
footer {
  &.page-footer {
    bottom: 0;
    color: $adminDining_white-base;
    .container-fluid {
      width: auto;
    }
    .footer-copyright {
      overflow: hidden;
      background-color: $footer-copyright-bg-color;
      color: $footer-copyright-color;
    }
    a {
      color: $adminDining_white-base;
    }
  }
}