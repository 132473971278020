.center-map-btn {
	width: 35px;
	height: 35px;
	border: 1px solid #6c6f70;
	margin-top: 3px;
	background: url(#{$imageBaseUrl}/icons/icon-current-location-white.svg) center center no-repeat #209a20;
	background-size: 18px 18px;
	border-radius: 8px;
	cursor: pointer;
}
