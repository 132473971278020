@import './vars';
@import './default-button';
@import './primary-button';
@import './tertiary-button';
@import './link-button';
@import './warning-button';

.btn,
.btn[class*='btn-outline-'] {
	padding: 15px 28px;
	border: 0;
	margin-top: 15px;
	margin-bottom: 15px;
	border-radius: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	cursor: pointer;
	font-size: 20px;
	text-transform: uppercase;
	transition: all .3s ease-in-out;
	white-space: normal;
	word-wrap: break-word;
}

/* Makes Buttons Full Width On Small View */
@media (max-width: 767px) {
	.btn {
		width: 100%;
		margin-right: auto;
		margin-left: auto;
	}
}

// No Story Styles
.show > .btn-secondary.dropdown-toggle {
	// TODO: STORY - Button With Dropdown
	background-color: $button-active-color !important;
	-webkit-box-shadow: $button-hover-shadow;
	box-shadow: $button-hover-shadow;
}

.show > .btn-primary.dropdown-toggle {
	// TODO: STORY - Button With Dropdown
	background-color: $dinova-green !important;
}

.show > .btn-outline-primary.dropdown-toggle {
	border-color: $dinova-green !important;
	//TODO: STORY - Button With Dropdown
	background-color: transparent !important;
}

// NOTE: Idk what this points to.
.btn.btn-block {
	margin-top: 15px;
	margin-bottom: 15px;
}

.fa-2x {
	vertical-align: middle;
	// padding-right: 10px;
}


// NOTE: Is this used?
.btn-default {
	border: 3px solid $dinova-green !important;
	background-color: $adminDining_white !important;
	color: $dinova-green !important;
}

.btn {
	&.black {
		border-color: black !important;
		background-color: $adminDining_white !important;
		color: black !important;

		&.btn-secondary:not([disabled]):not(.disabled):active,
		&.btn-secondary:not([disabled]):not(.disabled).active,
		&.btn-secondary:not([disabled]):not(.disabled):focus,
		&.btn-secondary:not([disabled]):not(.disabled).active:focus,
		&.btn.black.btn-secondary:not([disabled]):not(.disabled):active {
			border-color: $dinova-green !important;
			background-color: $adminDining_white !important;
			color: $dinova-green !important;
		}

		&:hover:not(.disabled) {
			border-color: black !important;
			color: black !important;
		}
	}
}

.btn.round {
	border-radius: 10px;
}

.btn.no-transform {
	text-transform: none;
}

.btn.text {
	padding: 0;
	border: none !important;
	margin: 0;
	text-transform: none;

	&:active:not(.disabled) {
		background-color: transparent !important;
		color: $dinova-green !important;
	}
}
