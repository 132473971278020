.st0 {
	fill: $pink-grey;
}

.st1 {
	fill: $sunset-orange;
}

.st2 {
	filter: url(#Adobe_OpacityMaskFilter);
}

.st3 {
	mask: url(#SVGID_1_);
	opacity: .5;
}

.st4 {
	fill: $adminDining_white;
}
