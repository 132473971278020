/* stylelint-disable block-no-empty, no-descending-specificity */
.autoComplete {
	h4 {
		font-size: 1.5em;
		font-weight: 700;
	}

	.input-wrapper.with-help {
		margin-bottom: 0px;
	}

	.col,
	.col-sm-12,
	.container {
		padding-right: 0;
		padding-left: 0;
	}

	.required-field-note {
		margin-bottom: 10px;
		color: $black-opactiy-5;
		font-size: .75em;
		font-weight: 500;
		text-align: right;
	}

	.modal-header {
		position: relative;

		.close-btn {
			position: absolute;
			top: 28px;
			right: 18px;

			@include close-button(30px, 30px);

			// width: 30px;
			// height: 30px;
			padding: 0;
			// border: none;
			// background: url(#{$cdn-basePath}/icons/icon-close-black.svg) center center no-repeat;
			box-shadow: none;
			// cursor: pointer;
		}
	}

	.with-help {
		.help-panel-wrapper,
		.help-panel-wrapper.big-pad {
			top: 100%;
			margin: 0;
		}
	}

	.modal-footer {
		margin-top: 0;
	}

	.modal-header,
	.modal-body,
	.modal-footer {
		padding-right: 25px;
		padding-left: 25px;
	}

	.alert-dismissible .close {
		margin-top: -10px;
		background-color: transparent !important;
		color: $dinova-green !important;
		font-size: 1.5em !important;
	}

	.alert-success {
		background-color: $green-opacity-9;
	}

	.bodyText {
		font-size: 1.2em;
		line-height: 1.2;
	}

	// for table and up
	@include media-breakpoint-up(md) {
		.modal-header .close-btn {
			top: 26px;
			width: 34px;
			height: 34px;
		}
	}
}
