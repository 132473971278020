// Primary Button
.btn-primary {
	border: $button-border-width solid $dinova-green !important;
	background-color: $dinova-green !important;
	box-shadow: none !important;
	text-decoration: none;

	// Not Disabled
	&:not([disabled]):not(.disabled) {
		// Active
		&.active,
		&:active {
			background-color: $dinova-green !important;
		}
	}

	// Disabled
	&[disabled],
	&.disabled {
		border-color: $grey !important;
		background-color: $grey !important;
		color: $black-opactiy-5 !important;
		opacity: 1;
	}
}

// Primary Outlined Button
.btn-outline-primary {
	border: $button-border-width solid $dinova-green !important;
	background-color: transparent !important;
	color: $dinova-green !important;
	// Active, Focused, Hovered
	&.active,
	&:active,
	&:focus,
	&:hover,
	&:active:focus {
		border-color: $dinova-green !important;
		color: $dinova-green !important;
	}

	// Not Disabled
	&:not([disabled]):not(.disabled) {
		// Active
		&.active,
		&:active {
			border-color: $dinova-green !important;
			background-color: transparent !important;
		}
	}
}
