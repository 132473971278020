.filter-group {
	border-bottom: solid $input-border-grey 1px;

	> p {
		@include fluid-type-default(16px, 18px);
	}

	ul {
		padding: 0;

		li {
			position: relative;
			list-style: none;

			.box {
				position: absolute;
				top: 4px;
				width: 20px;
				min-width: 20px;
				height: 20px;
				border: solid $dinova-green 2px;
				border-radius: 4px;
			}

			p.text {
				padding: 0 0 0 25px;
				margin: 0;

				@include fluid-type-default(12px, 14px);
			}

			&.selected {
				.box {
					background-color: $dinova-green;
				}
			}
		}

		.more-button {
			@include fluid-type-default(12px, 14px);

			margin-left: 25px;
			text-decoration: none;
		}
	}
}

@media screen and (max-width: 768px) {
	.filter-group {
		padding: 0 0 0 15px;

		> p {
			@include fluid-type-default(20px, 22px);
		}

		ul {
			li {
				display: flex;
				min-height: 48px;
				align-items: center;

				.box {
					top: 13px;
				}

				p.text {
					@include fluid-type-default(14px, 16px);
				}
			}
		}

		button.more-button {
			height: 48px;

			@include fluid-type-default(14px, 16px);
		}
	}
}
