.reward-view-options-page {
	.reward-order {
		max-width: 600px;
		padding: 0 25px;
		margin: 0 auto;

		.reward-img {
			display: block;
			width: 100%;
			max-width: 700px;
			height: 100%;
			max-height: 464px;
			margin: 0 auto;
			object-fit: cover;
		}

		.reward-title {
			margin: 30px 0 50px;
			color: $black;
			text-align: center;
			text-transform: initial;

			> * {
				display: block;
				font: 18px/22px 'Lato', sans-serif;
				font-weight: bold;
			}

			.title {
				margin: 0;
			}
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		.reward-order {
			.reward-img {
				max-width: 525px;
				max-height: 348px;
			}

			.reward-title {
				margin: 20px 0 25px;
			}
		}
	}

	// for desktop
	@include media-breakpoint-up(lg) {
		.reward-order {
			.reward-img {
				max-width: 430px;
				max-height: 285px;
			}
		}
	}
}
