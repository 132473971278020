.dining-options-contact-info {
	a.btn.btn-primary {
		width: 100%;
		padding: .5rem 0;
		margin-right: 0;
		margin-bottom: 10px;
		margin-left: 0;
		text-align: center;
		text-transform: uppercase;
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.dining-options-contact-info {
		width: 70%;
		margin: 0 auto;
	}
}
