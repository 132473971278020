.promos {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 19px 0;
	background-color: #fff;

	// for table and up
	@include media-breakpoint-up(md) {
		display: flex;
		justify-content: space-between;
		//padding: 0;
		background-color: transparent;
	}

	@include media-breakpoint-up(lg) {
		width: 92%;
		min-width: 750px;
		max-width: 940px;
		margin: 0 auto;
	}
}
