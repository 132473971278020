.payment-types {
	display: flex;
	flex-flow: column wrap;

	.payment-type {
		margin-right: 1em;
	}
}

.credit-card-img {
	width: 45px;
}

.credit-card-name {
	margin-left: .5em;
	font-weight: 400;

	@include fluid-type-default(13px, 16px);
}

@media screen and (min-width: 768px) {
	.payment-types {
		flex-direction: row;

		.payment-type {
			width: 50%;
			margin-right: 0;
		}
	}
}
