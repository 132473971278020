$filter-width: 230px;
$list-width: 30vw;
$map-with-list-width: 70vw;
$map-with-filter-width: calc(100vw - 230px);
$map-with-filter-and-list-width: calc(100vw - 30vw - 230px);
$map-alone-width: 100vw;

@media screen and (min-width: 768px) {
	.search-page {
		.main-content-wrapper .main-content {
			display: flex;
			overflow: scroll;
			height: 80vh;

			.collapsible-panel {
				// filter panel
				&:first-child {
					width: 0vw;
					transition: border-width 300ms linear;

					> div {
						> div {
							width: 100%;
							max-width: 100%;
						}
					}

					&:not(.closed) {
						padding-right: 15px;
						padding-left: 25px;
						border-right: solid 1px $input-border-grey;
					}
				}

				// results list panel
				&:nth-child(2) {
					width: 0vw;
				}

				// map panel
				&:last-child {
					width: $map-alone-width;

					.map {
						width: 100%;
					}
				}
			}

			&.show-list:not(.show-filter) {
				.collapsible-panel {
					// filter panel
					&:first-child {
						width: 0vw;

						> div {
							> div {
								width: calc(#{$filter-width} - 2rem);
							}
						}
					}
					// results list panel
					&:nth-child(2) {
						width: $list-width;
						margin: 0 1rem 0 0;
					}

					// map panel
					&:last-child {
						width: $map-with-list-width;

						.map {
							width: 100%;
						}
					}
				}
			}

			&.show-filter:not(.show-list) {
				.collapsible-panel {
					// filter panel
					&:first-child {
						width: $filter-width;
						min-width: $filter-width;
						border-right: solid 0px $input-border-grey;
					}

					// results list panel
					// &:nth-child(2) {
					// 	width: 0vw;
					// }

					// map panel
					&:last-child {
						width: $map-with-filter-width;

						.map {
							width: 100%;
						}
					}
				}
			}

			&.show-list.show-filter {
				.collapsible-panel {
					// filter panel
					&:first-child {
						width: $filter-width;
						min-width: $filter-width;
					}

					// results list panel
					&:nth-child(2) {
						width: $list-width;
						margin: 0 1rem;
					}

					// map panel
					&:last-child {
						width: $map-with-filter-and-list-width;

						.map {
							width: 100%;
						}
					}
				}
			}
		}
	}
}
