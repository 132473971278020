@media (min-width: 1024px) {
	.Di_HeaderPadding {
		height: 140px;
	}

	.Di_RequiredHeader {
		// margin-right: 34px;

		@include fluid-type-default(12px, 14px);
	}
}
