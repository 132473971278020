.nav-item {
	list-style-type: none;

	.nav-link {
		padding: 0;
		color: $black-text;

		@include fluid-type-default(12px, 14px);

		font-weight: 400;
		line-height: 36px;
		text-decoration: none;
		text-transform: uppercase;

		&.active {
			color: $dinova-green;
			font-weight: bold;
		}
	}

	&.disabled {
		pointer-events: none;

		.nav-link {
			color: lightgrey;
		}
	}
}

@media screen and (min-width: 1024px) {
	.nav-item {
		.nav-link {
			line-height: 1;

			@include fluid-type-default(14px, 16px);
		}
	}
}

@import './menu-nav-item';
