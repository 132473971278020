.edit-payment-page .modal-container {
	padding: 50px 25px;

	.page-header {
		padding: 0;
		margin-bottom: 1em;

		.header-bar {
			padding: 0;

			.title {
				margin: 0;
			}
		}
	}

	.desc {
		margin-bottom: 2em;
		font-size: 16px;
	}

	.profile-section-item {
		display: flex;
		align-items: baseline;

		.item-value {
			font-size: 18px;
		}
	}
}

@media screen and (min-width: 768px) {
	.edit-payment-page .modal-container {
		.profile-section-item {
			h3.title {
				font-size: 16px;
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.edit-payment-page .modal-container {
		.container.page-header {
			.col {
				padding: 0;
			}
		}
	}
}
