.unsubscribe {
	.title {
		padding-top: 20px;
	}

	.body {
		min-width: 280px;
		padding-right: 20px;
		padding-left: 20px;
	}

	.body .row {
		padding-top: 20px;
	}

	// for table and up
	@include media-breakpoint-up(md) {
		.body {
			.desc {
				padding: 0 36px 40px;
			}

			> .section {
				padding: 40px 36px;
			}
		}
	}

	// for desktop
	@include media-breakpoint-up(lg) {
		.body {
			max-width: 920px;
			margin: 0 auto;
		}
	}
}
