/* Small Screen Font Sizes */

p {
	font-size: 1.7rem;
	line-height: 2rem;
}

h1 {
	font-size: 2.1rem;
}

h2 {
	font-size: 2rem;
}

h3 {
	font-size: 1.9rem;
}

h4 {
	font-size: 1.8rem;
}

h5 {
	font-size: 1.7rem;
}
