.edit-personal-page .modal-container {
	padding: 50px 25px;

	.page-header {
		padding: 0;
		margin-bottom: 10px;

		.header-bar {
			padding: 10px 0;

			.title {
				margin: 0;
			}
		}
	}

	.desc {
		margin-bottom: 10px;
	}

	// for table and up
	@include media-breakpoint-up(md) {
		padding-top: 40px;
		padding-bottom: 40px;

		.page-header,
		.desc {
			margin-bottom: 40px;
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		// styles
	}
}
