.dining-options-list {
	padding: 1rem;

	.dining-options.options-list {
		display: flex;
		margin-left: 0 !important;

		.option-item {
			display: flex;
			width: 48%;
			align-items: center;
			padding: 0;

			@include fluid-type-default(18px, 18px);

			&:before {
				content: '';
			}

			svg {
				width: 2rem;
				height: 2rem;
				margin-right: .5rem;
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
	.dining-options-list {
		.dining-options.options-list {
			max-width: 500px;
			justify-content: flex-start;
			margin-top: 1rem;
			margin-right: auto;
			margin-left: auto !important;

			.option-item {
				p.text {
					@include fluid-type-default(16px, 18px);
				}
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.dining-options-list {
		.dining-options.options-list {
			display: block;

			.option-item {
				width: auto;

				p.text {
					@include fluid-type-default(16px, 18px);
				}
			}
		}
	}
}
