.search-top-bar {
	// background-color: $searchTopBarBg;
	// margin-bottom: 10px;

	.inner-wrapper {
		> div {
			> p.text {
				@include fluid-type-default(20px, 22px);
			}
		}

		.search-error-message {
			padding: 15px;
			border: 2px solid #e37959;
			margin: 0 10px 10px;
			background-color: $adminDining_white;
			color: #e37959;
			font: 16px/20px 'Lato', sans-serif;
			font-weight: bold !important;
		}

		.bottom-bar {
			display: flex;
			background-color: $adminDining_white;

			.filter-btn {
				display: flex;
				align-items: flex-start;
				font-size: 18px;
				font-weight: bold;
				text-decoration: none;
			}

			.filters {
				display: flex;
				align-items: center;

				> .label {
					font-size: 18px;
					font-weight: bold;
				}
			}

			.toggle-list-btn {
				display: flex;
				align-items: center;
				margin-left: auto;
				text-decoration: none;

				.icon {
					height: 14px;
					margin-right: 5px;
				}

				.map-icon {
					height: 24px;
				}

				.label {
					font-size: 18px;
					font-weight: bold;
					line-height: 1;
				}
			}
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		padding: 25px 25px 15px;

		.inner-wrapper {
			form {
				width: 100%;
				max-width: 1024px;
				margin: 0 auto 0 0;
			}

			.input-label {
				padding: 0 0 0 9px;
			}

			.search-error-message {
				margin: 10px 0 0;
			}

			.bottom-bar {
				display: flex;
				justify-content: space-between;
				padding: 10px 0 0;
				// background-color: $searchTopBarBg;
			}
		}
	}

	// for table and up
	@include media-breakpoint-up(lg) {
		margin-bottom: 0;

		.inner-wrapper .bottom-bar .toggle-list-btn {
			margin-left: 0;
		}
	}
}
