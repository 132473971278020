.sub-header {
	display: none;

	.sub-title {
		margin: 20px 0;
		font-size: 30px;
		font-weight: 600;
		letter-spacing: .02em;
		text-transform: none;
	}

	.sub-nav {
		margin-top: 32px;
		margin-bottom: 18px;

		a {
			padding: 9px;
			margin: 15px 0 15px 15px;
			color: $dinova-green;
			font-size: 18px;
			font-weight: 600;
			text-decoration: none;

			&.active {
				position: relative;
				color: $black-text;

				&:after {
					position: absolute;
					bottom: 3px;
					left: 10%;
					width: 80%;
					border: solid $black-text 1px;
					content: '';
				}
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.sub-header {
		display: block;
	}
}
