.view-catering-dashboard {
	z-index: 1;
	padding: 1.75em 0;

	.options-list {
		margin: auto auto 15px;

		// mobile only
		@media screen and (max-width: 767px) {
			width: 55%;
		}

		.option-item {
			max-width: 50%;
			white-space: nowrap;
		}
	}

	.btn-link.btn.item {
		padding-top: 1em;
		padding-bottom: 1em;
	}

	// mobile only
	@media screen and (max-width: 767px) {
		margin-top: 2em;
		margin-bottom: -3em;
		border-color: $black;
		border-style: solid;
		border-width: 1px 0;

		.options-list {
			min-width: 330px;
		}
	}

	// tablet and up
	@media screen and (min-width: 768px) {
		display: flex;
		width: 45%;
		flex-direction: column;
		padding: 0 1.5em;
		margin: 2em 0;

		.options-list {
			min-width: 290px;
			margin: 0 0 15px;
			margin-left: 0px;
		}

		.select.flat {
			margin: auto auto 9px;

			.item.btn.btn-link {
				padding-right: 0px;
				padding-bottom: 0px;
				padding-left: 0px;
				border: none;

				@include fluid-type-default(14px, 16px);
			}
		}
	}
}
