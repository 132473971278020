.filter-types.nav-tabs {
	flex-wrap: nowrap;
	justify-content: space-around;
	padding: 0;
	margin: 0;
	// background-color: $searchTopBarBg;
	box-shadow: none;

	.nav-item {
		display: flex;
		flex: 1 0 0;
		align-items: center;
		justify-content: center;
		padding: 10px 15px;
		margin: 20px 6px 20px 0;

		&:first-child {
			margin-left: 15px;
		}

		&:last-child {
			margin-right: 15px;
		}

		.nav-link {
			display: flex;
			align-items: center;
			padding: 0;
			border-radius: 0;
			color: $dinova-green;
			font-size: 14px;
			font-weight: bold;
			line-height: 18px;
			text-align: center;
			text-transform: none;
			transition: none;
			word-spacing: 100vw;

			&:hover {
				color: $adminDining_white;
			}

			.filter-tab-count {
				margin-left: 5px;
			}
		}

		&.active {
			background-color: #fff;

			.nav-link {
				color: $adminDining_white;
			}
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		flex-wrap: wrap;
		justify-content: flex-start;

		.nav-item {
			display: list-item;
			flex: none;
			margin: 0;

			&:first-child {
				margin: 0;
			}

			.nav-link {
				width: 200px;
				padding: 9px 0 6px;
				font-size: 16px;
				word-spacing: normal;
			}
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		.nav-item {
			.nav-link {
				width: 250px;
				padding: 11px 0 15px;
			}
		}
	}
}
