/* ==========================================================================
   $BASE-PICKER
   ========================================================================== */
/**
 * Note: the root picker element should *NOT* be styled more than what's here.
 */
/**
 * The picker input element.
 */
.picker__input {
  cursor: default;
}
/**
 * When the picker is opened, the input element is "activated".
 */
.picker__input.picker__input--active {
  border-color: #0089ec;
}
/**
 * Note: the root picker element should *NOT* be styled more than what's here.
 */
.picker {
  font-size: 16px;
  text-align: center;
  line-height: 1.2;
  color: #000000;
  position: absolute;
  z-index: 10000;
  user-select: none;
  /**
   * The holder is the only "scrollable" top-level container element. The holder should overlay the entire screen.
   */
  .picker__holder {
    width: 100%;
    overflow-y: auto;
    overflow-scrolling: touch;
    position: fixed;
    transition: background 0.15s ease-out, top 0s 0.15s;
    backface-visibility: hidden;
  }
  /*!
   * Default mobile-first, responsive styling for pickadate.js
   * Demo: http://amsul.github.io/pickadate.js
   */
  /**
   * Note: the root picker element should *NOT* be styled more than what's here.
   */
  /**
   * Make the holder and frame fullscreen.
   */
  .picker__holder,
  .picker__frame {
    bottom: 0;
    left: 0;
    right: 0;
    top: 100%;
  }
  /**
   * The frame that bounds the box contents of the picker.
   */
  .picker__frame {
    position: absolute;
    margin: 0 auto;
    min-width: 256px;
    max-width: 325px;
    //  picker width
    width: 300px;
    max-height: 350px;
    filter: alpha(opacity=0);
    opacity: 0;
    transition: all 0.15s ease-out;
    @media (min-height: $pickerframe-wrap-box-breakpoint) {
      overflow: visible;
      top: auto;
      bottom: -100%;
      max-height: 80%;
    }
    @media (min-height: $pickerframe-mb-breakpoint) {
      margin-bottom: 7.5%;
    }
    /**
     * The wrapper sets the stage to vertically align the box contents.
     */
    .picker__wrap {
      display: table;
      width: 100%;
      height: 100%;
      @media (min-height: $pickerframe-wrap-box-breakpoint) {
        display: block;
      }
    }

  }
  /**
   * The box contains all the picker contents.
   */
  .picker__box {
    background: #ffffff;
    display: table-cell;
    vertical-align: middle;
    @media (min-height: $pickerframe-wrap-box-breakpoint) {
      display: block;
      border: 1px solid #777777;
      border-top-color: #898989;
      border-bottom-width: 0;
      border-radius: 5px 5px 0 0;
      box-shadow: 0 12px 36px 16px rgba(0, 0, 0, 0.24);
    }
  }
}


/**
 * When the picker opens...
 */
.picker--opened {
  .picker__holder {
    top: 0;
    background: transparent;
    zoom: 1;
    background: rgba(0, 0, 0, 0.32);
    transition: background 0.15s ease-out;
  }
  .picker__frame {
    top: 0;
    filter: alpha(opacity=100);
    opacity: 1;
    @media (min-height: $pickerframe-pos-breakpoint) {
      top: 10%;
      bottom: auto;
    }
  }
}


/**
 * For `large` screens, transform into an inline picker.
 */

/* ==========================================================================
   CUSTOM MATERIALIZE STYLES
   ========================================================================== */

.datepicker.picker__input.picker__input--active,
.timepicker.picker__input.picker__input--active {
  border-bottom: 1px solid $blue-lighten-5;
}
