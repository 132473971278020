/* read more about it https://css-tricks.com/snippets/css/fluid-typography/ */

@import '../variables';

@function strip-unit($value) {
	// stylelint-disable-next-line at-rule-no-unknown
	@return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size, $apply-line-height: false) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
			font-size: $min-font-size;

			@if $apply-line-height {
				line-height: #{strip-unit($min-font-size) + 10}px;
			}

			@media screen and (min-width: $min-vw) {
				font-size: calc(
					#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
					((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
				);

				@if $apply-line-height {
					// stylelint-disable
					line-height: calc(
						#{$min-font-size} +
						#{strip-unit($max-font-size - $min-font-size) +
						10} *
						((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
					);
					// stylelint-enable
				}
			}

			@media screen and (min-width: $max-vw) {
				font-size: $max-font-size;

				@if $apply-line-height {
					line-height: #{strip-unit($max-font-size) + 10}px;
				}
			}
		}
	}
}

@mixin fluid-type-default($min-font-size, $max-font-size, $apply-line-height: false) {
	@include fluid-type(
		map-get($grid-breakpoints, xs),
		map-get($grid-breakpoints, xl),
		$min-font-size,
		$max-font-size,
		$apply-line-height
	);
}
