.step-title {
	position: relative;
	padding: 0 1.6vw;
	margin-top: .59vw;
	font-size: 3.2vw;
	font-weight: 700;
	line-height: 1.25;
	text-align: center;
	text-transform: uppercase;

	// Medium devices (tablets, 768px and up)
	@media (min-width: 768px) {
		font-size: 2.60416667vw;
	}

	// Large devices (desktops, 1024px and up)
	@media (min-width: 1024px) {
		top: 8px;
		font-size: 20px;
	}
}
