.input-container {
	position: relative;
	width: 100%;

	.input {
		width: 100%;
		height: 40px;
		padding: 9px 12px;
		border-width: 1px;
		border-style: solid;
		border-color: $input-border-grey;
		background-color: transparent;
		border-radius: 5px;
		color: $black-text;
		font-weight: 400;
		outline: none;
		transition: all .3s ease, border-color .3s ease, border-top-color .2s ease .1s;

		@include fluid-type-default(16px, 20px);

		&.text {
			@include fluid-type-default(16px, 20px);

			margin-bottom: 0;
		}

		&:focus,
		&.focused {
			padding: 7px 10px;
			border-width: 3px;
			border-color: $dinova-green;
			border-top-color: transparent;
			transition: all .3s ease, border-color .3s ease, border-top-color 0s ease;
		}

		&.error {
			padding: 9px 10px 7px 43px;
			border-width: 3px;
			border-color: $danger-red;

			&:focus,
			&.focused {
				border-color: $danger-red;
				border-top-color: transparent;
			}
		}

		&.has-value {
			border-top-color: transparent !important;
		}
	}
}

.has-recent {
	.input-container {
		&.focused,
		&:focus {
			.input {
				border-radius: 5px 5px 0 0;
			}
		}
	}
}
