.step-indicators {
	position: relative;
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	margin-bottom: 6.66666667vw;

	&:before {
		position: absolute;
		left: 17%;
		display: block;
		width: 67%;
		height: .666666667vw;
		background-color: #e6e6e6;
		content: '';
	}

	> *:last-child:after {
		width: 0;
	}

	// for table and up
	@include media-breakpoint-up(md) {
		margin-bottom: 5.20833333vw;
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		margin-bottom: 2.91666667vw;
		margin-bottom: 42px;

		&:before {
			height: .277777778vw;
		}
	}
}
