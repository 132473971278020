@media screen and (min-width: 768px) {
	.registration-form-wrapper {
		padding: 0 13%;

		.with-help {
			.help-panel-wrapper,
			.help-panel-wrapper.big-pad {
				top: 100%;
				margin: 0;
			}
		}
	}
}
