.tab-panels {
	.MuiTabs-indicator {
		// Don't show the bottom border that animates and slides under the active tab.
		display: none;
		width: 0;
		height: 0;
		visibility: hidden;
	}

	header.MuiAppBar-root {
		border-bottom: solid 1px $dark-grey;
		background-color: $adminDining_white;
	}

	.MuiTabs-root {
		min-height: 43px;
	}

	.MuiTabs-fixed,
	.MuiTabs-scroller {
		height: 43px;
		min-height: 43px;

		.MuiTabs-flexContainer,
		.MuiTabs-centered {
			max-width: 922px;
			margin: 0 auto;
		}
	}

	.MuiTab-root {
		min-height: 40px;
		flex: 1 1;
		padding: 0 10px;
		margin: 3px 3px 0;
		box-shadow: 0 0 10px 0px rgba(0, 0, 0, .1);
		color: $dinova-green;
		text-transform: none;

		@include fluid-type-default(12px, 16px);

		&.Mui-selected,
		&.Mui-selected:hover {
			background-color: $dinova-green !important;
			color: $adminDining_white;
		}
	}
}
