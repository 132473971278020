$image_root_dir: '#{$cdn-basePath}/graphics';

/* Key = image className, Value = image file name */
$images: (
	Di_LoginPic: 'SignIn.jpg',
	Di_HomePic: 'home.jpg',
	Di_RegisterPic: 'registration-hero.jpg',
	Di_BrokenPic: 'broken-plate.jpg',
	Di_HelpBanner: 'HelpBanner.jpg',
	dodHeaderRegistration_sv: 'dod_header_tacos_sv.jpg',
	dodHeaderRegistration_mv: 'dod_header_tacos_mv.jpg',
	dodHeaderRegistration_lv: 'dod_header_tacos_lv.jpg',
	dodHeaderConnection_sv: 'dod_header_sub_sv.jpg',
	dodHeaderConnection_mv: 'dod_header_sub_mv.jpg',
	dodHeaderConnection_lv: 'dod_header_sub_lv.jpg',
	dodHeaderReferral_sv: 'dod_header_wings_sv.jpg',
	dodHeaderReferral_mv: 'dod_header_wings_mv.jpg',
	dodHeaderReferral_lv: 'dod_header_wings_lv.jpg',
	dodHeaderCompletion_sv: 'dod_header_burger_sv.jpg',
	dodHeaderCompletion_mv: 'dod_header_burger_mv.jpg',
	dodHeaderCompletion_lv: 'dod_header_burger_lv.jpg',
	
	doorDashHeaderRegistration_sv: 'dod_header_tacos_sv.jpg',
	doorDashHeaderRegistration_mv: 'dod_header_tacos_mv.jpg',
	doorDashHeaderRegistration_lv: 'dod_header_tacos_lv.jpg',
	doorDashHeaderConnection_sv: 'dod_header_sub_sv.jpg',
	doorDashHeaderConnection_mv: 'dod_header_sub_mv.jpg',
	doorDashHeaderConnection_lv: 'dod_header_sub_lv.jpg',
	doorDashHeaderReferral_sv: 'dod_header_wings_sv.jpg',
	doorDashHeaderReferral_mv: 'dod_header_wings_mv.jpg',
	doorDashHeaderReferral_lv: 'dod_header_wings_lv.jpg',
	doorDashHeaderCompletion_sv: 'dod_header_burger_sv.jpg',
	doorDashHeaderCompletion_mv: 'dod_header_burger_mv.jpg',
	doorDashHeaderCompletion_lv: 'dod_header_burger_lv.jpg',
	doorDashLogin: 'doordash_login.png',
	doordashComplete_lv: 'doordash_complete_banner_horizontal_no_copy.jpg',
	doordashComplete_mv: 'doordash_complete_banner.png',
	doordashComplete_sv: 'doordash_complete_banner_vertical_no_copy.jpg'
);

// Loop over each image className/src key value pair
@each $imageClass, $imageSrc in $images {
	// create a class selector for the current image className
	.#{$imageClass} {
		// apply the current image src as the background image
		background-image: url('#{$image_root_dir}/#{$imageSrc}?w=768');

		&.dod {
			background-image: url('#{$image_root_dir}/#{$imageSrc}?w=1600');
		}

		&.doordash {
			background-image: url('#{$image_root_dir}/#{$imageSrc}?w=1600');
			background-size: cover;
			height: 765px;
			display: flex;
  			justify-content: center;
  			align-items: center;
		}
	}

	/*
    Example output:
    .Di_LoginPic {
      background-image: url("https://dinova-images-dev.imgix.net/graphics/SignIn.jpg?w=768");
    }
  */
}

// Repeat above loop for medium screens
@media screen and (min-width: 768px) {
	@each $imageClass, $imageSrc in $images {
		.#{$imageClass} {
			background-image: url('#{$image_root_dir}/#{$imageSrc}?w=1024');

			&.dod {
				background-image: url('#{$image_root_dir}/#{$imageSrc}?w=2048');
			}

			&.doordash {
				background-image: url('#{$image_root_dir}/#{$imageSrc}?w=800');
			}
		}
	}
}


// Repeat for large screen
@media screen and (min-width: 1024px) {
	@each $imageClass, $imageSrc in $images {
		.#{$imageClass} {
			background-image: url('#{$image_root_dir}/#{$imageSrc}?w=1400');

			&.dod {
				background-image: url('#{$image_root_dir}/#{$imageSrc}?w=2800');
			}

			&.doordash {
				background-image: url('#{$image_root_dir}/#{$imageSrc}?w=800');
			}
		}
	}
}
