.faqs {
	.title {
		margin: 15px 10px;

		@include fluid-type-default(14px, 20px);
	}
}

@media screen and (min-width: 768px) {
	.faqs {
		.title {
			margin: 15px;
		}

		.accordion {
			margin-right: 15px;
			margin-left: 15px;
		}
	}
}
