.registration-page {
	.content-inner {
		max-width: 1070px;
		padding: 0 25px;
		margin: 0 auto;
	}

	&.doordash {
		background-position: center;
		background-size: contain;
	}

	// for desktop and up

	.Di_RegisterPic {
		&.dod {
			background-position: center;
			background-size: contain;
		}

		&.doordash {
			background-position: center;
			background-size: contain;
		}
	}

	@include media-breakpoint-up(lg) {
		.Di_RegisterPic {
			//background-position-y: -168px;
		}
	}
}
