/* Small Screen Page Limit */
.Di_PageLimit {
	width: 100%;
	min-width: 320px;
	padding-right: 25px;
	padding-left: 25px;
}

.Di_PageLimit-LG {
	min-width: 320px;
	padding-right: 20px;
	padding-left: 20px;
}

.Di_PageLimit-XLG {
	min-width: 320px;
	padding-right: 20px;
	padding-left: 20px;
}

.Di_PageLimit-XXLG {
	width: 100%;
	min-width: 320px;
}
