.dashboard-promo img {
	width: 100%;
}

.dashboard-promo .no-gutters {
	margin: 15px -20px -30px;
}

.Di_ShowLargePromo {
	display: none;
}

.Di_ShowSmallPromo {
	display: block;
}

@media (min-width: 768px) {
	.dashboard-promo .no-gutters {
		margin: 0;
	}

	.dashboard-promo .no-gutters > .col,
	.dashboard-promo .no-gutters > [class*='col-'] {
		padding: 1px;
	}

	.Di_ShowLargePromo {
		display: block;
	}

	.Di_ShowSmallPromo {
		display: none;
	}
}
