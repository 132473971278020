@import '~bootstrap/scss/mixins';
@import './variables';
@import './mixins/responsive';
@import './common/base.scss'; // @import './normalize/index.scss';
@import './organisms/vars/index.scss';
@import './atoms/index.scss';
@import './ecosystems/index.scss';
@import './organisms/index.scss';
@import './molecules/index.scss';

html {
	max-width: 100vw;
	overflow-x: hidden;
	scroll-behavior: smooth;
}

body {
	box-sizing: border-box;
	margin: 0;
}

body.no-lower-nav .Di_HeaderIconMenu div:nth-child(2) {
	display: none;
	visibility: hidden;
	height: 0;
}

body.no-scroll #root {
	position:fixed;
	overflow:hidden;
	min-width: 100vw;
	max-width: 100vw;
}

body.no-scroll .app-wrapper {
	max-width: 100vw;
}

body.stop-transitions * {
	transition: none !important;
}

.smartbanner-show.smartbanner-margin-top.with-modal {
	margin-top: 0px !important;
}

.orange-text {
	color: $loading-orange !important;
}

.pointer {
	cursor: pointer;
}

.text.error {
	color: $danger-red;
}
