.profile-section-item {
	display: flex;
	align-items: center;

	> * {
		margin: 0;
	}

	&.disabled {
		pointer-events: all !important;
	}

	&.disabled > *:not(.with-help) {
		opacity: .5;
		pointer-events: none !important;
	}

	.required {
		color: $fire-orange;
	}

	.with-help {
		position: relative;
		display: flex;
		align-content: center;
		justify-content: center;
		margin: 0 8px 0 0;

		.help-btn {
			position: static;
			width: 20px;
			height: 20px;
			padding: 0px;

			.Di_SideHelpIcon {
				width: 20px;
				height: 20px;
			}
		}

		.help-panel-wrapper {
			position: absolute;
			z-index: 9999;
			top: 1.25em;
			left: -9px;

			.inner-wrapper {
				width: 400px;
				max-width: 70vw;

				&:before {
					right: unset;
					left: 4px;
				}

				img {
					right: unset;
					left: 12px;
				}

				.content {
					padding-top: 10px;
				}
			}
		}
	}

	.item-value {
		margin-left: 5px;
		color: $black-text;
		font: 24px/1.2 'Lato', sans-serif;

		@include fluid-type(map-get($grid-breakpoints, xs), map-get($grid-breakpoints, md), 12px, 16px);
		@include text-truncate;
	}

	&.sm {
		.title,
		.item-value {
			font-size: 14px;
		}
	}

	// for table
	@include media-breakpoint-up(md) {
		.item-value {
			@include fluid-type(map-get($grid-breakpoints, md), map-get($grid-breakpoints, lg), 20px, 24px);
		}
	}

	&.sm {
		@include media-breakpoint-up(md) {
			.title,
			.item-value {
				font-size: 12px !important;
			}
		}
	}

	// for desktop
	@include media-breakpoint-up(lg) {
		.item-value {
			@include fluid-type(map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xl), 14px, 18px);
		}
	}

	&.sm {
		@include media-breakpoint-up(lg) {
			.title,
			.item-value {
				font-size: 12px !important;
			}
		}
	}
}
