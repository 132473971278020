@import '../../vars/colors';

@media (min-width: 768px) {
	.md-form label {
		font-size: 18px;
	}

	.md-form label.active {
		background-color: $deep-white;
		font-size: 18px;
	}
}
