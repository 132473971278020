@media screen and (min-width: 1024px) {
	.input-container {
		.input {
			height: 55px;
			padding: 15px 17px;

			&:focus,
			&.focused {
				padding: 13px 15px;
			}

			&.error {
				padding: 13px;
			}
		}

		&.error {
			.input {
				&.error {
					padding-left: 60px;
				}
			}
		}
	}
}
