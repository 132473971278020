@media screen and (min-width: 768px) {
	p.text.input-error-text {
		@include fluid-type-default(14px, 18px);

		padding: 0 20px;
	}

	.input-container.error svg {
		position: absolute;
		width: 30px;
		height: 30px;
	}

	.error.dropdown,
	.input-container.error,
	.input-container.error.text,
	.input-container.error.password,
	.input-container.error.select,
	.input-container.error.email {
		.radio-options {
			margin-top: 15px;
		}

		.text.input-error-text {
			padding-left: 0px;
			margin-top: 15px;
		}

		svg {
			top: 17px;
			left: 23px;
		}
	}

	.input-container.error.radio,
	.input-container.error.checkbox-group {
		position: relative;
		padding-left: 38px;
	}
}
