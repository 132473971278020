.select-reward-options {
	.list {
		padding: 0;
		margin: 0;
		list-style-type: none;

		li {
			display: flex;
			align-items: center;
			margin-top: 50px;

			.points {
				margin: 0 5px 0 0 !important;
			}
		}
	}

	.money, .points {
		font: 22px/29px 'Lato', sans-serif;

		&.disabled {
			color: $black-opactiy-5;
		}
	}

	.money {
		font-weight: 600;
	}

	// for table and up
	@include media-breakpoint-up(md) {
		.list {
			margin: 0 0 0 70px;

			li {
				margin-top: 30px;
			}
		}
	}
}
