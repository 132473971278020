/* ==========================================================================
   $BASE-DATE-PICKER
   ========================================================================== */

/**
 * The picker box.
 */

.picker__box {
  padding: 0;
  border-radius: 2px;
  overflow: hidden;
  /**
   * The header containing the month and year stuff.
   */
  .picker__header {
    text-align: center;
    position: relative;
    margin-bottom: 20px;
    select {
      display: inline-block !important;
    }
    .picker__date-display {
      text-align: center;
      background-color: $datepicker-date-bg;
      @extend .white-text;
      padding-bottom: 15px;
      font-weight: 300;
      margin-bottom: 1rem;
      .picker__weekday-display {
        background-color: $datepicker-weekday-bg;
        padding: 10px;
        font-weight: 200;
        letter-spacing: .5;
        font-size: 1rem;
        margin-bottom: 15px;
      }
      .picker__month-display {
        text-transform: uppercase;
        font-size: 2rem;
      }
      .picker__day-display {
        font-size: 4.5rem;
        font-weight: 400;
      }
      .picker__year-display {
        font-size: 1.8rem;
        color: $datepicker-year;
      }
    }
    /**
       * The month and year labels.
       */
    .picker__month,
    .picker__year {
      display: inline-block;
      margin-left: .25em;
      margin-right: .25em;
    }
    /**
       * The month and year selectors.
       */
    .picker__select--month,
    .picker__select--year {
      height: 2em;
      padding: 0;
      margin-left: .25em;
      margin-right: .25em;
      display: inline-block;
      &:focus {
        border-color: $datepicker-focus;
      }
    }
    .picker__select--year {
      width: 30%;
    }
    // Modified
    .picker__select--month.browser-default {
      display: inline;
      @extend .white;
      width: 40%;
    }
    .picker__select--year.browser-default {
      display: inline;
      @extend .white;
      width: 25%;
    }
    /**
       * The month navigation buttons.
       */
    .picker__nav--prev,
    .picker__nav--next {
      position: absolute;
      padding: .5em 1.55em;
      width: 1em;
      height: 1em;
      box-sizing: content-box;
      bottom: 0;
      &:hover {
        cursor: pointer;
        color: #000000;
        background: $datepicker-selected-outfocus;
      }
      &:before {
        content: " ";
        border-top: .5em solid transparent;
        border-bottom: .5em solid transparent;
        border-right: 0.75em solid #676767;
        width: 0;
        height: 0;
        display: block;
        margin: 0 auto;
      }
    }
    .picker__nav--prev {
      left: -1em;
      padding-right: 1.25em;
    }
    .picker__nav--next {
      right: -1em;
      padding-left: 1.25em;
      &:before {
        border-right: 0;
        border-left: 0.75em solid #676767;
      }
    }
    .picker__nav--disabled,
    .picker__nav--disabled:hover,
    .picker__nav--disabled:before,
    .picker__nav--disabled:before:hover {
      cursor: default;
      background: none;
      border-right-color: #f5f5f5;
      border-left-color: #f5f5f5;
    }

  }

  /**
   * The calendar table of dates
   */
  .picker__table {
    text-align: center;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    font-size: 1rem;
    width: 100%;
    margin-top: .75em;
    margin-bottom: .5em;
    th, td {
      text-align: center;
    }
    td {
      margin: 0;
      padding: 0;
    }
    /**
       * The weekday labels
       */
    .picker__weekday {
      width: 14%;
      font-size: .9em;
      padding-bottom: .25em;
      color: #999999;
      font-weight: 500;
      /* Increase the spacing a tad */
      @media (min-height: $pickerweekday-padb-breakpoint) {
        padding-bottom: .5em;
      }
    }
    /**
       * The days on the calendar
       */
    .picker__day--today {
      position: relative;
      letter-spacing: -.3;
      padding: .75rem 0;
      font-weight: 400;
      border: 1px solid transparent;
    }
    .picker__day.picker__day--today {
      color: $datepicker-selected;
    }
    .picker__day--disabled{
      &:before {
        border-top-color: #aaaaaa;
      }
    }
    .picker__day--infocus{
      color: #595959;
      letter-spacing: -.3;
      padding: .75rem 0;
      font-weight: 400;
      border: 1px solid transparent;
      &:hover {
        cursor: pointer;
        color: #000;
        font-weight: 500;
      }
    }
    .picker__day--outfocus {
      display: none;
      padding: .75rem 0;
      @extend .white-text;
      &:hover {
        cursor: pointer;
        color: #dddddd;
        font-weight: 500;
      }
    }
    .picker__day--highlighted:hover,
    .picker--focused .picker__day--highlighted {
      cursor: pointer;
    }
    .picker__day--selected,
    .picker__day--selected:hover,
    .picker--focused {
      //  Circle background
      border-radius: 50%;
      transform: scale(.9);
      background-color: $datepicker-selected;
      &.picker__day--outfocus {
        background-color: $datepicker-selected-outfocus;
      }
      @extend .white-text;
      box-shadow: $z-depth-1-half;
    }
    .picker__day--disabled,
    .picker__day--disabled:hover,
    .picker--focused {
      background: #f5f5f5;
      border-color: #f5f5f5;
      color: #dddddd;
      cursor: default;
    }
    .picker__day--highlighted.picker__day--disabled,
    .picker__day--highlighted.picker__day--disabled:hover {
      background: #bbbbbb;
    }

  }

  /**
   * The footer containing the "today", "clear", and "close" buttons.
   */
  .picker__footer {
    text-align: right;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .picker__button--today,
    .picker__button--clear,
    .picker__button--close {
      border: 1px solid #ffffff;
      background: #ffffff;
      font-size: .8em;
      padding: 1rem 0 0.7rem 0;
      font-weight: 700;
      width: 33%;
      display: inline-block;
      vertical-align: bottom;
      text-transform: uppercase;
      &:hover {
        cursor: pointer;
        color: #000000;
        background: #b1dcfb;
        border-bottom-color: #b1dcfb;
      }
      &:focus {
        background: #b1dcfb;
        border-color: $datepicker-focus;
        outline: none;
      }
      &:before {
        position: relative;
        display: inline-block;
        height: 0;
      }
    }
    .picker__button--today,
    .picker__button--clear{
      &:before {
        content: " ";
        margin-right: .45em;
      }
    }
    .picker__button--today{
      &:before {
        top: -0.05em;
        width: 0;
        border-top: 0.66em solid #0059bc;
        border-left: .66em solid transparent;
      }
    }
    .picker__button--clear{
      &:before {
        top: -0.25em;
        width: .66em;
        border-top: 3px solid #ee2200;
      }
    }
    .picker__button--close{
      &:before {
        content: "\D7";
        top: -0.1em;
        vertical-align: top;
        font-size: 1.1em;
        margin-right: .35em;
        color: #777777;
      }
    }
    .picker__button--today[disabled],
    .picker__button--today[disabled]:hover {
      background: #f5f5f5;
      border-color: #f5f5f5;
      color: #dddddd;
      cursor: default;
    }
    .picker__button--today[disabled]:before {
      border-top-color: #aaaaaa;
    }
  }

}

/* ==========================================================================
   CUSTOM MATERIALIZE STYLES
   ========================================================================== */

.picker__calendar-container {
  padding: 0 1rem;
  thead {
    border: none;
  }
}
