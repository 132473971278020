.tab-panel-body-wrap {
	.tab-panel {
		display: none;
		padding: 1em 0;

		&.active {
			display: block;
		}
	}
}
