.details-header-section {
	display: flex;
	flex-direction: column;

	.links {
		display: flex;
		align-items: center;

		> :first-child,
		> :last-child {
			width: 50%;
		}

		> :first-child {
			text-align: left;
		}

		> :last-child {
			text-align: right;
		}

		.with-help {
			z-index: 9999;
			max-height: 40px;
			text-align: right;

			.title {
				display: inline-flex;
				margin-top: 8px;
			}

			.content {
				width: calc(100vw - 20px);

				.text.d-block.text-left {
					padding-left: 10px;
				}
			}
		}
	}
	// Back button
	a.back-link {
		width: 50px;
		font-weight: 400;

		@include fluid-type-default(14px, 20px);
	}

	.logo {
		pointer-events: none;
		text-align: center;
	}
}

@media screen and (min-width: 768px) {
	.details-header-section {
		flex-direction: column-reverse;

		.links {
			margin: -25px 0 .75em;

			.with-help {
				.content {
					width: 75vw;

					.text.d-block.text-left {
						padding-left: 15px;
					}

					.contact-info {
						flex-direction: row;

						.contact-info-item {
							padding-top: 5px;
							padding-bottom: 5px;
						}

						.contact-info-item:first-child {
							border-right: solid 2px $grey;
							border-bottom: none;
						}
					}
				}
			}
		}
	}
}

// only on mobile screens
@media screen and (max-width: 767px) {
	.details-header-section {
		.links {
			min-height: 40px;
		}
	}
}

@media screen and (min-width: 1024px) {
	.details-header-section {
		.links {
			margin: -30px 0 0 ;

			.with-help {
				.content {
					width: 40vw;

					.contact-info {
						flex-wrap: nowrap;

						.contact-info-item {
							padding-top: 5px;
							padding-bottom: 5px;
						}

						.contact-info-item:first-child {
							width: auto;
							padding-right: 10px;
							white-space: nowrap;
						}
					}
				}
			}
		}
	}
}

.details-header-section.zuppler-catering {
	.collapse-with-close.open {
		.inner-wrapper {
			.content {
				width: unset;

				.text {
					padding-bottom: 10px;
				}
			}
		}

		.more-help {
			margin-top: 0px !important;
		}
	}
}
