/* width */
.custom-scroll::-webkit-scrollbar {
	width: 14px;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
	background: $accordion-title-grey;
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
	border-width: 0 2px;
	border-style: solid;
	border-color: $accordion-title-grey;
	background: $dinova-green;
	border-radius: 10px;
}

/* Handle on hover */
.custom-scroll::-webkit-scrollbar-thumb:hover {
	background: $dinova-green;
}

/* Scrollbar styles */
@media screen and (max-width: 768px) {
	/* width */
	div.business-search-results::-webkit-scrollbar {
		width: unset;
	}

	/* Track */
	div.business-search-results::-webkit-scrollbar-track {
		background: unset;
	}

	/* Handle */
	div.business-search-results::-webkit-scrollbar-thumb {
		border-width: unset;
		border-style: unset;
		border-color: unset;
		background: unset;
		border-radius: unset;
	}

	/* Handle on hover */
	div.business-search-results::-webkit-scrollbar-thumb:hover {
		background: unset;
	}
}
