.form-buttons {
	button {
		@include fluid-type-default(14px, 16px);

		span {
			@include fluid-type-default(14px, 16px);
		}
	}

	&.with-help {
		margin-right: 50px;
	}
}
