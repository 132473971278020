.terms-and-conditions .my-3 {
	float: left;
}

.terms-and-conditions .mr-5 {
	margin-right: auto !important;
	// font-size: 1.5rem;
}

.terms-and-conditions .form-check-input[type='radio'][class*='optin'] + label:before,
.terms-and-conditions [type='radio'][class*='optin'] + label:after {
	width: 25px;
	height: 25px;
	margin: auto;
}

.terms-and-conditions .form-check-input[type='radio'] + label {
	padding-left: 30px;
	line-height: 3.6rem !important;
}

.terms-and-conditions .form-check-input[type='radio'] + label,
.terms-and-conditions .form-check-input[type='checkbox'] + label {
	line-height: 3.6rem !important;
}

.terms-and-conditions .form-check-input[type='radio'][class*='error']:not(:checked) + label:before,
.terms-and-conditions .form-check-input[type='radio'][class*='error']:not(:checked) + label:after {
	border-color: $danger-red;
	border-width: 3px;
}

@media (max-width: 400px) {
	.terms-and-conditions .form-check-input[type='radio'] + label,
	.terms-and-conditions .form-check-input[type='checkbox'] + label {
		font-size: 1.7rem;
	}
}
