.cluster-map-marker {
	position: relative;
	width: 28px;
	height: 40px;
	cursor: pointer;

	.marker {
		position: absolute;
		z-index: 1;
		height: inherit;
	}

	.cluster-amount {
		position: absolute;
		z-index: 2;
		top: 3px;
		left: 3px;
		display: flex;
		width: 22px;
		height: 22px;
		align-items: center;
		justify-content: center;
		border: solid transparent 0px;
		margin-top: 0;
		background: $dinova-green;
		border-radius: 100%;
		color: white;
		text-align: center;
	}
}

.favorite {
	height: 28px;

	.cluster-amount {
		background-color: transparent;
		left: 3px;
		top: 4px;
		color: red;
	}
}
