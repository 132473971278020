@media screen and (max-width: 767px) {
	.d-sm-none {
		display: none !important;
	}

	.col-sm-3 {
		max-width: 25%;
		flex: 0 0 25%;
	}

	.col-sm-6 {
		max-width: 50%;
		flex: 0 0 50%;
	}

	.col-reverse-small {
		flex-direction: column-reverse;
	}
}
