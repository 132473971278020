.dine-assist {
	.jumobotron {
		margin-bottom: 0px;

		+ div img {
			width: 100%;
		}
	}

	.container.page-header {
		margin-top: 30px;
	}

	h1 {
		max-width: 80%;
		margin: 0 auto;

		@include fluid-type-default(24px, 26px);
	}

	h2 {
		@include fluid-type-default(20px, 22px);
	}

	h3 {
		@include fluid-type-default(20px, 22px);
	}

	h4 {
		@include fluid-type-default(18px, 20px);
	}

	h5 {
		@include fluid-type-default(24px, 26px);
	}

	// header description
	.col-12 > .d-block.normal.sm.text.text-none {
		margin-bottom: 4rem;
	}

	.row {
		max-width: 1024px;
		margin: 0 auto;
	}

	.card {
		width: 100%;
		background-color: $accordion-title-grey;
		border-radius: 15px;

		img {
			border-radius: 15px 15px 0 0;
		}
	}

	.card,
	.branding {
		img {
			width: 100%;
			min-height: 1px;
		}
	}

	.endorsement {
		margin-top: 2rem;

		.quote {
			margin: 0 auto 1rem;
			font-style: italic;
			font-weight: 300;
			text-align: center;
		}

		.quote:before {
			display: inline-block;
			width: 20px;
			height: 20px;
			margin-right: .25rem;
			background-color: transparent;
			background-image: url(#{$imageBaseUrl}/icons/openQuote.svg);
			background-repeat: no-repeat;
			background-size: contain;
			content: '';
		}

		.quote:after {
			display: inline-block;
			width: 20px;
			height: 20px;
			margin-left: .25rem;
			background-color: transparent;
			background-image: url(#{$imageBaseUrl}/icons/closeQuote.svg);
			background-repeat: no-repeat;
			background-size: contain;
			content: '';
		}

		img {
			width: 120px;
		}

		.signee {
			border-bottom: solid 2px $sunset-orange;

			@include fluid-type-default(16px, 18px);

			font-weight: 600;
		}
	}

	.footer {
		display: flex;
		flex-direction: column;
		align-items: center;

		button.btn {
			width: 90%;
			max-width: 250px;
			padding: 5px 28px;
			margin-top: 3rem;
		}
	}
}

@media screen and (max-width: 1024px) {
	.dine-assist {
		.card,
		.footer {
			width: 80%;
			margin: 0 auto;
		}

		.endorsement {
			.quote {
				width: 75%;
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.dine-assist {
		.footer {
			p.text {
				width: 75%;
				margin: 0 auto;
			}
		}
	}
}
