.edit-personal-form {
	.required-field-note {
		margin-bottom: 10px;
	}

	.field {
		margin-top: 0;

		// &:not(:nth-child(2)) {
		// 	margin-top: 30px;
		// }
	}

	.company-select-wrapper .input-wrapper {
		margin-top: 0px;
	}

	.note {
		margin: 50px 0 0;
		color: $black-text;
		font: 'Lato', sans-serif;
		font-size: 14px;

		@include fluid-type(map-get($grid-breakpoints, xs), map-get($grid-breakpoints, md), 18px, 22px);

		.cross-mark {
			margin-right: 5px;
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		.required-field-note {
			margin-bottom: 24px;
		}

		// .field:not(:first-child) {
		// 	margin-top: 28px;
		// }

		.note {
			margin-top: 40px;
			font-size: 16px;
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		.required-field-note {
			margin-bottom: 25px;
		}

		// .field:not(:first-child) {
		// margin-top: 25px;
		// }

		.note {
			font-size: 18px;
		}
	}
}
