.edge {
	// mobile only
	@media screen and (max-width: 767px) {
		.search-types.nav-tabs {
			.nav-item {
				width: calc(50vw - 13px);
				min-height: 48px;
			}
		}
	}
}
