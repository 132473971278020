.auto-enroll-user-form {
	.trigger {
		width: unset;
		padding: 0;

		p.link.collapsible {
			padding: 0;

			@include fluid-type-default(14px, 16px);
		}
	}

	span.link {
		display: inline;
	}

	.trigger-panel-wrapper {
		//.inner-wrapper {
		margin-top: 10px;

		&:before {
			border: none;
		}

		.close-btn {
			top: 26px;
		}
		// }
	}

	.input-container.radio .radio-options {
		margin-top: 5px;
	}
}
