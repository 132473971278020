.header-section {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 20px;

	.banner-img {
		width: 100%;
		height: 155px;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.back-link {
		padding: 15px 0;
		color: #1c871c !important;
		font-size: 20px;
		font-weight: 400;
		text-decoration: underline !important;
	}

	.title {
		align-self: center;
		font-size: 24px;
		font-weight: bold;
		line-height: 1;
	}

	.logo-img {
		height: 80px;
		align-self: center;
		margin-top: 20px;
	}

	// for table and up
	@include media-breakpoint-up(md) {
		margin-bottom: 25px;

		.banner-img {
			height: 172px;
		}

		.back-link {
			font-size: 18px;
		}

		.logo-img {
			height: 100px;
			margin-top: 25px;
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		margin-bottom: 40px;

		.banner-img {
			height: 338px;
		}

		.back-link {
			font-size: 20px;
		}

		.title {
			margin-top: -47px;
			font-size: 34px;
		}

		.logo-img {
			display: none;
		}
	}
}
