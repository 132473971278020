.endorse {
	display: flex;
	align-items: center;

	p.text {
		margin: 0px;

		&:first-child {
			@include fluid-type-default(18px, 22px);
		}
	}

	p.text.disclaimer {
		margin: 0 0 20px;
	}

	div.flex {
		margin: .5em 0 .5em .5em;
	}
}

@media screen and (min-width: 768px) {
	.endorse {
		div.flex {
			margin: 0;

			&.rec-btns {
				margin: 0 1rem;
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.endorse {
		p.text {
			&:first-child {
				@include fluid-type-default(16px, 18px);
			}
		}
	}
}
