.faq.accordion {
	.accordion__item .accordion__title {
		border: none;
	}
}

.faqs {
	.faq.accordion {
		.accordion__item .accordion__title {
			.section-title {
				@include fluid-type-default(16px, 18px);

				padding: 20px 37px 20px 10px;
			}
		}

		.text {
			padding: 1em;
			margin-bottom: 0;
			line-height: 1.75;

			@include fluid-type-default(14px, 18px);
		}
	}
}

@media screen and (min-width: 768px) {
	.faqs {
		.faq.accordion {
			.text {
				padding: 1em 20px;
			}

			.accordion__item .accordion__title .section-title {
				padding: 1em 45px 1em 20px;

				.accordion__arrow {
					right: 20px;
				}
			}
		}
	}
}
