.auto-enroll-user-page {
	min-height: 74vh;

	.page-header {
		margin-bottom: 20px;
	}

	.page-banner {
		padding-bottom: 10px;
	}
}
