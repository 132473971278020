.restaurant-list {
	position: relative;
	// mobile only
	@media screen and (max-width: 767px) {
		.no-results {
			max-width: 90%;
			margin: 1em auto 0;
		}
	}
}
