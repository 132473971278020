.date-paginator {
	display: flex;
	align-items: center;
	justify-content: center;

	p {
		margin: 0;
	}

	.title {
		min-width: 70px;
		margin: 0 1em;
	}

	&.month {
		.title {
			min-width: 183px;
		}
	}

	.prev,
	.next {
		display: flex;
		width: 48px;
		height: 48px;
		align-items: center;
		justify-content: center;
		border: 2px solid #cdcdcd;
		background-color: #fafbfb;
		cursor: pointer;

		img {
			width: 18px;
			height: 29px;
		}
	}
}
