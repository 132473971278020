.month-year-date-picker {
	padding: 12px 16px !important;
	background-color: $adminDining_white;

	.MuiInput-underline:after,
	.MuiInput-underline:before {
		content: unset;
	}

	input {
		@include fluid-type-default(14px, 16px);
	}

	.MuiInputAdornment-root.MuiInputAdornment-positionEnd {
		.MuiButtonBase-root.MuiIconButton-root {
			padding: 0px;
		}
	}

	.MuiSvgIcon-root {
		width: 24px;
		height: 24px;
	}

	.clear {
		svg {
			width: 20px;
			height: 20px;
			margin-top: 2px;
			margin-left: 4px;
			color: rgba(0, 0, 0, .54);
			cursor: pointer;
		}
	}
}

.MuiPickersYear-root:focus,
.MuiPickersMonth-root:focus {
	color: $dinova_green !important;
}

.MuiPickersBasePicker-container {
	.MuiPickersToolbar-toolbar {
		background-color: $dinova_green;

		h6 {
			line-height: 16px;

			@include fluid-type-default(14px, 16px);
		}
	}
}

.MuiTypography-colorPrimary,
.MuiButton-textPrimary {
	color: $dinova_green !important;
}

div.MuiPickersMonth-root,
div.MuiPickersYear-root {
	@include fluid-type-default(12px, 14px);
}

div.MuiPickersMonth-monthSelected,
div.MuiPickersYear-yearSelected {
	@include fluid-type-default(15px, 17px);
}

.MuiButton-textPrimary,
.MuiButton-label {
	line-height: 16px;

	@include fluid-type-default(12px, 14px);
}
