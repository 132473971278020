.profile-section-list {
	.item {
		max-width: 100%;
		justify-content: space-between;

		> div {
			max-width: 90%;
		}

		.profile-section-item {
			max-width: 100%;

			.title {
				white-space: nowrap;
			}
		}
	}

	> *:not(:last-child) {
		margin-bottom: 35px;
	}

	// for table and up
	@include media-breakpoint-up(md) {
		> *:not(:last-child) {
			margin-bottom: 28px;
		}
	}

	// for desktop
	@include media-breakpoint-up(lg) {
		> *:not(:last-child) {
			margin-bottom: 25px;
		}
	}

	&.profile-security-list {
		svg {
			cursor: pointer;
		}
	}
}
