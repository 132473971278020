.main-footer {
	padding: 15px 0 25px;
	background-color: $adminDining_white;
	text-align: center;

	.DI_FooterLogo {
		margin-bottom: 2px;
		text-align: center;

		img {
			width: 22px;
			height: 22px;
		}
	}

	.DI_FooterLinks {
		margin: 0 10px 25px;
		text-align: center;
	}

	.Di_FooterLink {
		display: inline-block;
		padding: 0 5px;
		border-right: solid $black 1px;
		margin: 0 0 6px;
		font-size: 13px;
		font-weight: 400;
		text-align: center;
		white-space: nowrap;

		&:last-child {
			border: none;
		}
	}

	.DI_FooterReg {
		font-size: 13px;
		text-align: center;
	}

	// for table and up
	@include media-breakpoint-up(md) {
		padding-top: 8px;

		.DI_FooterLogo {
			img {
				width: 30px;
				height: 30px;
			}
		}

		.DI_FooterLinks {
			margin: 7px auto 33px;

			.Di_FooterLink {
				padding: 0 5px;
				margin: 0 0 13px;
				font-size: 18px;
			}
		}

		.DI_FooterReg {
			font-size: 18px;
		}
	}
}

@media screen and (min-width: 1024px) {
	.main-footer {
		padding-top: 2px;

		.DI_FooterLogo {
			margin-bottom: 15px;

			img {
				width: 39px;
				height: 39px;
			}
		}

		.DI_FooterLinks {
			margin-bottom: 6px;

			.Di_FooterLink {
				padding: 0 11px;
				margin: 0 0 13px;
			}
		}
	}
}
