$dark-grey: rgb(218, 217, 218);
$light-grey: rgb(246, 247, 248);

.company-transactions {
	.header-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 15px 0;
		background-color: $light-grey;

		p.text.sm {
			@include fluid-type-default(14px, 16px);

			margin: 0 16px 0 0;
		}
	}

	.MuiTableCell-alignRight {
		flex-direction: row;
	}
}
