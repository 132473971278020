.details-location {
	.map-wrapper {
		width: 100%;
		height: 250px;

		div {
			cursor: default !important;
		}
	}

	.map-info {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: .5em 0 0;

		.addresses {
			max-width: 50%;
		}

		.addresses,
		.distance,
		.direction-btn {
			@include fluid-type-default(12px, 12px);
		}

		.distance {
			font-weight: 400;
		}

		.right-column {
			height: 100%;
		}

		.direction-btn {
			padding: 3px 7px;
			// margin-top: 5px;
			// background-color: $orange-peel;
			border: solid $black-text 1px;
			color: $black-text;
			font-weight: 600;
			text-decoration: none;
			text-transform: uppercase;
		}
	}

	// Hide Google Map Els
	// .gmnoprint,
	// .gm-style-cc,
	// [href*='https://maps.google.com/maps'] {
	// 	// display: none !important;
	// }
}

@media screen and (min-width: 768px) {
	.details-location {
		.map-wrapper {
			height: 300px;
		}
	}
}

@media screen and (min-width: 1024px) {
	.details-location {
		.map-wrapper {
			height: 400px;
		}
	}
}
