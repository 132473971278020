.ie {
	.Di_LogoBoxLeft {
		.logo-link {
			min-width: 175px;
		}
	}

	@media screen and (min-width: 1024px) {
		.Di_LogoBoxLeft {
			.logo-link {
				min-width: 265px;
			}
		}
	}
}
