$connected-card-icon-width: 45px;
$connected-card-icon-height: 30px;
$help-icon-width: 20px;

.connected-cards-content {
	max-height: 57vh;
	padding: .5em;
	background-color: $light-grey;
	overflow-x: hidden;
	overflow-y: scroll;

	.connected-card {
		display: flex;
		width: 100%;
		align-items: flex-start;
		justify-content: space-between;
		padding: .5em;
		margin-top: .25em;
		margin-bottom: .25em;
		background-color: $adminDining_white;
		border-radius: 4px;
		box-shadow: $box-shadow-sm;

		> div {
			width: 100%;

			&:first-child {
				max-width: calc(100% - #{$connected-card-icon-width} - 1em);
			}

			&.flex-col {
				position: relative;
			}
		}

		p.text {
			overflow: hidden;
			margin-bottom: .5em;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.close {
			@include close-button(12px, 12px);
		}

		.cc-icon {
			height: $connected-card-icon-height;
		}

		&.ineligible {
			> div:first-child {
				max-width: calc(100% - #{$connected-card-icon-width} - #{$help-icon-width} - 1em);
				opacity: .6;
			}

			// .text:not(.collapse-with-close-text),
			.cc-icon {
				opacity: .6;
			}

			.cc-icon {
				margin-right: 30px;
			}

			.with-help {
				position: absolute;
				top: -6px;
				right: -13px;
				width: 300px;

				.collapse-with-close.open {
					z-index: 100;
					margin-top: 31px;
					margin-right: 1px;
				}
			}

			.Di_SideHelpIcon {
				width: $help-icon-width;
				height: $help-icon-width;
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.connected-cards-content {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}
