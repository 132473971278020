.restaurant-description {
	overflow: hidden;

	.LinesEllipsis {
		margin-bottom: 0px;
		line-height: 1.75;
		text-overflow: ellipsis;
		white-space: pre-line;

		@include fluid-type-default(13px, 16px);
	}
}
