.compass-icon {
	&.map-btn {
		display: flex;
		width: 35px;
		height: 35px;
		align-items: center;
		justify-content: center;
		background-color: $dinova-green;

		img {
			width: 25px;
			height: 25px;
		}
	}
}
