// Centered Tab Panel Header

.tab-panel-header.center {
	.tabs {
		justify-content: center;

		.tab {
			display: flex;
			flex: 1;
			align-items: center;
			justify-content: center;
		}
	}
}
