#root {
	min-height: 100vh;
}

.app-wrapper {
	display: flex;
	min-height: 100vh;
	flex-direction: column;

	.main-footer {
		margin-top: 20px;
	}
}

@media screen and (min-width: 768px) {
	.app-wrapper {
		.main-footer {
			margin-top: 30px;
		}
	}
}

@media screen and (min-width: 1024px) {
	.app-wrapper {
		.main-footer {
			margin-top: 50px;
		}
	}
	// >>>>>>> master
}

.app-wrapper:not(.ie) {
	.app-content {
		flex: 1 1 100%;
	}
}
