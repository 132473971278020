@import './ie-styles';

.search-tabs {
	@include media-breakpoint-up(lg) {
		width: 92%;
		min-width: 750px;
		max-width: 940px;
		margin: 0 auto;
	}

	.search-btn {
		transition: all .2s ease-in-out, margin 0s, padding 0s;
	}
}

// common styles for all tabs which have this search-tab class
.search-tab {
	.search-group {
		flex-direction: column;

		&.input-group {
			position: relative;
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			align-items: stretch;
		}

		> * {
			display: flex;
			width: 100%;
			flex-direction: column;
		}

		[class*='input-wrapper'] {
			flex: 1;
			margin-bottom: 13px;

			.title {
				margin-bottom: 0;
				color: $black-text;
				font-size: 18px;
				font-weight: 400;
				line-height: 42px;
			}
		}

		.input-label {
			display: inline-block;
			padding: 0 0 0 13px;
			margin-bottom: 7px;
			font-weight: 400;
			line-height: 1;

			@include fluid-type-default(16px, 18px);

			.required {
				color: $required-red;
			}
		}

		.search-dropdown {
			position: relative;
			width: 100%;
			flex: 1 1 auto;
			margin-bottom: 0;

			.search-input {
				width: 100%;
				height: 100%;
				min-height: 44px;
				padding: 0 50px 0 15px;
				border: solid $input-border-grey 1px;
				border-radius: 5px;
				outline: none;
			}

			.autocomplete-dropdown {
				padding: 0 10px;

				.option {
					padding: .5em 0;
					border-bottom: solid 2px $foggy-grey;
					color: $black-text;
					font-weight: 400;

					@include fluid-type-default(12px, 18px);

					&:last-child {
						padding-bottom: 0;
						border-bottom-color: transparent;
					}
				}
			}

			.dropdown-list {
				padding-top: 0;
			}

			.form-control {
				height: unset;
				padding: 2px 15px;
				border-color: $input-border-grey;
				border-radius: 5px;
				box-shadow: none;
				font-size: 18px;
				line-height: 38px;

				&::placeholder {
					font-style: italic;
					font-weight: 300;
				}
			}

			&.show {
				z-index: 11;

				.form-control,
				.search-input {
					border-radius: 5px 5px 0 0;
				}
			}
		}

		.search-btn-wrapper {
			.search-btn {
				padding: 1em 0;
				margin: 0;
				font-size: 16px;
				font-weight: bold;
				letter-spacing: 1px;
				text-align: center;
			}
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		.search-group {
			&.input-group {
				flex-flow: row nowrap;
			}

			.form-control {
				padding: 0 15px;
				line-height: 40px;
			}

			[class*='input-wrapper'] {
				margin-bottom: 0;

				&:first-child {
					.form-control,
					.dropdown-toggle {
						border-right: none !important;
						border-radius: 5px 0 0 5px !important;
					}

					.show {
						.form-control,
						.dropdown-toggle {
							border-radius: 5px 0 0 !important;
						}
					}
				}
			}

			.input-label {
				padding: 0 0 0 11px;
				margin-bottom: 7px;
			}

			.search-btn-wrapper {
				width: auto;
				justify-content: flex-end;
				margin-top: 20px;

				.search-btn {
					width: 95px !important;
					min-height: 40px;
					padding: 5px 0;
					border-radius: 0 5px 5px 0;
					font-size: 16px;
					letter-spacing: 0;
					line-height: 30px;
				}
			}
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		.search-group {
			[class*='input-wrapper'] {
				.form-control {
					padding: 6px 15px;
				}
			}

			.search-btn-wrapper {
				margin-top: 24px;

				.search-btn {
					min-width: 146px;
					padding: 9px 0;
					font-size: 20px;
				}
			}
		}
	}
}

[class*='input-wrapper'].hasError > .search-dropdown.show {
	// location error message.
	.dropdown-list {
		top: 44px;
	}
}

@media screen and (min-width: 768px) {
	[class*='input-wrapper'].hasError + .search-btn-wrapper {
		button.search-btn {
			min-height: 40px;
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 903px) {
	[class*='input-wrapper'].hasError + .search-btn-wrapper {
		button.search-btn {
			margin-bottom: 48px;
		}
	}
}

@media screen and (min-width: 903px) {
	[class*='input-wrapper'].hasError + .search-btn-wrapper {
		button.search-btn {
			margin-bottom: 24px;
		}
	}
}

@media screen and (min-width: 1024px) {
	[class*='input-wrapper'].hasError + .search-btn-wrapper {
		button.search-btn {
			margin-bottom: 48px;
		}
	}

	[class*='input-wrapper'].hasError > .search-dropdown.show {
		// location error message.
		.dropdown-list {
			top: 52px;
		}
	}
}

@media screen and (min-width: 1440px) {
	[class*='input-wrapper'].hasError + .search-btn-wrapper {
		margin-top: 25px;
	}
}

.search-tab-footer {
	@media screen and (min-width: 768px) {
		display: flex;
	}
}

@media screen and (min-width: 1440px) {
	.search-tab .search-group {
		.search-btn-wrapper {
			.search-btn {
				margin-top: 1px !important;
			}
		}
	}
}
