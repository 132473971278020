@media screen and (min-width: 768px) {
	.top-border-wrapper {
		top: 0px;

		.focus-border {
			&.front {
				width: 19px;
			}
		}
	}

	.password {
		.top-border-wrapper {
			top: 0px;
		}
	}

	.transparent {
		@include fluid-type-default(18px, 20px);

		margin-left: 5px;
	}

	.dropdown {
		.top-border-wrapper {
			.focus-border {
				&.front {
					width: 24px;
				}
			}
		}
	}

	.error {
		.top-border-wrapper,
		.password .top-border-wrapper {
			top: 0px;
		}
	}
}
