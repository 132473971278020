.help-btn,
.trigger {
	display: inline-flex;
	width: 50px;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 6px 0 8px;
	margin: 0;

	.Di_IconText {
		position: static;
	}
}

.trigger {
	padding: 15px 0 0;

	.accordion__arrow {
		top: calc(50% + 8px);
	}
}
