@media screen and (min-width: 768px) {
	.dashboard-connect-message {
		a.btn.btn-primary {
			padding-right: 75px;
			padding-left: 75px;
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.dashboard-connect-message {
		> div {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		div.content {
			display: flex;
			flex-direction: column;
			justify-content: center;

			a.btn.btn-primary {
				width: 50%;
				margin-right: auto;
				margin-left: auto;
			}
		}
	}
}
