.nav-item.menu {
	display: flex;

	a[role='button'] {
		color: $black-text;
	}
}

.MuiPopover-root.nav-link.nav-menu {
	.MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
		overflow: visible;
		border: solid $dark-grey 1px;
		margin-top: 8px;
		border-radius: 0px;
		box-shadow: 0 0 10px 0px rgba(0, 0, 0, .1) !important;

		ul.MuiList-root.MuiMenu-list {
			overflow: visible;
			padding: 0px;

			&:after,
			&:before {
				position: absolute;
				top: 0px;
				display: block;
				width: 0;
				height: 0;
				border-style: solid;
				content: '';
			}

			&:before {
				top: -16px;
				right: 10px;
				border-width: 8px;
				border-color: transparent transparent $dark-grey transparent;
			}

			&:after {
				top: -14px;
				right: 11px;
				border-width: 7px;
				border-color: transparent transparent $adminDining_white transparent;
			}

			li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root {
				@include fluid-type-default(14px, 16px);

				&:not(:last-child) {
					border-bottom: solid $dark-grey 1px;
				}
			}
		}
	}
}
