$red: rgb(218, 28, 34);

.no-response-notice-msg {
	padding: 15px 15px 15px 60px;
	border: solid 2px $red;
	background: url(#{$imageBaseUrl}/icons/icon-warning-red.svg) 15px center no-repeat;
	background-size: 28px;
	color: $red;
	font-weight: 400;

	@include fluid-type-default(16px, 18px);
}
