// NOTE: This isn't the best dir for these styles to live in.  Perhaps a registration-form specific style sheet would be better.
.registration-form {
	.input-wrapper .radio {
		fieldset {
			min-width: 166px;
		}
	}

	// Radio Buttons
	.form-check-input[type='radio'] {
		// Label
		+ label {
			padding-right: 10px;
			padding-left: 30px;
		}

		// Opt-in Radio Buttons
		&.optin {
			+ label {
				&:before,
				&:after {
					width: 25px;
					height: 25px;
					margin: auto;
				}
			}
		}

		// Error
		&.error:not(:checked) {
			// Label
			+ label {
				&:before,
				&:after {
					border-width: 3px;
					border-color: $danger-red;
				}
			}
		}
	}
}

// Mobile
@media screen and (max-width: 400px) {
	.registration-form {
		// Radio Buttons
		.form-check-input[type='radio'] {
			// Label
			+ label {
				font-size: 1.7rem;
			}
		}
	}
}
