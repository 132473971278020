.main-header {
	padding-top: 20px;

	&.admin-dining-guides {
		background: linear-gradient(90deg, #006432 4.79%, #058A50 100%);
		
		* { 
			color: $adminDining_white !important
		}

		.logo-link {
			img {
				margin: 0;
				height: 30px;
			}
		}

		.Di_LogoBoxLeft {
			display: inline;
		}
	}

	&.admin-dining-guides-landing {
		min-height: 160px !important;
	}

	.Di_HeaderPadding, .Di_SubHeaderPadding {
		display: flex;
		height: auto;
		align-items: baseline;
		padding: 5px 10px 20px;

	}

	// for tablet and up
	@include media-breakpoint-up(md) {
		.Di_HeaderPadding, .Di_SubHeaderPadding {
			padding: 0px 20px 20px;
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		padding-top: 0;

		.Di_HeaderPadding {
			padding: 20px 25px;

			.register-center {
				flex-direction: column;
			}

		}

		.Di_SubHeaderPadding {
			padding: 0 25px;
		}
	}
}
