.accordion[class*='Dining Options'] {
	.accordion__item {
		.accordion__title {
			.section-title:not([class*='col']) {
				display: flex;
				justify-content: center;
				padding: 0 0 .75rem 0;
				border-bottom: solid 1px $input-border-grey;
			}
		}
	}
}
