@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';

.recommendations-slider {
	.title {
		display: block;
		padding: 0 0 0 10px;
		margin-bottom: 5px;
		font-weight: 400;
		text-align: left;

		@include fluid-type-default(18px, 26px);
	}

	.slides {
		display: flex;
		align-items: center;
		margin-bottom: 28px;

		.slick-list {
			width: 100%;
		}

		.slick-arrow {
			position: relative;
			left: 0;
		}

		.slick-arrow:before {
			display: block;
			width: 20px;
			height: 30px;
			background-position: center;
			background-repeat: no-repeat;
			color: #000;
			content: '';
			opacity: 1;
		}

		.slick-prev {
			&:before {
				background-image: url(#{$imageBaseUrl}/icons/icon-arrow-left-green.svg);
			}

			&.slick-disabled:before {
				background-image: url(#{$imageBaseUrl}/icons/icon-arrow-left-gray.svg);
			}
		}

		.slick-next {
			&:before {
				background-image: url(#{$imageBaseUrl}/icons/icon-arrow-right-green.svg);
			}

			&.slick-disabled:before {
				background-image: url(#{$imageBaseUrl}/icons/icon-arrow-right-gray.svg);
			}
		}

		.slide {
			display: block;
			padding: 0 10px;
			color: #458c35;
			text-align: center;
			text-decoration: none;

			.img {
				display: block;
				width: 100%;
				max-height: 200px;
				margin: 0 auto;
				object-fit: contain;
			}

			.legend {
				display: inline-block;
				width: 70%;
				margin: 5px 0 10px;
				font-weight: 400;
				line-height: 21px;

				@include fluid-type-default(14px, 18px);
			}
		}

		.slick-dots li button:before {
			width: 24px;
			height: 24px;
			font-size: 10px;
			line-height: 24px;
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		padding: 40px 10px 0px;

		.slick-slider.slides {
			margin-bottom: 10px;

			.slide {
				.legend {
					width: 100%;
					font-size: 14px;
				}
			}
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		padding: 0;
		margin-top: 37px;

		.slick-slider.slides {
			margin-top: 18px;

			.slide {
				.legend {
					margin-top: 13px;
					font-size: 20px;
				}
			}
		}

		.title {
			padding: 0;
			font-size: 31px;
		}
	}
}
