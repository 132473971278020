.funnel-chart-wrapper {
	&.lv {
		.funnel-slice {
			&:hover .left {
				top: 5%;
				display: flex;
				width: 200px;
				height: 90%;
				align-items: center;
				justify-content: center;
				visibility: visible;

				p.sm {
					@include fluid-type-default(12px, 14px);

					padding: 12px;
					border: solid rgb(240, 240, 240) 1px;
					margin: 0 12px 0 0;
					border-radius: 4px;
					box-shadow: 0px 0px 11px -1px rgba(0, 0, 0, .05);
					line-height: 15px;
					text-align: center;
				}
			}

			.potential {
				position: absolute;
				width: 200px;
				text-align: center;
				transform: translateX(100%);

				p.lg {
					margin: 0;
					color: $black-text;
					line-height: 22px;

					@include fluid-type-default(20px, 22px);
				}

				p.xl {
					@include fluid-type-default(44px, 48px);

					margin: 0 0 8px 0;
					line-height: 48px;
				}
			}

			&.two,
			&.three,
			&.four {
				.right {
					min-width: unset;

					&:after {
						position: absolute;
						top: 0;
						right: 3px;
						width: 0;
						height: 0;
						border-top: 53px solid transparent;
						border-bottom: 54px solid transparent;
						border-left: 60px solid transparent;
						content: ' ';
						transform: translateX(100%);
					}
				}

				&.two {
					.right {
						width: 197px;
						min-width: 197px;
						max-width: 197px;

						&:after {
							// border-left-color: transparentize($loading-orange, .8);
							border-left-color: rgb(252, 231, 214);
						}

						.potential {
							right: -76px;

							p.lg {
								color: transparentize($loading-orange, .1);
							}
						}
					}
				}

				&.three {
					.right {
						width: 280px;
						min-width: 280px;
						max-width: 280px;

						&:after {
							// border-left-color: transparentize($loading-red, .9);
							border-left-color: rgb(250, 234, 232);
						}

						.potential {
							right: -76px;

							p.lg {
								color: transparentize($loading-red, .2);
							}
						}
					}
				}

				&.four {
					.right {
						width: 362px;
						min-width: 362px;
						max-width: 362px;

						&:after {
							// border-left-color: transparentize($dinova-green, .8);
							border-left-color: rgb(217, 231, 213);
						}

						.potential {
							right: -76px;

							p.lg {
								color: transparentize($dinova-green, .1);
							}
						}
					}
				}
			}
		}
	}
}
