@import './page_limit/main';
@import './header/main';
@import './banners/main';
@import './accordion/main';
@import './logo/main';
@import './input/main';
@import './link-back/main';
@import './alert/main';
@import './form/main';
@import './more_button/main';

/* Global Selectors */

/* Layout */
.center {
	text-align: center;

	> * {
		text-align: initial;
	}
}

.center-margin {
	margin-right: auto;
	margin-left: auto;
}

.full-width {
	width: 100% !important;
	max-width: 100% !important;
}

.half-width {
	width: 50%;
}

.third-width {
	width: calc(100% / 3) !important;
}

.static {
	position: static !important;
}

/* Flex Layout */
.flex {
	display: flex;
}

.flex-col,
.column {
	display: flex;
	flex-direction: column;
}

.row-reverse {
	flex-direction: row-reverse;
}

.column-reverse {
	flex-direction: column-reverse;
}

.flex.row-wrap {
	flex-wrap: wrap;
}

.align-end {
	align-items: flex-end;
}

.align-center {
	align-items: center;
}

.align-baseline {
	align-items: baseline;
}

.justify-around {
	justify-content: space-around;
}

.justify-between {
	justify-content: space-between;
}

.justify-start {
	justify-content: flex-start;
}

.justify-center {
	justify-content: center;
}

.justify-end {
	justify-content: flex-end;
}

/* cursor */
.pointer {
	cursor: pointer;
}

/* Fonts */
.normal-text {
	text-transform: none !important;
}

.font-green {
	color: $dinova-green;
}

/* Hidden */
.hidden {
	display: none;
	height: 0 !important;
	padding: 0 !important;
	margin: 0 !important;
	opacity: 0;
	visibility: hidden;
}

span.required {
	margin-left: 1px;
	color: $danger-red;
}

.sm-hidden {
	@media screen and (max-width: 767px) {
		display: none;
		height: 0 !important;
		padding: 0 !important;
		margin: 0 !important;
		opacity: 0;
		visibility: hidden;
	}
}

.green-text {
	color: $dinova-green !important;
}

.red-text {
	color: $danger-red !important;
}

.preserve-text {
	white-space: pre-wrap;
}

:not(i).arrow {
	display: inline-block;
	padding: 4px;
	border: solid black;
	border-width: 0 2px 2px 0;
	transition: transform ease .3s;

	&.green {
		border-color: $dinova-green;
		background-color: transparent !important;
	}

	&.right {
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}

	&.left {
		-webkit-transform: rotate(135deg);
		transform: rotate(135deg);
	}

	&.up {
		-webkit-transform: rotate(-135deg);
		transform: rotate(-135deg);
	}

	&.down {
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}

.link-button,
.link-button.btn {
	padding: 0px;
	border: none;
	margin: 0px;
	background-color: $adminDining_white;
	color: $dinova-green !important;
	cursor: pointer;
	font-weight: 300;
	text-decoration: underline !important;
	text-transform: none;

	@include fluid-type-default(14px, 16px);

	&.btn-secondary,
	&.btn-primary {
		border: none !important;

		&:focus {
			background-color: transparent !important;
			color: $dinova-green !important;
		}
	}
}

.no-border {
	border: none !important;
}

.no-wrap {
	flex-wrap: nowrap;
	white-space: nowrap;
}
