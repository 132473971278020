@media screen and (max-width: 768px) {
	.business-search-filter-wrapper {
		.filter-header {
			display: flex;
			height: 60px;
			align-items: center;
			justify-content: center;
		}

		.filters {
			overflow: scroll;
			height: calc(100% - 120px);
			max-height: calc(100% - 120px);
		}
	}
}

.business-search-filter-wrapper {
	z-index: 11;
	background-color: $adminDining_white;

	.filter-header {
		.text {
			margin-bottom: 0;
		}

		> .text {
			padding: 1em;
			// mobile filter header "FILTERS"
			white-space: nowrap;
		}
	}

	.filter-tab-panel {
		display: none;
		height: calc(100% - 318px); // height of footer buttons
		padding: 0 1em;
		overflow-x: hidden;
		overflow-y: scroll;

		&.active {
			display: block;
		}

		.form-check {
			padding: 23px 7px;
			border-bottom: solid $accordion-title-grey 2px;
			margin: 0 !important;

			&:last-child {
				border-bottom: none;
			}

			label {
				padding-left: 37px !important;
			}

			label:after {
				top: 1px !important;
				width: 25px !important;
				height: 25px !important;
			}

			input[type='checkbox']:checked + label:before {
				top: -1px !important;
				left: 2px !important;
				width: 13px !important;
				height: 22px !important;
				border-width: 0px 4px 4px 0px !important;
				border-radius: 2px;
			}
		}
	}

	.business-search-filter-footer {
		display: flex;
		height: 60px;
		justify-content: flex-end;
		padding: 0 1em;

		button,
		button.btn.btn-secondary {
			width: auto;
			padding: 0 1rem;
			border: none !important;
			margin: 0 10px;
			background-color: transparent !important;
			color: $dinova-green !important;
			font-weight: 600;
			text-transform: none;

			&:active {
				border: none !important;
				background-color: transparent !important;
				color: $dinova-green !important;
			}
		}


	}
}

// mobile only
@media screen and (max-width: 767px) {
	.business-search-filter-wrapper {
		position: fixed;
		z-index: 11;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		height: -webkit-fill-available;
		max-height: 100vh;
		max-height: -webkit-fill-available;
		transition: top ease .3s;

		// .business-search-filter-wrapper {

		// }
	}

	.business-search-filter-wrapper.slide-up {
		top: initial !important;
		bottom: 100%;
		overflow: hidden;
	}
}

@media screen and (min-width: 768px) {
	.search-top-bar .inner-wrapper .bottom-bar {
		padding: 0px !important;
		margin-top: 15px;

		.text.sm.bold.text-none {
			padding: 0px !important;
		}
	}

	.business-search-filter-wrapper {
		z-index: 10;
		// background-color: $accordion-title-grey;

		.business-search-filter {
			.filter-header {
				display: flex;
				flex-wrap: nowrap;
				align-items: center;
				// background-color: $accordion-title-grey;

				.text.sm.text-none {
					padding-right: 5px;
					padding-left: 5px;
					margin-right: 3px;

					@include fluid-type-default(12px, 16px);
				}
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.business-search-filter-wrapper {
		.business-search-filter {
			.filter-header {
				.text.sm.text-none {
					@include fluid-type-default(16px, 18px);

					&.normal {
						font-weight: 400;
					}
				}
			}
		}
	}
}
