.no-response-page {
	.plate-banner .banner-img {
		object-position: top;
	}

	.announcement {
		display: flex;
		flex-direction: column;
		align-items: center;

		.no-response-notice-msg {
			margin-bottom: 20px;
		}

		.msg-text {
			color: rgb(67, 65, 62);
			font-weight: 400;
			text-align: center;

			@include fluid-type-default(16px, 18px);

			&:last-child {
				margin-top: 10px;
			}
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		.announcement {
			.no-response-notice-msg {
				margin-bottom: 30px;
			}

			.msg-text:last-child {
				margin-top: 15px;
			}
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		.announcement {
			.no-response-notice-msg {
				margin-bottom: 40px;
			}

			.msg-text:last-child {
				margin-top: 25px;
			}
		}
	}
}
