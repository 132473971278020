.details-recommendations.options-list {
	display: flex;
	flex-wrap: wrap;

	.option-item {
		width: auto;
		min-width: 50%;
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 2;
		padding-left: 10px;
	}
}

@media screen and (min-width: 1024px) {
	.details-recommendations.options-list {
		.option-item {
			min-width: 100%;
		}
	}
}

.ie {
	.details-recommendations.options-list {
		.option-item {
			flex: none !important;
		}
	}
}
