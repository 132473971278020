.message-banner {
	padding: 1rem 15px;
	margin-bottom: 1rem;

	svg.fa-chevron-up {
		width: 20px;
		height: 20px;
		color: $adminDining_white;
		cursor: pointer;
	}

	> div:nth-of-type(1) {
		svg {
			transition: height 150ms linear, width 150ms linear;
		}
	}

	> div:nth-of-type(2) {
		overflow: hidden;
		transition: height 300ms linear;
	}

	&.red {
		background-color: $dinova-red !important;

		p.sm.text {
			button.btn.btn-secondary {
				color: $dinova-red !important;
			}
		}
	}

	&.green {
		background-color: $covid-green !important;

		p.sm.text {
			button.btn.btn-secondary {
				color: $covid-green !important;
			}
		}
	}

	p.text {
		margin-bottom: .5rem;
		color: $adminDining_white;

		&.lg {
			display: flex;
			align-items: center;
			font-size: 22px;
			font-weight: 600;
			transition: font-size 150ms linear;

			svg {
				width: 30px;
				height: 30px;
				margin-right: .75rem;
			}
		}

		&.sm {
			@include fluid-type-default(16px, 18px);

			display: flex;
			align-items: baseline;

			button.btn.btn-secondary {
				width: auto;
				padding: .5rem 2rem;
				border: none !important;
				border-bottom: solid 1px $adminDining_white !important;
				margin: .5rem 0 0 0;
				background-color: $adminDining_white !important;
				border-radius: 5px;
				font-weight: 400;
				text-transform: none;

				@include fluid-type-default(16px, 18px);
			}
		}
	}

	&.hide {
		> div:nth-of-type(1) {
			p.text.lg {
				align-items: center;
				margin-bottom: 0;
				font-size: 18px;
			}

			svg {
				width: 20px;
				height: 20px;
			}
		}

		> div:nth-of-type(2) {
			height: 0px !important;
		}

		svg.fa-chevron-up {
			transform: scaleY(-1);
		}
	}
}

// Tablet and up
@media screen and (min-width: 768px) {
	.message-banner {
		padding-right: 25px;
		padding-left: 25px;
	}
}
