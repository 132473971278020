// These settings will be only for one version

// Masks
.view {
  .full-bg-img {
    height: 100%;
  }
  .full-bg-img,
  .full-bg-img video {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}

// Full background
.full-height,
.full-height body,
.full-height header,
.full-height header .view {
  height: 100%;
}