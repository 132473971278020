.Di_MyDinovaLogo {
	width: 100%;
	margin-top: 20px;
	text-align: center;
}

.Di_LogoBoxWide {
	img {
		height: 50px;
		margin-top: 5px;
		margin-right: auto;
		margin-left: auto;
	}
}

@media (min-width: 768px) {
	.Di_LogoBoxWide {
		img {
			height: 60px;
			margin-top: 5px;
			margin-right: auto;
			margin-left: 25px;
		}
	}
}

@media (min-width: 1024px) {
	.Di_LogoBoxWideTall {
		padding-top: 20px;
	}

	.Di_LogoBoxWide {
		img {
			height: 70px;
			margin-top: 5px;
			margin-right: auto;
			margin-left: 25px;
		}
	}
}

.spinner {
	border: 4px solid rgba(0, 0, 0, 0.1);
	width: 36px;
	height: 36px;
	border-radius: 50%;
	border-left-color: #09f;
	animation: spin 1s ease infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}