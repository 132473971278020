// Default Button
.btn {
	box-shadow: none !important;
	transition: none !important;

	&.auto-width {
		width: auto;
	}
}

// Default Button
// Default Outlined Button
.btn-secondary,
.btn-outline-secondary {
	border: $button-border-width solid $dinova-green !important;
	background-color: $adminDining_white !important;
	box-shadow: none !important;
	color: $dinova-green !important;

	// Not Disabled
	&:not([disabled]):not(.disabled) {
		// Hover, Focus
		&:hover,
		&:focus {
			border-color: $dinova-green !important;
			-webkit-box-shadow: $button-hover-shadow;
			box-shadow: $button-hover-shadow;
			color: $dinova-green !important;
		}

		// Active
		&:active,
		&.active {
			border-color: $dinova-green !important;
			background-color: $dinova-green !important;
			-webkit-box-shadow: $button-hover-shadow;
			box-shadow: $button-hover-shadow;
			color: $adminDining_white !important;
		}
	}
}
