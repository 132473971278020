/* Medium Screen Page Limit */
@media screen and (min-width: 768px) {
	.Di_PageLimit {
		width: 600px;
		padding-right: 20px;
		padding-left: 20px;
	}

	.Di_PageLimit-LG {
		width: 760px;
		padding-right: 20px;
		padding-left: 20px;
	}

	.Di_PageLimit-XLG {
		// width: 760px;
		padding-right: 20px;
		padding-left: 20px;
	}

	.Di_PageLimit-XXLG {
		padding-right: 20px;
		padding-left: 20px;
	}
}
