@import './ie-style';

.Di_LogoBoxLeft {
	display: flex;
	flex: 1 0 0;
	flex-direction: column;
	align-items: center;

	img {
		height: 35px;
	}
}

.center {
	.Di_LogoBoxLeft {
		justify-content: center;
	}
}

@media (min-width: 768px) {
	.Di_LogoBoxLeft {
		.logo-link {
			img {
				height: 36px;
				margin-top: 0px;
				margin-left: 0;
			}
		}
	}
}

@media (min-width: 1024px) {
	.Di_LogoBoxWideTall {
		padding-top: 20px;
	}

	.Di_LogoBoxLeft {
		flex-direction: row;
		align-items: flex-end;
		justify-content: flex-start;

		.logo-link {
			img {
				height: 45px;
				margin-top: 5px;
			}
		}
	}
}
