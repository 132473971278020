// REMOVE ME
@import '../../../styles/common/vars/colors';

.input-container.checkbox-group {
	margin-top: 13px;

	&.error {
		&.single {
			input:not(:checked) + label.checkbox.sub {
				&:after {
					border-color: $danger-red;
					border-width: 3px;
				}
			}

			.text.input-error-text {
				margin-top: 13px;
			}
		}
	}

	&:not(.single) {
		.checkbox-options {
			margin-top: 13px;
		}
	}
}
