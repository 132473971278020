@media screen and (max-width: 768px) {
	.search-page.content-wrapper {
		&.show-filter {
			.main-content {
				overflow: hidden;
				height: 0px;
			}
		}
	}
}

.mobile {
	.search-page.content-wrapper {
		.main-content.show-list {
			height: auto;
		}
	}
}
