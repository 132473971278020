.search-modify {
	display: flex;
	align-items: center;

	.search-label {
		font-size: 20px;
		font-weight: bold;
	}

	.search-input-wrapper {
		position: relative;
		flex-grow: 1;

		.search-icon {
			position: absolute;
			z-index: 2;
			left: 12px;
			height: 40px;
		}

		.search-input {
			margin: 0;

			.form-control {
				height: 40px;
				box-sizing: border-box;
				padding: 0 12px 0 44px;
				border: 1px solid #6c6f70;
				margin: 0;
				border-radius: 5px;
				cursor: pointer;
				text-overflow: ellipsis;

				@include fluid-type-default(14px, 20px);

				&:focus {
					border: 1px solid #6c6f70 !important;
				}
			}
		}
	}

	.modify-btn {
		width: auto;
		height: 40px;
		padding: 0 20px;
		margin: 0;
		box-shadow: none;
		font-weight: 400;

		@include fluid-type-default(14px, 20px);
	}

	// for table and up
	@include media-breakpoint-up(md) {
		.search-input-wrapper {
			margin: 0 0 0 10px;

			.search-input .form-control {
				border-radius: 5px 0 0 5px;
			}
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		.search-input-wrapper {
			margin-right: 10px;

			.search-icon {
				display: none;
			}

			.search-input .form-control {
				padding: 0 12px;
				border-radius: 5px;
			}
		}
	}
}
