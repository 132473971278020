.card .left {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	.promo-banner {
		p {
			text-shadow: 1px 1px 1px #3f3f3f;
		}

		&:first-of-type {
			width: 100%;
			margin-bottom: .5em;
		}

		&:nth-of-type(2) {
			width: 40%;
			// margin: 0 2.5% 0 0;
		}

		&:nth-of-type(3) {
			width: 55%;
			// margin: 0 0 0 2.5%;
		}

		img {
			width: 100%;
			// margin: 0 2%;
		}
	}
}

.card.open .left {
	.promo-banner {
		p {
		}

		&:first-of-type {
		}

		&:nth-of-type(2) {
		}

		&:nth-of-type(3) {
		}

		img {
		}
	}
}
