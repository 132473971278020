.view-profile-section {
	padding: 1.25em 2.5vw;
	border-bottom: solid rgba(0, 0, 0, .125) 1px;

	.profile-preference-item {
		margin-bottom: .75em;
	}

	.list-header {
		display: flex;
		align-items: baseline;
		justify-content: space-between;
		margin-bottom: 15px;

		.link-wrapper {
			display: flex;
			align-items: center;

			.with-help {
				position: relative;
				display: flex;
				margin: 0 5px;

				.help-btn {
					position: static;
					width: unset;
					padding: 0px;

					.Di_SideHelpIcon {
						width: 20px;
						height: 20px;
					}
				}

				.help-panel-wrapper {
					position: absolute;
					z-index: 1;
					top: 20px;
					right: -14px;
					width: 400px;
					max-width: 80vw;
				}
			}
		}

		.title {
			margin-bottom: 0;
			font-size: 18px;
		}

		a {
			font-size: 14px;
			font-weight: 400;
			text-decoration: none;
		}
	}

	.profile-section-list {
		.profile-section-item {
			align-items: flex-end;
			margin-bottom: .75em;

			&.has-help {
				align-items: center;
			}

			&:last-child {
				margin-bottom: 0;
			}

			&.disabled > *:not(.with-help) {
				opacity: .3;
			}

			.title {
				font-size: 14px;
			}

			.item-value {
				font-size: 12px;
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.view-profile-section {
		.list-header {
			.title {
				font-size: 20px;
			}

			a {
				font-size: 16px;
			}
		}

		.profile-section-list {
			.profile-section-item {
				.title {
					font-size: 16px;
				}

				.item-value {
					font-size: 14px;
				}
			}
		}
	}
}
