.social-wrapper {
	flex-wrap: wrap;

	.social-icon {
		height: 55px;
		margin-right: 2em;
		margin-bottom: 1em;
	}
}

@media screen and (max-width: 1024px) {
	.social-wrapper {
		justify-content: center;

		.title {
			width: 100%;
			padding: .75rem 0;
			text-align: center !important;
		}

		a:last-child {
			.social-icon {
				margin-right: 0px;
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.social-wrapper {
		.social-icon {
			width: 40px;
		}
	}
}

@media screen and (min-width: 1024px) {
	.social-wrapper {
		h1.title {
			margin: 0;
		}
	}
}
