@media screen and (min-width: 768px) {
	label.radio.sub {
		&:before {
			width: 30px;
			height: 30px;
		}

		&:after {
			width: 20px;
			height: 20px;
			margin: 5px;
		}
	}

	.inline {
		> div {
			.radio-option-wrapper {
				margin-right: 10px;
				margin-bottom: 0px;
			}
		}
	}
}
