@import './input-mods.scss';
@import './style-mv.scss';
@import './style-lv.scss';
@import './style-sv.scss';
@import './ie_style.scss';

.search-page.content-wrapper {
	max-width: 100%;
	margin: 0 auto;

	.header {
		position: relative;
		display: flex;

		.back-link {
			position: absolute;
			top: -4px;
			left: 13px;
			font-weight: 400;
		}

		.title {
			width: 100%;
		}
	}

	.main-content-wrapper {
		.list,
		.map {
			transition: width ease .3s, transform ease .3s;
		}

		.main-content.loading {
			// opacity: .6;
		}
	}
}

// mobile only
@media screen and (max-width: 767px) {
	.search-page.content-wrapper {
		overflow: hidden;
		width: 100vw;

		.main-content {
			display: flex;
			width: 200vw;
			height: 90vh;
			transform: translateX(-100vw);
			transition: transform ease .3s, height linear 0s;

			.list,
			.map {
				width: 100vw;
			}

			&.show-list {
				// .5rem to account for list padding
				transform: translateX(calc(2vw - .5rem));
			}

			.no-results {
				display: flex;
				width: calc(100vw - 20px);
				height: 150px;
				flex-direction: column;
				justify-content: center;
				margin: 0 10px;
			}
		}

		&.show-filter {
			.main-content {
				transition: transform ease .3s, height linear 1s;
			}
		}
	}
}

// tablet and up
@media screen and (min-width: 768px) {
	.search-page.content-wrapper {
		max-width: 1440px;

		.header {
			.back-link {
				left: 25px;
			}
		}

		.input-wrapper.error .typeahead.no-label.use-placholder input {
			padding-left: 10px !important;
			color: $danger-red;

			&::placeholder {
				color: $danger-red;
			}
		}

		.input-wrapper.error.focused.has-recent.open {
			.input-footer {
				.suggestions {
					border-color: $danger-red;
				}
			}
		}
	}
}

// Desktop and up
@media screen and (min-width: 1024px) {
	.search-page.content-wrapper {
	}
}

@media screen and (min-width: 1440px) {
	.search-page.content-wrapper {
		.main-content-wrapper {
			margin: 0px;
		}
	}
}
