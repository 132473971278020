.btn.area-search-btn {
	position: absolute;
	top: 0;
	left: 50%;
	width: auto;
	padding: 0 20px;
	border: none !important;
	margin: 20px 0 0;
	font-weight: bold;
	line-height: 40px;
	opacity: .8;
	transform: translateX(-50%);
	white-space: nowrap;

	@include fluid-type-default(14px, 20px);

	&:hover {
		opacity: 1;
	}
}
