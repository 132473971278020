.filter-title {
	p.text {
		@include fluid-type-default(20px, 22px);
	}

	button.btn {
		@include fluid-type-default(12px, 14px);
	}
}

.active-filter-bar {
	.active-filter-list-wrapper {
		p.text {
			color: $required-red;
			line-height: 1.2;

			@include fluid-type-default(12px, 14px);
		}
	}
}
