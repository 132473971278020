.search-types.nav-tabs {
	flex-wrap: nowrap;
	justify-content: space-around;
	padding: 0;
	margin: 0;
	background: radial-gradient(ellipse at center, rgba(0, 0, 0, .4) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
	box-shadow: none;

	.nav-item {
		display: flex;
		flex: 1 0 0;
		align-items: center;
		justify-content: center;
		background-color: rgba(225, 225, 225, .6);

		.nav-link {
			display: flex;
			width: 100%;
			height: 100%;
			align-items: center;
			justify-content: center;
			padding: 8px 0 4px;
			border-radius: 0px;
			color: rgba(0, 0, 0, .7);
			font-size: 14px;
			font-weight: bold;
			line-height: 18px;
			text-align: center;
			text-transform: uppercase;
			transition: none;
			word-spacing: 100vw;

			&:hover {
				color: #fff;
			}
		}

		&.active {
			background-color: #fff;

			.nav-link {
				color: #000;
			}
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		flex-wrap: wrap;
		justify-content: flex-start;

		.nav-item {
			display: list-item;
			flex: none;
			margin: 0;

			&:first-child {
				margin: 0;
			}

			.nav-link {
				width: 200px;
				padding: 9px 0 6px;
				font-size: 16px;
				word-spacing: normal;
			}
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		.nav-item {
			.nav-link {
				width: 250px;
				padding: 11px 0px 15px;
			}
		}
	}
}
