// Miscellaneous
// Edge Headers
.edge-header {
  display: block;
  height: $edge-header-height;
  background-color: $edge-header-background-color;
}
.free-bird {
  margin-top: $edge-header-margin-top;
}
