// Dropdown pro
// Dropdowns menu's colors
.dropdown,
.dropup,
.dropleft,
.dropright {
	.label {
		font-weight: 300;
	}

	.dropdown-menu {
		padding: $dropdown-menu-padding;

		@each $name, $color in $dropdown-colors {
			&.dropdown-#{$name} {
				.dropdown-item {
					&:hover,
					&:active {
						background-color: $color !important;
					}
				}
			}
		}

		.dropdown-item {
			padding: $dropdown-menu-padding;
			margin-left: 0;
			font-size: $dropdown-item-font-size;

			&.disabled {
				color: $dropdown-item-disabled-color;

				&:hover,
				&:active,
				&:focus {
					background-color: transparent !important;
					box-shadow: none;
					color: $dropdown-item-disabled-color !important;
				}
			}

			&:hover,
			&:active {
				background-color: $primary-color;
				border-radius: $border-radius-base;
				box-shadow: $z-depth-1-half;
				transition: $dropdown-item-transition;

				@extend .white-text;
			}
		}
	}
}

.navbar-nav {
	.dropdown-menu-right {
		right: 0;
		left: auto;
	}
}

.dropdown-menu {
	&.animated {
		/* Speed up animations */
		animation-duration: $dropdown-menu-animated-animation-duration;
		animation-timing-function: $dropdown-menu-animated-animation-timing-function;
	}
}
