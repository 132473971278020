@media screen and (min-width: 768px) {
	.input-container {
		.input {
			height: 62px;
			padding: 18px 20px;

			&:focus,
			&.focused {
				padding: 18px;
			}

			&.error {
				padding: 18px;
			}
		}

		&.error {
			.input {
				&.error {
					padding-left: 70px;
				}
			}
		}
	}
}
