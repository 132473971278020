$list-expanded-background-dark: #dcdcdc; // confirmed - list background light
$pale-dinova-green: #edf5ed;

.userInfo {
  border: solid $list-expanded-background-dark 1px;
  border-radius: 12px;
  padding: 12px 16px;
  width: 100%;
}

.avatar {
  height: 50px;
  width: 50px;
  border-radius: 12px;
  background-color: $pale-dinova-green;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
}