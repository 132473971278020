.company-select.open {
	input.input.typeahead.focused.has-value.text {
		border-radius: 3px;
	}

	&.has-suggestions,
	&.loading {
		input.input.typeahead.focused.has-value.text {
			border-radius: 3px 3px 0 0;
		}
	}
}

.company-select-wrapper {
	display: flex;

	form {
		display: flex;
	}

	.no-suggestions {
		min-height: 46px;
	}

	.input-wrapper .suggestions,
	.input-wrapper .no-suggestions {
		left: 0px;
	}
}
