.details-page {
	.content {
		margin-top: 0px;
	}

	// .links,
	// .details-header-section + .content {
	// 	margin-right: 10px;
	// 	margin-left: 10px;
	// }

	.links {
		min-height: 48px;
	}

	.accordion .accordion__item .accordion__title {
		cursor: default !important;
	}
}

// Text under logo image
.details-jumbo-keywords {
	margin-bottom: 0px;
	font-weight: 300;

	@include fluid-type-default(16px, 20px);
}

@media screen and (min-width: 768px) {
	.details-page {
		.links {
			margin-right: 20px;
			margin-left: 20px;
		}

		.details-header-section + .content {
			margin-right: 20px;
			margin-left: 20px;
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
	.details-page.about {
		.content {
			max-width: 768px;
			margin: 0 auto;
		}
	}
}

@media screen and (min-width: 1024px) {
	.details-page {
		.links {
			margin-right: 25px;
			margin-left: 25px;
		}

		.details-header-section + .content {
			margin-top: 13px;
			margin-right: 25px;
			margin-left: 25px;
		}

		.tab-panel-header.details {
			max-width: calc(100% / 12 * 8); // col 8
		}
	}
}
