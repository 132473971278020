.recommendations-page {
	.body {
		max-width: 940px !important;
	}

	.date-paginator {
		margin-top: 2.5em;
		margin-bottom: 1em;
	}

	.no-activity {
		padding: 30px 0 0;
	}
}
