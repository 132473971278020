@import '../../vars/colors';
@import './registration_form';
// Default Radio Button
.form-check-input[type='radio'] {
	// Label
	+ label {
		padding-left: 43px;
		font-size: 1.125em;
		font-weight: 300;
		line-height: 2.57rem;

		&:before,
		&:after {
			width: 31px;
			height: 31px;
		}
	}

	// Checked
	&:checked {
		+ label:after {
			background-color: $dinova-green;
			border-color: $dinova-green;
		}
	}

	// With Gap, With Gap Checked
	&.with-gap,
	&.with-gap:checked {
		+ label {
			&:before {
				background: $adminDining_white;
				background: -moz-linear-gradient(45deg, $adminDining_white 0%, $input-checked-background-grey 100%);
				background: -webkit-linear-gradient(45deg, $adminDining_white 0%, $input-checked-background-grey 100%);
				background: linear-gradient(45deg, $adminDining_white 0%, $input-checked-background-grey 100%);
				border-color: $input-border-grey;
				filter: progid:dximagetransform.microsoft.gradient(startColorstr=#{$adminDining_white}, endColorstr=#{$input-checked-background-grey}, GradientType=1);
			}

			&:after {
				transform: scale(.64);
			}
		}
	}
}
