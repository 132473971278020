.registration-steps {
	.step-indicators {
		position: relative;
		display: flex;
		max-width: 100%;
		justify-content: space-between;
		margin: 0 auto;
		margin-bottom: 6.66666667vw;

		&:before {
			position: absolute;
			top: calc(6.66666667vw / 2 - (.666666667vw / 2));
			left: 17%;
			display: block;
			width: 67%;
			height: .666666667vw;
			background-color: $foggy-grey;
			content: '';
		}

		// for table and up
		@include media-breakpoint-up(md) {
			max-width: 100%;
			margin-bottom: 5.20833333vw;

			&:before {
				top: calc(5.21512386vw / 2 - (.277777778vw / 2));
			}
		}

		// for desktop and up
		@include media-breakpoint-up(lg) {
			max-width: 100%;
			margin-bottom: 2.91666667vw;
			margin-bottom: 42px;

			&:before {
				// height of step-number / 2 - height of bar / 2
				top: calc((44px / 2) - 2px);
				height: .277777778vw;
			}
		}
	}

	.step-indicator:last-child .count:after {
		width: 0;
	}
}
