@media screen and (min-width: 768px) {
	.select.dropdown {
		&.error {
			.label-wrapper {
				padding: 16px 18px 13px 70px;
			}

			&.has-value {
				.label-wrapper {
					padding: 19px 18px 13px 70px;
				}

				.label {
					margin-left: -51px;
				}
			}
		}
	}
}
