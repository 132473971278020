.export-button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 12px;
	border: solid 1px $dinova_green;
	margin-left: 20px;
	border-radius: 4px;
	color: $dinova_green;

	span.MuiIcon-root {
		margin-left: 8px;
	}
}

.MuiInput-underline:after {
	border-color: $dinova_green !important;
}

.MuiTouchRipple-root {
	display: none;
}

.MuiButtonBase-root:hover {
	background-color: transparent !important;
}

.MuiPaper-root {
	box-shadow: none !important;

	.MuiToolbar-root {
		// Table Header
		[class*='MTableToolbar-title'] {
			h6 {
				@include fluid-type-default(16px, 18px);

				font-weight: 400;
			}
		}

		// Export Button
		[class*='MTableToolbar-actions'] {
			.MuiButtonBase-root {
				.MuiIconButton-label {
					span {
						color: $dinova_green;
						font-weight: 400;
						text-transform: none;

						@include fluid-type-default(12px, 14px);
					}

					span.MuiIcon-root {
						@include fluid-type-default(16px, 18px);
					}
				}
			}
		}
	}

	// Search Input
	[class*='MTableToolbar-searchField'] {
		.MuiInputBase-root {
			padding: 2px 0px;

			input.MuiInputBase-input {
				@include fluid-type-default(12px, 14px);
			}
		}

		.MuiInput-underline:after,
		.MuiInput-underline:before {
			// border-color: rgb(230, 231, 231);
		}

		.MuiIcon-fontSizeSmall {
			// color: $dinova_green;

			@include fluid-type-default(16px, 18px);
		}
	}

	// Table Header
	.MuiTableRow-head {
		// Table Header Cell
		.MuiTableCell-root {
			padding: 16px 16px 4px;
			border-color: rgb(230, 231, 231);
			font-size: calc(12px + 2 * ((100vw - 0px) / 1440)) !important;
			font-weight: 500 !important;
		}
	}

	// Table Body
	.MuiTableBody-root {
		// Table Body Cell
		.MuiTableCell-root {
			border-color: rgb(230, 231, 231);
			font-size: calc(12px + 2 * ((100vw - 0px) / 1440)) !important;
			font-weight: 400 !important;
			white-space: nowrap;

			.image-col {
				display: flex;
				align-items: center;

				img {
					width: 20px;
					height: 20px;
					margin-right: 5px;
				}
			}
		}
	}

	// Table Footer
	.MuiTableCell-footer {
		border-color: transparent;
	}

	// Pagination
	[class*='MTablePaginationInner-root'] {
		// Pagination button
		.MuiButtonBase-root:not(.Mui-disabled) {
			color: $dinova_green;
		}

		.MuiTypography-root.MuiTypography-caption {
			@include fluid-type-default(12px, 14px);
		}

		// Pagination button - disabled
		.MuiButton-contained.Mui-disabled {
			padding: 5px;
			background-color: rgb(86, 86, 83);
			border-radius: 0;
			color: $adminDining_white;
		}
	}

	// Table Row Count Selector
	.MuiTablePagination-input.MuiTablePagination-selectRoot {
		display: flex;
		align-items: center;

		div {
			display: flex;
			align-items: center;

			@include fluid-type-default(12px, 14px);
		}

		.MuiSelect-icon {
			top: calc(50%);
			font-size: 24px;
			transform: translate(0, -50%);
		}
	}
}

.MuiMenuItem-root.MuiTablePagination-menuItem {
	@include fluid-type-default(12px, 12px);
}

.MuiList-root.MuiMenu-list.MuiList-padding {
	border: solid 1px $dark-grey;
}
