// Buttons
.btn {
  box-shadow: $z-depth-1;
  @include button-size($btn-padding-y-basic, $btn-padding-x-basic, $btn-font-size-basic);
  transition: $btn-transition;
  margin: $btn-margin-basic;
  border: 0;
  border-radius: $border-radius-base;
  cursor: pointer;
  text-transform: uppercase;
  white-space: normal;
  word-wrap: break-word;
  color: $btn-color-basic;
  &:hover,
  &:active,
  &:focus {
    box-shadow: $z-depth-1-half;
    outline: 0;
  }
  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    box-shadow: $z-depth-1-half;
  }
  .fa {
    position: relative;
    font-size: $btn-icon-basic;
    &.right {
      margin-left: $btn-icon-margin;
    }
    &.left {
      margin-right: $btn-icon-margin;
    }
  }
  &.btn-lg {
    @include button-size($btn-padding-y-large, $btn-padding-x-large, $btn-font-size-large);
    .fa {
      font-size: $btn-icon-large;
    }
  }
  &.btn-md {
    @include button-size($btn-padding-y-medium, $btn-padding-x-medium, $btn-font-size-medium);
    .fa {
      font-size: $btn-icon-medium;
    }
  }
  &.btn-sm {
    @include button-size($btn-padding-y-small, $btn-padding-x-small, $btn-font-size-small);
    .fa {
      font-size: $btn-icon-small;
    }
  }
  &.btn-tb {
    padding: $btn-tb-padding-y $btn-tb-padding-x;
  }
  &.disabled,
  &:disabled {
    &:active,
    &:focus,
    &:hover {
      box-shadow: $z-depth-1;
    }
  }
  &.btn-block {
    margin: inherit;
  }
  &.btn-link {
    @extend .black-text;
    box-shadow: none;
    background-color: transparent;
    &:active,
    &:focus,
    &:hover {
      box-shadow: none !important;
      background-color: transparent;
    }
  }
  &[class*="btn-outline-"] {
    padding-top: $btn-outline-padding-y-basic;
    padding-bottom: $btn-outline-padding-y-basic;
    &.btn-lg {
      padding-top: $btn-outline-padding-y-large;
      padding-bottom: $btn-outline-padding-y-large;
    }
    &.btn-md {
      padding-top: $btn-outline-padding-y-medium;
      padding-bottom: $btn-outline-padding-y-medium;
    }
    &.btn-sm {
      padding-top: $btn-outline-padding-y-small;
      padding-bottom: $btn-outline-padding-y-small;
    }
  }
}
.btn-group {
  .btn {
    margin: 0;
  }
}
// Overwrite default button icon size
.btn {
  .fa-lg {font-size: 1.33333333em !important;}
  .fa-2x {font-size: 2em !important;}
  .fa-3x {font-size: 3em !important;}
  .fa-4x {font-size: 4em !important;}
  .fa-5x {font-size: 5em !important;}
}
@each $btn_name, $color_value in $mdb-colors {
  @include make-button($btn_name, $color_value);
  @include make-outline-button($btn_name, $color_value);
}

// button,
// html [type="file"] {
//   -webkit-appearance: button; 
//   appearance: button;
// }

// button::-moz-focus-inner,
// [type="file"]::-moz-focus-inner {
//   padding: 0;
//   border-style: none;
// }