.transactions-page {
	.date-paginator {
		margin-bottom: 1em;
	}
}

@media (min-width: 1024px) {
	.transactions-page {
		.dashboard-connect-message {
			div.content {
				display: flex;
				flex-direction: row;
				align-items: flex-end;
				justify-content: unset;

				p.text.d-block {
					margin-right: 15px;
					margin-bottom: 0px;
					text-align: left;
				}

				a.btn.btn-primary {
					width: 100%;
					padding-right: 20px;
					padding-left: 20px;
					margin-right: unset;
					margin-left: unset;
				}
			}
		}
	}
}
