@media screen and (min-width: 1024px) {
	.dashboard-connect-message {
		padding: 25px 20px;
		margin-top: 41px;

		div.content {
			.title.text-normal {
				@include fluid-type-default(16px, 20px);
			}

			.text.d-block {
				@include fluid-type-default(14px, 18px);
			}

			a.btn.btn-primary {
				width: 100%;

				@include fluid-type-default(16px, 18px);
			}
		}
	}
}
