.incentive {
	p.text {
		margin: 0 0 0px;

		span {
			@include fluid-type-default(12px, 14px);
		}

		&.xs.normal.text-none {
			@include fluid-type-default(12px, 14px);
		}
	}
}

@media screen and (min-width: 768px) {
	.incentive {
		p.text {
			margin: 0;
		}
	}

	// history page
	.recommendations.transactions {
		.incentive {
			margin-top: 0px;
		}
	}
}

@media screen and (min-width: 1024px) {
	.incentive {
		p.text {
			margin: 0 0 5px;

			span {
				@include fluid-type-default(14px, 16px);
			}

			&.xs.normal.text-none {
				@include fluid-type-default(14px, 16px);
			}
		}
	}
}
