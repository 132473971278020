@media screen and (max-width: 1024px) {
	.details-about {
		.accordion {
			.accordion__item {
				.accordion__title {
					border-width: 3px 0 0 0;
					background-color: $adminDining_white;

					.section-title {
						@include fluid-type-default(16px, 22px);

						padding: .75rem 0 !important;
						border-bottom: none !important;
					}
				}
			}

			.section-body {
				padding-top: 0px;
			}



			&[class*='Location Safety Measures | COVID-19'] {
				.accordion__item {
					.section-body {
						padding: 0px;
					}
				}
			}

			&[class*='Address and Directions'] {
				.accordion__item {
					.section-body {
						padding-top: 0;
						padding-right: 0;
						padding-left: 0;

						.map-info {
							padding-right: .75em;
							padding-left: .75em;
						}
					}
				}
			}

			&[class*='Recommended'] {
				.accordion__title {
					background-color: $pale-dinova-green !important;
				}

				.section-body {
					padding-top: .75em;
				}

				.accordion__item {
					border-bottom: none;

					h1.title {
						@include fluid-type-default(16px, 16px);

						margin-bottom: 0px;
					}
				}
			}

		}

		.accordion.Amenities,
		.accordion[class*='Recommended'],
		.accordion[class*='Dietary Preferences'] {
			.accordion__item {
				.accordion__title .section-title,
				.section-body {
					padding-left: 2rem !important;
				}
			}
		}

	}
}

@media screen and (max-width: 768px) {
	.accordion[class*='Description'] {
		.accordion__item .accordion__title .section-title {
			padding: 2rem 0 !important;
		}
	}
}

.details-about {
	margin-top: -.5em;

	.section-body {
		padding: .75em;
	}

	// Both columns
	.row > .col-md-6 {
		padding-left: 0;
	}

	// Second column
	.row > .col-md-6:nth-child(2) {
		padding-right: 0;
		padding-left: 15px;
	}

	.details-location {
		.section-body {
			padding: 0;
		}
	}

	// Details section
	.row .col-md-6:first-child .accordion:first-child .section-body {
		padding-left: 0px;
	}

	.options-list {
		align-items: flex-start;
		justify-content: space-between;

		.option-item {
			width: 49%;
			max-width: 49%;
			flex: unset;

			p.text {
				white-space: normal;

				@include fluid-type-default(13px, 16px);
			}

			&:before {
				width: 20px;
				height: 20px;
			}
		}
	}

	.keywords-section {
		margin-top: 3em;
	}
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
	.details-about {
		.accordion[class*='Recommended'],
		.accordion.Amenities,
		.accordion[class*='Dietary Preferences'],
		.accordion[class*='Follow'],
		.accordion[class*='Description'] {
			.section-body {
				padding-top: 1em;
				padding-bottom: 1em;
			}
		}

		.accordion[class*='Follow'],
		.accordion[class*='Description'] {
			.section-body {
				max-width: 90%;
				margin-right: auto;
				margin-left: auto;
			}
		}

		.accordion[class*='Description'] {
			.accordion__item .accordion__title .section-title {
				padding: 2rem 0 .75rem !important;
			}
		}

		.accordion[class*='Recommended'] {
			.accordion__item .section-body {
				.title {
					@include fluid-type-default(12px, 18px);

					margin-bottom: 0px;
				}
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.details-about {
		.accordion {
			margin: 0;

			.accordion__item {
				padding-bottom: .75em;
				border-bottom: solid $input-border-grey 1px;

				.accordion__title {
					border-width: 0;
					background-color: transparent;
				}

				.section-body {
					padding: 0 0 0 0vw;

					.options-list {
						margin-left: calc(.5em + 5px);

						&.covid {
							display: block;
							margin-left: 0;

							.option-item {
								width: unset;
								width: 100%;
								max-width: 100%;
								color: $black-text;
							}
						}
					}
				}
			}
		}

		.accordion.Description {
			.accordion__item {
				border: none;
			}
		}

		.accordion[class*='Recommended'] {
			border: solid $pale-dinova-green 2px !important;

			.accordion__item {
				border-bottom: none;

				h1.title {
					@include fluid-type-default(16px, 16px);

					margin-bottom: 0px;
				}

				.section-body {
					padding: .75em;

					.options-list {
						margin-left: 5px;
					}
				}
			}
		}

		.accordion[class*='Location Safety Measures | COVID-19'] {
			.accordion__item {
				border-bottom: none;
			}
		}
	}
}
