.collapsible-panel {
	position: relative;
	overflow-y: visible;
	max-height: 100%;
	transition: width 300ms linear, padding 300ms linear;

	> div {
		overflow-x: hidden;
		overflow-y: hidden;
		height: 100%;
		max-height: 100%;

		> div {
			overflow-y: scroll;
			height: 100%;
			max-height: 100%;
		}
	}

	.toggle {
		position: absolute;
		z-index: 100;
		top: 10px;
		right: -40px;
		display: flex;
		width: 40px;
		height: 40px;
		align-content: center;
		justify-content: center;
		background-color: $accordion-title-grey;
		cursor: pointer;

		svg {
			height: 100%;
			font-size: 2rem;
			transition: transform 300ms linear;
		}
	}

	&.closed {
		width: 0vw !important;

		.toggle {
			svg {
				transform: scaleX(-1);
			}
		}
	}
}
