/* stylelint-disable block-no-empty, no-descending-specificity */

.search-modal-interior {
	margin: 0 auto;
	background-color: rgb(66, 65, 66);
	outline: 0 none !important;

	.form-group {
		position: relative;
		display: flex;
		overflow: hidden;
		align-items: center;
		background-color: #fff;
		border-radius: 5px;

		&.error {
			border: 3px solid red;
			margin-bottom: 0;
			border-radius: 10px 10px 0 0;

			.location {
				border-bottom-left-radius: 0;
			}

			.btn-submit {
				border-bottom-right-radius: 0;
			}
		}
	}

	.error-message {
		padding: 1.5em;
		border: 3px solid red;
		border-top: 0;
		margin-bottom: 10px;
		background: #fff;
		border-radius: 0 0 5px 5px;
		color: red;
		font-size: 4.13793103vw;
		font-weight: 400;
		line-height: 1.3;

		// Medium devices (tablets, 768px and up)
		@include media-breakpoint-up(md) {
			font-size: 24px;
		}
	}

	label {
		width: 85px;
		flex-shrink: 0;
		align-items: center;
		margin: 0;
		color: #000;
		font-weight: bold;
		text-align: center;

		@include fluid-type-default(14px, 24px);

		&:after {
			display: block;
			width: 2px;
			height: 100%;
			border-right: 2px solid $dnaGray;
		}
	}

	.suggestions,
	.recent-search {
		flex-direction: column;

		a,
		.dropdown-item {
			border: 1px solid #c7c8c8;
			margin-bottom: .833333333vw;
			background-color: $dnaGrayLight;
			background-image: url(#{$imageBaseUrl}/icons/icon-search-inactive-ios.svg);
			background-position: 97% center;
			background-repeat: no-repeat;
			background-size: 4.13793103vw 100%;
			border-radius: 5px;
			color: rgba(0, 0, 0, .8);
			font-size: 4.13793103vw;

			// Medium devices (tablets, 768px and up)
			@include media-breakpoint-up(md) {
				margin-bottom: 10px;
				background-size: 24px 100%;
				font-size: 24px;
			}
		}
	}

	.recent-search,
	.toggle-selection {
		width: 100%;
		border-radius: 5px;
		text-align: left;

		.dropdown-item {
			background-image: none;
		}
	}

	.recent-search {
		position: relative;

		.toggle-selection {
			position: relative;
			height: 50px;
			padding: 0 25px;
			border: 1px solid #fff;
			margin: 0;
			background-color: $dnaGrayLight !important;
			background-position: 97% center;
			background-size: 4.13793103vw 100%;
			color: rgba(#000, .9) !important;
			line-height: 50px;
			text-transform: none;

			@include fluid-type-default(14px, 24px);

			&:after {
				position: absolute;
				top: 0;
				right: 3%;
				display: block;
				width: 24px;
				height: 48px;
				background-image: url(#{$imageBaseUrl}/icons/chevron.svg);
				background-position: center;
				background-repeat: no-repeat;
				content: '';
				transform: rotate(180deg);
			}

			&.focus,
			&:focus {
				box-shadow: none;
			}

			&:not([disabled]):not(.disabled):active,
			&:not([disabled]):not(.disabled).active {
				background-color: $dnaGrayLight !important;
			}
		}

		&.show .toggle-selection {
			border: 1px solid #c7c8c8;
			border-radius: 5px 5px 0 0;

			&:after {
				transform: rotate(0deg);
			}
		}

		.nav-item {
			width: 100%;
		}

		.dropdown-menu {
			position: relative !important;
			width: 100%;
			padding: 0 0 25px;
			border: none;
			background-color: rgb(66, 65, 66);
			transform: none !important;

			li > a {
				background-color: #868686;
				border-color: #5c5d5d;
				color: #fff;
			}

			.dropdown-item {
				height: 50px;
				padding: 0 40px;
				border: 1px solid rgb(122, 124, 125);
				margin: 0;
				background-color: #fff;
				border-radius: 0;
				color: #000;
				cursor: pointer;
				line-height: 50px;

				&:nth-child(odd) {
					background-color: rgb(182, 182, 182);
					color: #fff;
				}

				&:focus,
				&:hover {
					box-shadow: none;

					&:nth-child(even) {
						color: inherit !important;
					}
				}
			}
		}
	}

	input[type='text'] {
		height: 50px;
		padding: 0 10px;
		border: none;
		border-left: 1px solid #b6b6b6;
		border-radius: 0;
		box-shadow: none;
		caret-color: grey;
		outline: none;

		@include fluid-type-default(14px, 24px);

		&.location {
			width: 0;
			flex-grow: 1;
			border-right: none;
			background-size: .8em;
		}
	}

	.btn-location {
		width: 20px;
		height: 50px;
		flex-shrink: 0;
		margin-right: 10px;

		&:hover {
			background-color: #fff;
			border-color: transparent;
			cursor: pointer;
			text-shadow: none;
		}
	}

	.btn-submit {
		max-width: 95px;
		height: 50px;
		flex-basis: 65px;
		padding: 0;
		border: none;
		margin: 0;
		background-color: rgb(25, 145, 25) !important;
		border-bottom-right-radius: 5px;
		border-top-right-radius: 5px;
		box-shadow: none;
		color: #fff;
		cursor: pointer;
		font-size: 18px !important;
		line-height: 50px;
		text-transform: none;

		&:focus {
			box-shadow: none;
		}

		&:not([disabled]):not(.disabled):active,
		&:not([disabled]):not(.disabled).active {
			background-color: rgb(25, 145, 25) !important;
			box-shadow: none;
		}
	}

	.section-title {
		margin: 25px 0 12px;
		color: #fff;
		font-weight: 400;
		text-transform: none;

		@include fluid-type-default(16px, 26px);
	}

	form {
		display: flex;
		flex-direction: column;
		outline: 0 none !important;
	}

	.suggestions {
		.nav-item {
			cursor: pointer;

			.nav-link {
				height: 50px;
				padding: 0 25px;
				border: none;
				color: rgba(#000, .7);
				font-weight: 400;
				line-height: 50px;

				@include fluid-type-default(14px, 24px);
			}
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		.btn-submit {
			flex-basis: 95px;
		}
	}
}
