.input-wrapper {
	.file-input-wrapper {
		position: relative;

		button.label {
			padding: 0 !important;
			border: none !important;
			margin: 0 2rem 0 0 !important;

			label {
				width: 100%;
				height: 100%;
				padding: 1rem 2.4rem;
				border: solid $dinova-green 2px;
			}
		}

		p.text {
			white-space: nowrap;
		}

		svg {
			position: absolute;
			top: calc(.5rem + 1px);
			left: 0;
			width: 24px;
			height: 24px;
			fill: $danger-red;
		}
	}

	&.error {
		.file-input-container {
			padding-left: 30px;
		}
	}
}
