@media screen and (min-width: 768px) and (max-width: 903px) {
	.ie {
		[class*='input-wrapper'].hasError + .search-btn-wrapper {
			button.search-btn {
				margin-bottom: 47px;
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.ie {
		[class*='input-wrapper'].hasError + .search-btn-wrapper {
			button.search-btn {
				margin-bottom: 47px;
			}
		}
	}
}
// IE specific styles
.ie {
	.search-group {
		[class*='input-wrapper'] {
			display: inline !important;

			@media screen and (max-width: 767px) {
				-ms-flex: none !important;
				flex: none !important;
			}
		}

		.search-btn-wrapper {
			.search-btn {
				@media screen and (min-width: 1024px) {
					height: 44px !important;
					min-height: 44px !important;
					max-height: 44px !important;
					padding: 5px 0;
				}
			}
		}
	}

	.catering-search-tab {
		.search-group {
			[class*='input-wrapper'] {
				@media screen and (min-width: 767px) {
					// -ms-flex: none !important;
					flex: 1 1 auto !important;

					&:first-child {
						.dropdown {
							height: auto !important;
						}
					}

					&:nth-child(3) {
						.calendar-dropdown {
							height: auto !important;
						}
					}
				}
			}

			@media screen and (min-width: 768px) {
				.search-btn-wrapper {
					.search-btn {
						min-height: 39px !important;
					}
				}
			}
		}
	}

	.search-tab .search-group {
		@media screen and (max-width: 1024px) {
			.search-dropdown .form-control {
				height: 44px;
				padding: 3px 15px;
			}
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		.search-group {
			[class*='input-wrapper'] {
				.form-control {
					min-height: 44px;
					max-height: 44px;
					padding: 3px 15px !important;
				}
			}

			.search-btn-wrapper {
				.search-btn {
					padding: 5px 0 !important;
				}
			}
		}
	}
}
