.count {
	position: relative;
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;

	&:after {
		position: absolute;
		// height of step-number / 2 - height of bar / 2
		top: calc(6.66666667vw / 2 - (.666666667vw / 2));
		left: 50%;
		width: 33%;
		height: .666666667vw;
		background-color: $orange-peel;
		content: '';
		transition: width .25s ease-out;
		transition-delay: .5s;
	}

	&.completed:after {
		width: 100%;
		background-color: $dinova-green;
		transition-delay: 0;
	}

	&.incomplete:after {
		width: 0;
		background-color: transparent;
	}
}

.step-number {
	position: relative;
	z-index: 1;
	display: flex;
	width: 6.66666667vw;
	height: 6.66666667vw;
	align-items: center;
	justify-content: center;
	background-color: $foggy-grey;
	border-radius: 50%;
	font-size: 4vw;
	font-weight: 400;
	transition: background-color 1s ease-out;

	&.current {
		background-color: $orange-peel;
	}
}

.active .step-number {
	background-color: $orange-peel;
	font-weight: 600;
}

.completed .step-number {
	background-color: $dinova-green;
	background-image: url('#{$cdn-basePath}/icons/icon-checkmark-white.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 1.2em;
	font-weight: 600;
	text-indent: -9999vw;
}

.stepcount {
	&:last-child .count:after {
		width: 0;
	}
}

@media screen and (min-width: 768px) {
	.count:after {
		// height of step-number / 2 - height of bar / 2
		top: calc(5.21512386vw / 2 - (.277777778vw / 2));
	}

	.step-number {
		width: 5.21512386vw;
		height: 5.21512386vw;
		font-size: 3.12907432vw;
	}
}

@media screen and (min-width: 1024px) {
	.count:after {
		// height of step-number / 2 - height of bar / 2
		top: calc((44px / 2) - 2px);
		height: .277777778vw;
	}

	.step-number {
		z-index: 1;
		display: flex;
		width: 44px;
		height: 44px;
		align-items: center;
		justify-content: center;
		font-size: 1.66666667vw;
		font-size: 24px;
	}
}
