@media screen and (min-width: 768px) {
	.input-label {
		@include fluid-type-default(16px, 18px);
	}

	.input-container.password,
	.input-container.text,
	.input-container.email {
		.input-label {
			top: 20px;
			margin-left: 18px;
		}

		&.focused,
		&.open,
		&.has-value {
			.input-label {
				top: -10px;
			}
		}

		&.error {
			.input-label {
				margin-left: 72px;
			}
		}

		&.focused.typeahead {
			.input-label {
				margin-left: 14px;
			}
		}

		&.required:not(.error) {
			.input-label {
				margin-left: 18px;
			}
		}

		&.has-value.error,
		&.focused.error {
			.input-label {
				top: -10px;
				margin-left: 14px;
			}
		}
	}

	label.sub {
		position: relative;
		padding: 0 0px 0 40px;
	}
}

@media screen and (min-width: 768px) {
	.modal-dialog.autoComplete {
		.input-label.typeahead {
			margin-left: 11px;
		}

		.input-container.text.error {
			.input-label.typeahead {
				margin-left: 70px;
			}
		}

		.input-container.text.error.focused,
		.input-container.text.error.has-value {
			.input-label.typeahead {
				margin-left: 11px;
			}
		}
	}
}
