.receipt-view {
	.reward-img {
		display: block;
		width: 100%;
		max-width: 700px;
		max-height: 464px;
		margin: 0 auto 40px;
		object-fit: cover;
	}

	.text {
		color: $black-text;
		font: 30px/32px 'Lato', sans-serif;

		&:after {
			display: block;
			height: 40px;
			content: '';
		}
	}

	.ok-btn {
		margin: 0 auto;

		button {
			width: 100%;
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		max-width: 600px;

		.reward-img {
			max-width: 525px;
			max-height: 348px;
		}

		.text {
			font-size: 28px;
			line-height: 30px;
		}

		.ok-btn button {
			width: auto;
		}
	}

	// for desktop
	@include media-breakpoint-up(lg) {
		.reward-img {
			max-width: 430px;
			max-height: 285px;
		}

		.text {
			font-size: 24px;
			line-height: 26px;
		}
	}
}

@media print {
	.main-header,
	.main-footer,
	.receipt-view .ok-btn {
		display: none;
	}
}
