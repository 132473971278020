@import './funnel_chart-style.scss';
@import './funnel_chart-mv-style.scss';
@import './funnel_chart-lv-style.scss';

.company-summary {
	.header-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 15px 0;
		background-color: #f6f7f8;

		p.text.sm {
			@include fluid-type-default(14px, 16px);

			margin: 0 16px 0 0;
		}
	}
}
