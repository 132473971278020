/* Large Screen Normalize */

@media screen and (min-width: 1024px) {
	p {
		font-size: 1.8rem;
		line-height: 2.8rem;
	}

	h1 {
		font-size: 2.4rem;
	}

	h2 {
		font-size: 2.2rem;
	}

	h3 {
		font-size: 2rem;
	}

	h4 {
		font-size: 1.8rem;
	}

	h5 {
		font-size: 1.8rem;
	}
}
