/* stylelint-disable block-no-empty, no-descending-specificity */
.dropdown .dropdown-menu .dropdown-item:hover {
	background-color: $dinova-green !important;
	cursor: pointer;
}

.select {
	&.flat {
		display: flex;
		width: 100%;

		.item {
			min-height: 40px;
			padding: 0 10px;
			border: 1px solid #3d852c;
			margin: 0;
			background-color: #fff;
			border-radius: 0;
			box-shadow: none !important;
			color: #3d852c !important;
			cursor: pointer;
			font-size: 16px;
			font-weight: bold;
			line-height: 20px;
			text-align: center;
			text-decoration: none;
			text-transform: none;

			&:not(:last-child) {
				margin-right: 8px;
			}

			&.active {
				background-color: #3d852c;
				color: #fff !important;
			}
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		&.dropdown {
			position: relative;

			.dropdown-toggle:not([disabled]):not(.disabled),
			&.show .dropdown-toggle:not([disabled]):not(.disabled) {
				&:active,
				&:hover,
				&:focus {
					background-color: #fff !important;
				}
			}

			.dropdown-toggle {
				display: flex;
				width: 155px;
				align-items: center;
				justify-content: space-between;
				padding: 0 15px;
				border: 1px solid #ababab !important;
				margin: 0;
				background-color: #fff !important;
				border-radius: 5px !important;
				box-shadow: none !important;
				color: #5d5c59 !important;
				line-height: 54px;
				text-align: left;
				text-transform: inherit;

				@include fluid-type-default(12px, 18px);

				&:after {
					display: none;
				}

				.caret-wrapper {
					display: flex;
					width: 30px;
					height: 30px;
					align-items: center;
					justify-content: center;
					background-image: url(#{$imageBaseUrl}/icons/icon-background-circle.svg);
					background-position: center;
					background-repeat: no-repeat;

					.caret {
						display: block;
						width: 20px;
						height: 20px;
						background-image: url(#{$imageBaseUrl}/icons/icon-caret-gray.svg);
						background-position: center;
						background-repeat: no-repeat;
						transform: rotate(180deg);
					}
				}
			}

			&.show .dropdown-toggle {
				background-color: #fff !important;

				.caret {
					background-image: url(#{$imageBaseUrl}/icons/icon-caret.svg);
					transform: rotate(0deg);
				}
			}

			.dropdown-menu {
				width: 100%;
				padding: 10px;
				border: none;
				border: solid 1px $input-border-grey;
				margin: 0;
				border-radius: 0;

				&.show {
					width: calc(100% + 1px);
					border-top: none;
				}

				.dropdown-item {
					padding: 0 10px;
					border: 1px solid #3d852c;
					background-color: #fff;
					border-radius: 0;
					color: #3d852c !important;
					cursor: pointer;
					font-size: 16px;
					font-weight: bold;
					line-height: 42px;
					text-align: center;

					&:not(:last-child) {
						margin-bottom: 10px;
					}

					&.active {
						background-color: #3d852c;
						color: #fff !important;
					}

					&:hover,
					&:active {
						box-shadow: none;
					}
				}
			}
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		&.dropdown .dropdown-toggle {
			width: 180px;
		}
	}
}
