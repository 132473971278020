.connect-to-points {
	@media screen and (max-width: 768px) {
		form[name='connect-to-points'] {
			margin-top: 1em;
		}

		.card-type + form[name='connect-to-points'] {
			margin-top: 0;
		}

		.row [class*='col'] {
			padding: 0px;
		}

		.row.form-buttons [class*='col'] {
			&:first-child {
				padding-right: 15px;
			}

			&:nth-child(2) {
				padding-left: 15px;
			}
		}
	}

	.card-type {
		padding: 0;
		margin-top: calc(-15px + 1em);
		margin-bottom: 1em;
		font-size: 18px;
	}

	.company-selection {
		display: flex;
		height: 60px;
		flex-wrap: wrap;
		align-items: center;
		padding: 0;
		margin-top: 15px;
		font-size: 18px;

		> * {
			white-space: nowrap;

			&:not(:last-child) {
				margin-right: 5px;
			}
		}
	}

	.with-help {
		.help-panel-wrapper,
		.help-panel-wrapper.big-pad {
			top: 100%;
			margin-top: 0px;
		}
	}

	.column1,
	.column2 {
		.col {
			padding: 0;
		}
	}

	.email-address-container {
		width: calc(100% - 60px);
		margin: 5px 0 0 10px;

		.email-field {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 15px;
			font-size: 18px;

			> * {
				white-space: nowrap;

				&:not(:last-child) {
					margin-right: 5px;
				}
			}
		}

		.example-label {
			color: #4d4c48;
			font: 16px/20px 'Lato', sans-serif;
			font-weight: 300;
		}
	}

	@media screen and (min-width: 768px) {
		.company-selection {
			padding-left: 1.8em;
		}

		.card-type {
			padding-left: 1.8em;
			// margin-top: calc(-30px + 1em);
		}

		.input-container.text.required:not(.error) .input-label {
			margin-left: 13px;
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		.company-selection {
			// padding-left: 1.8em;

			&.margin-bottom {
				margin-bottom: 41px;
			}
		}

		.card-type {
			// padding-left: 1.8em;
			margin-top: calc(-30px + 1em);
		}
	}
}
