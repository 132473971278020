.option {
	padding: 8px 10px;
	margin: 0;
	font-weight: 400;

	@include fluid-type-default(14px, 18px);

	&.selected {
		background-color: rgb(242, 240, 239);
	}
}

.option:hover {
	background-color: rgb(242, 240, 239);
}
