.profile-preference-item {
	display: flex;
	align-items: center;

	> * {
		margin: 0;
		font-size: 14px;
		line-height: 1.2;
	}

	.fa-check {
		color: $forest-green;
	}

	.fa-ban {
		color: $red;
	}

	.item-label {
		margin-left: 10px;
		color: $black;
		font-family: 'Lato', sans-serif;
		font-weight: bold;
	}

	&.unchecked {
		opacity: .5;

		.fa-check {
			color: $black;
		}
	}

	// for table
	@include media-breakpoint-up(md) {
		> * {
			@include fluid-type(map-get($grid-breakpoints, xs), map-get($grid-breakpoints, md), 20px, 24px);
		}
	}

	// for desktop
	@include media-breakpoint-up(lg) {
		> * {
			@include fluid-type(map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xl), 16px, 20px);
		}

		.item-label {
			font-weight: normal;
		}
	}
}
