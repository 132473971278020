.dropdown {
	position: relative;
	overflow: visible;
	width: 100%;
	cursor: pointer;

	.dropdown-list-wrapper {
		position: absolute;
		z-index: 2000;
		overflow: hidden;
		width: 100%;
		border-width: 1px 3px 3px;
		border-style: solid;
		border-color: $dinova-green;
		margin-top: -1px;
		background-color: $adminDining_white;
		border-radius: 0 0 5px 5px;

		&.closed {
			z-index: -2;
			height: 0px !important;
			border-width: 0;
		}

		.dropdown-list {
			z-index: 200;
		}
	}

	&.error,
	&.error.open {
		border-color: $danger-red;

		.label-wrapper {
			padding: 9px 10px 7px 43px;
			border-width: 3px;
		}

		.focus-border {
			border-width: 3px 0 0;
		}

		/* stylelint-disable */
		.label-wrapper,
		.focus-border {
			border-color: $danger-red;
		}
		/* stylelint-enable */

		.dropdown-list-wrapper {
			border-color: $danger-red;
		}

		svg {
			position: absolute;
			top: 9px;
			left: 15px;
			width: 24px;
			height: 24px;
			fill: $danger-red;
		}
	}
}

@media screen and (min-width: 768px) {
	.dropdown {
		&.error,
		&.error.open {
			.label-wrapper {
				padding: 18px 18px 18px 70px;
				// border-width: 3px;
			}

			.focus-border {
				// border-width: 3px 0 0;
			}

			svg {
				top: 17px !important;
				left: 23px !important;
				width: 30px;
				height: 30px;
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.dropdown {
		&.error,
		&.error.open {
			.label-wrapper {
				padding: 13px 13px 13px 60px;
				// border-width: 3px;
			}

			.focus-border {
				// border-width: 3px 0 0;
			}

			svg {
				top: 17px !important;
				left: 19px !important;
				width: 26px;
				height: 26px;
			}
		}
	}
}

:not(.with-help) {
	.dropdown {
		&.disabled {
			opacity: .6;
		}
	}
}
