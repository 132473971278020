.restaurant-hours {
	position: relative;
	display: inline-block;

	p.text {
		display: inline-block;
		margin-bottom: 0;

		@include fluid-type-default(12px, 14px);

		&.today {
			margin-left: 5px;
		}
	}

	.hours {
		.option-item {
			margin-bottom: 3px;
		}
	}

	.hours-btn {
		display: inline;
		min-width: 140px;
	}

	.caret {
		position: relative;
		display: inline-block;
		width: 15px;
		height: 15px;

		&:before {
			position: absolute;
			right: 0;
			width: 0;
			height: 0;
			border-width: 10px 6px;
			border-style: solid;
			border-color: transparent;
			content: '';
		}

		&.up {
			&:before {
				top: -4px;
				border-bottom-color: $black-text;	}
		}

		&.down {
			&:before {
				top: 4px;
				border-top-color: $black-text;
			}
		}
	}

	.hours-panel-wrapper {
		position: absolute;
		z-index: 1;
		left: 40px;
		border-radius: 4px;

		.collapse-with-close {
			&.open {
				overflow-x: visible;
				overflow-y: visible;
			}

			.inner-wrapper {
				margin-top: 0;

				.close-btn,
				&:before {
					display: none;
					visibility: hidden;
				}

				.content {
					.text.md.normal.text-none {
						padding: 1rem 10px;
						background-color: $adminDining_white;
						box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
					}
				}
			}

			.option-item {
				padding-right: 15px;
				color: $black-text;

				@include fluid-type-default(12px, 14px);
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.restaurant-hours {
		p.text {
			@include fluid-type-default(14px, 16px);
		}

		.hours-panel-wrapper {
			right: 0;
			left: unset;
		}
	}
}

@media screen and (min-width: 1024px) {
	.restaurant-hours {
		display: inline-flex;
		width: 100%;
		justify-content: space-between;
	}
}
