@font-face{
	font-family: text-security-disc;
	src: url("https://raw.githubusercontent.com/noppa/text-security/master/dist/text-security-disc.woff");
  }
  
.hide {
	.cc-input {
		-webkit-text-security: disc;
		font-family: text-security-disc;
	}

	label {
		font-family: 'Lato' !important;
		-webkit-text-security: none;
	}
}
