.edit-profile-actions {
	margin-top: 50px;
	text-align: center;

	.btn {
		display: block;
		margin: 0;

		@media screen and (max-width: 768px) {
			@include fluid-type-default(14px, 16px);
		}

		& + * {
			margin-top: 25px;
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		margin-top: 40px;

		.btn {
			display: inline;

			& + * {
				margin-top: 0;
				margin-left: 40px;
			}
		}
	}
}
