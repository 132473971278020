$desktop-closed-card-width: calc((100vw / 3) - (#{$card-side-margin} * 2));
$desktop-open-card-width: calc(92vw - (#{$card-side-margin} * 2));
// $card-padding-top: 5rem;
// $card-padding-side: 5rem;
$transition-duration: 600ms;

@media screen and (min-width: 1024px) {
	.landing-page {
		.splash-wrapper {
			.cards {
				&.has-active {
					&[class*='1'] {
						transform: translateX(calc(-1 * #{$desktop-closed-card-width}));
					}

					&[class*='2'] {
						transform: translateX(calc(-1 * (#{$desktop-closed-card-width} * 2 - (#{$card-side-margin} * 4))));
					}
				}

				.card.dinova-card {
					width: $desktop-closed-card-width;
					min-width: $desktop-closed-card-width;
					max-width: $desktop-closed-card-width;

					.content-wrapper {
						.face {
							width: $desktop-closed-card-width;
							min-width: $desktop-closed-card-width;
							max-width: $desktop-closed-card-width;
						}

						.fold {
							width: $desktop-open-card-width;
							min-width: $desktop-open-card-width;
							max-width: $desktop-open-card-width;
						}
					}
				}

				.card.dinova-card.active {
					width: $desktop-open-card-width;
					min-width: $desktop-open-card-width;
					max-width: $desktop-open-card-width;
					animation-delay: $transition-duration;
				}

				.card.dinova-card.slide.active.right {
					.content-wrapper {
						transform: translateX(calc(#{$desktop-closed-card-width} * -1));
					}
				}
			}
		}
	}
}
