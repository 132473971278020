.contact-info-item {
	display: flex;
	text-decoration: none;

	.label,
	.value {
		@include fluid-type-default(16px, 16px);
	}

	.label {
		margin-right: 5px;
		color: $black-text;
	}

	.value {
		overflow: hidden;
		text-decoration: underline;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.contact-info-item.has-icon {
	align-items: center;
	padding: 10px 0;

	&:first-child {
		border-color: $grey;
		border-style: solid;
		border-width: 0 0 2px;
	}

	.icon {
		display: flex;
		width: 35px;
		height: 35px;
		margin-right: 5px;
	}

	.label {
		font-weight: 600;
	}

	.value {
		color: $charcoal;
		font-weight: 400;
		text-decoration: none;
	}
}
